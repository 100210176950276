import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { capitalize, last } from 'lodash';
import get from 'lodash/get';

import { sortEvents } from '@cntxt/shared/data-access-cdf';
import { useAuthContext } from '@cntxt/shared/providers/web';
import {
  DateFormat,
  getDateWithFormat,
  getDisplayDate,
} from '@cntxt/shared/util-date';
import {
  getAsset,
  getEventById,
  useAssetsByIdQuery,
  useEventsQuery,
} from '@cntxt/steam/data-access';

export type ImageCardDetails = {
  title: string;
  imageSrc: string;
};
const useLeafItem = (): { parentId: number; leafId: number } => {
  const { id, ...rest } = useParams<'id'>();

  const extras = get(rest, '*') || '';
  const splits = extras.split('/');

  if (last(splits) === 'inspect') {
    splits.pop();
  }
  return {
    parentId: Number(splits[splits.length - 2] || id),
    leafId: Number(last(splits) || id),
  };
};

export const useData = (startTime?: number, endTime?: number) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { client } = useAuthContext();
  const datasetId = useParams<'id'>();
  const eventIDFromQueryParam = searchParams.get('eventId');
  const { parentId } = useLeafItem();
  const { data: events, refetch: refetchEventsData } = useEventsQuery({
    client,
    id: parentId,
    dataSetIds: [{ id: Number(datasetId['id']) }],
    startTime,
    endTime,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const eventsData = sortEvents(events);
  React.useEffect(() => {
    // Manually trigger refetch when either startTime or endTime changes
    refetchEventsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime, events?.length]);
  const eventIdIfExist = events?.length ? events[0].id : 0;
  const selectedEventId = eventIDFromQueryParam
    ? +eventIDFromQueryParam
    : eventIdIfExist;

  React.useEffect(() => {
    if (events?.length) {
      const searchInfo = {
        ...searchParams,
        eventId: events[0].id,
      };
      setSearchParams(searchInfo as unknown as URLSearchParams);
    }
  }, [events?.length]);

  const selectedEvent = getEventById(eventsData || [], selectedEventId);
  const title =
    capitalize(selectedEvent?.type) +
    ' | ' +
    getDateWithFormat(
      new Date(selectedEvent?.startTime || 0).toDateString(),
      DateFormat.DAY_MONTH_YEAR_24HRs,
    );

  const calendarData: [string, number][] = [];
  eventsData?.forEach((item) => {
    const startTimeUnix = Number(item.startTime);
    const date = getDisplayDate(new Date(startTimeUnix));
    const formattedDate = getDateWithFormat(
      date,
      DateFormat.DAY_MONTH_YEAR_24HRs,
    );
    calendarData.push([formattedDate, 1]);
  });

  const parentAssets = useAssetsByIdQuery({ client, ids: [parentId] }).data;
  const parentAsset = getAsset(parentId, parentAssets || []);

  return {
    dataSetId: Number(datasetId['id']),
    title,
    parentAsset,
    selectedEvent,
    calendarData,
    id: datasetId['id'],
  };
};
