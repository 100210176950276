import { mergeWith } from 'lodash';

import { Config } from './baseConfig';

export const updateConfigRecursively = (target: Config, source: Config) => {
  mergeWith(target, source, (targetValue, sourceValue) => {
    if (sourceValue !== undefined) {
      // Modify the target object in place
      if (typeof sourceValue === 'object' && !Array.isArray(sourceValue)) {
        return undefined; // Prevent Lodash from overriding nested objects
      } else {
        return sourceValue; // Update the target value
      }
    }

    return targetValue;
  });
};
