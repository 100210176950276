import * as React from 'react';
import { StrictMode } from 'react';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarProvider } from 'notistack';

import { QueryClientProvider } from '@cntxt/shared/providers/shared';
import { AuthOptions } from '@cntxt/shared/util-auth';

// import { ErrorBoundary } from '../error';
import { AuthProvider } from './AuthProvider';
import {
  OpenTelemetryProvider,
  TelemetryOptions,
} from './OpenTelemetryProvider';
import { SentryProvider } from './SentryProvider';

type Props = {
  config: {
    telemetry: TelemetryOptions;
    auth: AuthOptions;
    name: string;
    // production: boolean;
  };
};
export const Providers: React.FC<React.PropsWithChildren<Props>> = ({
  config,
  children,
}) => {
  return (
    <StrictMode>
      <SentryProvider production={false}>
        {/* Notifications */}
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          {/* Enables date selector components */}
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <AuthProvider name={config.name} authOptions={config.auth}>
              {/* Cache network data */}
              <QueryClientProvider>
                <OpenTelemetryProvider {...config.telemetry}>
                  {/* <ErrorBoundary>{children}</ErrorBoundary> */}
                  {children}
                </OpenTelemetryProvider>
              </QueryClientProvider>
            </AuthProvider>
          </LocalizationProvider>
        </SnackbarProvider>
      </SentryProvider>
    </StrictMode>
  );
};
