import * as React from 'react';

import * as Sentry from '@sentry/browser';

export const SentryProvider: React.FC<
  React.PropsWithChildren<{
    production: boolean;
  }>
> = ({ children, production }) => {
  Sentry.init({
    dsn: 'http://355b891757e4422492184a3f0f5d4342@localhost:8000/1',
    release: '',
    environment: production ? 'production' : 'development',
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
