import { DateTime } from 'luxon';

export enum DateFormat {
  DAY_WITH_SHORT_MONTH = 'dd LLL',
  DAY_MONTH_YEAR = 'dd MMMM yyyy',
  DAY_MONTH_YEAR_24HRs = 'yyyy-MM-dd HH:MM:ss',
  FULL_MONTH_NAME = 'MMMM',
  DAY_TIME_24HR = 'cccc, HH:mm',
}

const sanitizeString = (date: string) => {
  return date.replace(/[\u2000-\u200A\u202F\u205F\u3000]/g, ' ').normalize();
};

export const getDisplayDate = (
  dateString: Date,
  long?: boolean,
  timeZone = 'local',
): string => {
  const date = DateTime.fromJSDate(dateString).setZone(timeZone);
  const format = long
    ? DateTime.DATETIME_SHORT_WITH_SECONDS
    : DateTime.DATETIME_SHORT;
  const formattedDate = date.toLocaleString(format);
  return sanitizeString(formattedDate);
};

export const getDisplayDateFromUnix = (
  unixTime: number,
  long?: boolean,
  timeZone = 'local',
): string => {
  const date = DateTime.fromMillis(unixTime).setZone(timeZone);
  const format = long
    ? DateTime.DATETIME_SHORT_WITH_SECONDS
    : DateTime.DATE_SHORT;
  const formattedDate = date.toLocaleString(format);
  return sanitizeString(formattedDate);
};

export const getShortDate = (value: string) => {
  const formattedDate = getDateWithFormat(
    value,
    DateFormat.DAY_WITH_SHORT_MONTH,
  );
  return sanitizeString(formattedDate);
};

export const getDateWithFormat = (
  value: string | number | Date,
  format: DateFormat,
): string => {
  const date = new Date(value);
  const formattedDate = DateTime.fromJSDate(date).toFormat(format);
  return sanitizeString(formattedDate);
};
