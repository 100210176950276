import { getDevProject } from '@cntxt/shared/util-auth';
import { BaseConfig, Config } from '@cntxt/shared/util-envs';

type Page = 'upload' | 'history' | 'notifications' | 'steamTraps';

const clientId = 'steam'; // identifies the application in the IdP
const project = getDevProject('dev2-cntxt-open'); // where the data/users come from

export type AppConfig = {
  thresholds: Partial<Record<string, number>>;
  appId: string;
  api: Record<string, number>;
  datasetId: number;
} & BaseConfig<Page>;

export type AppEnvConfig = AppConfig & Config;

export const baseConfig: AppConfig = {
  name: 'Advanced Steam Trap Monitoring',
  datasetId: 7416128750083047,
  thresholds: {
    blocked: 100,
    warning: 400,
    passing: 600,
  },
  api: {
    PORT: 3333,
  },
  appId: 'steam',
  auth: {
    clientId,
    project,
  },
  features: {
    upload: true,
    history: false,
    notifications: false,
    steamTraps: true,
  },
  telemetry: {
    disabled: true,
    name: 'steam',
    url: 'http://localhost:4318',
  },
};
