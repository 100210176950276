import { AssetInternal } from '../types';

import { getAsset } from './getAsset';

export const getNameFromAssetId = (id?: number, data: AssetInternal[] = []) => {
  if (!id) {
    return undefined;
  }
  return getAsset(id, data)?.name;
};

export const getNamesFromAssetIds = (
  ids: number[] = [],
  data: AssetInternal[] = [],
) => {
  return ids?.map((id) => getNameFromAssetId(id, data));
};
