import React from 'react';

import { flexRender, type Table } from '@tanstack/react-table';

import { RowContainer, CellText } from './Table.style';

type Props<T> = {
  table: Table<T>;
};
export const Body = <T,>({ table }: Props<T>) => {
  //todo click and hover feature to "Row"
  return (
    <tbody>
      {table.getRowModel().rows.map((row) => {
        return (
          <RowContainer key={row.id}>
            {row.getVisibleCells().map((cell) => {
              return (
                <td key={cell.id}>
                  <CellText>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </CellText>
                </td>
              );
            })}
          </RowContainer>
        );
      })}
    </tbody>
  );
};
