import React from 'react';

import ReactECharts from 'echarts-for-react';

export type ChartClickEventParams = {
  data: [string, string, number]; // [x,y, event Id]
  dataType: string;
  dataIndex: number;
  event: {
    event: {
      clientX: number;
      clientY: number;
      layerX: number;
      layerY: number;
      screenX: number;
      screenY: number;
      target: HTMLElement;
    };
  };
};

type ScatterChartProps = {
  options: Record<string, string | number | object>;
  onSelect?: (params: ChartClickEventParams) => void;
  styles?: { width: number | string; height: number | string };
};

export const ScatterChart: React.FC<ScatterChartProps> = ({
  options = {},
  onSelect,
  styles,
}) => {
  const handleChartClick = (params: ChartClickEventParams) => {
    if (params && onSelect) {
      onSelect(params);
    }
  };
  return (
    <ReactECharts
      option={options}
      style={styles}
      onEvents={{
        click: handleChartClick,
      }}
    />
  );
};
