import { CogniteClient, ListGroups } from '@cognite/sdk';

export const fetchGroups = async ({
  client,
  listGroups,
}: {
  client: CogniteClient;
  listGroups: ListGroups;
}) => {
  const groups = await client.groups.list(listGroups);

  return groups;
};
