import { CogniteClient, Asset } from '@cognite/sdk';

export const fetchAssetsById = async ({
  client,
  id,
}: {
  client: CogniteClient;
  id?: Asset['id'];
}) => {
  if (!id) {
    return false;
  }

  const result = await client.assets.retrieve([{ id }]);

  return result[0];
};
