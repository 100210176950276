import { CSSProperties } from 'react';

import { ECElementEvent } from 'echarts/types/dist/echarts';

import { Calendar } from './Calendar';
import { getData } from './getData';

type Props = {
  startDate: Date;
  endDate: Date;
  data: [string, number][];
  onSelect?: (param: ECElementEvent) => void;
};

export const CalendarGroup: React.FC<Props> = ({
  startDate,
  endDate,
  data,
  onSelect,
}) => {
  const monthlyCalendarOptions = getData(data, startDate, endDate);

  const calendars = monthlyCalendarOptions.map((option, index) => (
    <Calendar key={index} graphOptions={option} onSelect={onSelect} />
  ));
  return <div style={styles.container}>{calendars}</div>;
};

const styles: Record<string, CSSProperties> = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: '0',
    flexWrap: 'nowrap',
    overflow: 'auto',
  },
};
