import { ButtonBase } from '@mui/material';
import type { Table } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';

import { Icon, IconsList } from '@cntxt/shared/ui-icons';

import {
  CellIcon,
  CellText,
  HeadContainer,
  HeaderContainer,
} from './Table.style';

type Props<T> = {
  table: Table<T>;
};
export const Header = <T,>({ table }: Props<T>) => {
  const tableHeaderGroup = table.getHeaderGroups();

  return (
    <HeadContainer>
      {tableHeaderGroup.map((headerGroup) => {
        return (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => {
              return (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <HeaderContainer
                      {...{
                        'aria-label': 'sortable',
                        className: header.column.getCanSort()
                          ? 'cursor-pointer select-none'
                          : '',
                        $minwidth: header.getSize() + 'px', //NOTE - 'minWidth' not 'minwidth' will throw an error. use $ to bypass
                      }}
                      role="button"
                      tabIndex={index}
                    >
                      <CellText>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </CellText>
                      {header.column.getCanSort() && (
                        <CellIcon>
                          <ColumnSorting
                            state={header.column.getIsSorted()}
                            onClick={header.column.getToggleSortingHandler()}
                          />
                        </CellIcon>
                      )}
                    </HeaderContainer>
                  )}
                </th>
              );
            })}
          </tr>
        );
      })}
    </HeadContainer>
  );
};

const ColumnSorting = ({
  state,
  onClick,
}: {
  state: boolean | string;
  onClick?: (event: unknown) => void;
}) => {
  if (state === 'desc') {
    return (
      <ButtonBase onClick={onClick}>
        <Icon iconName={IconsList.sortingDescending} />
      </ButtonBase>
    );
  }
  if (state === 'asc') {
    return (
      <ButtonBase onClick={onClick}>
        <Icon iconName={IconsList.sortingAscending} />
      </ButtonBase>
    );
  }

  return (
    <ButtonBase onClick={onClick}>
      <Icon iconName={IconsList.sorting} />
    </ButtonBase>
  );
};
