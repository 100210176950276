import * as React from 'react';

import type { ColumnDef } from '@tanstack/react-table';

import { EmptyView } from './EmptyView';
import { getColumnsConfiguration } from './getColumnsConfiguration';
import type { ColumnsConfiguration } from './getColumnsConfiguration';
import { Body } from './Table.Body';
import { Header } from './Table.Header';
import { TableContainer } from './Table.style';
import { useReactTable } from './useReactTable';

type Props = {
  data: Record<string, string | Date | object | null>[];
  columns: ColumnsConfiguration;
  isLoading?: boolean;
};
export const Table: React.FC<Props> = ({ data, columns }) => {
  const columnsConfiguration = getColumnsConfiguration(columns);
  // todo fix column type
  const table = useReactTable({
    data,
    columns: columnsConfiguration as ColumnDef<unknown>[],
  });
  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  return (
    <div ref={tableContainerRef}>
      <TableContainer style={{ width: '100%' }}>
        <Header table={table} />
        {data?.length <= 0 ? <EmptyView /> : <Body table={table} />}
      </TableContainer>
    </div>
  );
};
