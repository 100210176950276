import { useEffect, useRef } from 'react';
import { Doughnut as DoughnutChart } from 'react-chartjs-2';

import type { ChartData, PluginChartOptions } from 'chart.js';
import { Chart as ChartJS } from 'chart.js';

import { Styles } from '../../styles';

const defaultStartColor = '#42CAE9';
const defaultEndColor = '#1873B0';

//
// docs:
// https://react-chartjs-2.js.org/examples/doughnut-chart
//
const gaugeOptions: Partial<PluginChartOptions<'doughnut'>> = {
  rotation: -90, // Start from 9 o'clock
  circumference: 180, // Half circle (180 degrees)
  cutout: '75%', // Adjust for doughnut thickness
  plugins: {
    legend: {
      position: 'bottom',
      // @ts-expect-error strange type
      labels: {
        usePointStyle: true,
        boxHeight: 5,
        boxWidth: 5,
      },
    },
    maintainAspectRatio: false,
  },
  layout: {
    padding: 0,
  },
};

const defaultGaugeData: ChartData<'doughnut', number[], string> = {
  datasets: [
    {
      label: '',
      data: [],
      backgroundColor: [defaultStartColor, 'transparent'],
      borderWidth: 1,
      borderRadius: 0,
    },
  ],
};

const mergeGaugeData = (
  gaugeData: Partial<ChartData<'doughnut', number[], string>>,
  newData: Partial<ChartData<'doughnut', number[], string>>,
): Partial<ChartData<'doughnut', number[], string>> => {
  if (newData) {
    const mergedDatasets = gaugeData.datasets?.map((dataset, index) => {
      return { ...dataset, ...newData?.datasets?.[index] };
    });

    return { ...gaugeData, ...newData, datasets: mergedDatasets };
  } else {
    return gaugeData;
  }
};

const arcTextPlugin = (textOptions: ChartText) => {
  const defaultTextOptions: ChartText = {
    text: '',
    coordinate: { x: 0.5, y: 0.7 },
    font: `bold ${16}px sans-serif`,
  };
  textOptions = { ...defaultTextOptions, ...textOptions };
  const randomValue = Math.random().toString().slice(2);
  return {
    id: `arcTextPlugin-${randomValue}`,
    afterDraw: (chart: ChartJS) => {
      const ctx = chart.ctx;
      if (ctx) {
        const width = chart.width;
        const height = chart.height;
        ctx.restore();
        ctx.font = `${textOptions.font}`;
        ctx.textAlign = textOptions.textAlign || 'center';
        ctx.textBaseline = textOptions.textBaseline || 'bottom';
        ctx.fillStyle =
          textOptions.fillStyle || Styles.colors.text.interactive.default;
        // Calculate position based on the coordinate percentage
        const textX = textOptions.coordinate.x * width;
        const textY = textOptions.coordinate.y * height;

        ctx.fillText(textOptions.text, textX, textY);
        ctx.save();
      }
    },
  };
};

type ChartText = Partial<CanvasTextDrawingStyles> &
  Partial<CanvasFillStrokeStyles> & {
    text: string;
    coordinate: {
      x: number;
      y: number;
    };
  };

type Props = {
  arcText: ChartText;
  data?: Partial<ChartData<'doughnut', number[], string>>;
  gradientColors?: {
    startColor: string;
    endColor: string;
  };
};

export const Gauge: React.FC<Props> = ({
  data = defaultGaugeData,
  arcText,
  gradientColors,
}) => {
  const chartData = mergeGaugeData(defaultGaugeData, data) as ChartData<
    'doughnut',
    number[],
    string
  >;
  const chartArcText = arcTextPlugin(arcText);
  const gradientStartColor = gradientColors?.startColor || defaultStartColor;
  const gradientEndColor = gradientColors?.endColor || defaultEndColor;
  const chartRef = useRef();

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current as ChartJS;
      const ctx = chart.ctx;
      if (ctx) {
        const gradient = ctx.createLinearGradient(
          0,
          0,
          chart.width,
          chart.height,
        );
        gradient.addColorStop(0.3682, gradientStartColor);
        gradient.addColorStop(0.6011, gradientEndColor);

        chart.data.datasets[0].backgroundColor = [gradient, 'transparent'];
        chart.data.datasets[0].borderColor = gradient;
        chart.update();
      } else {
        return;
      }
    } else {
      return;
    }
  }, [gradientEndColor, gradientStartColor]);

  useEffect(() => {
    const updatedChartArcText = arcTextPlugin(arcText);
    if (chartRef.current) {
      const chart = chartRef.current as ChartJS;
      chart.config.plugins?.pop();
      chart.config.plugins?.push(updatedChartArcText);
      chart.update();
    }
  }, [arcText]);

  return (
    <DoughnutChart
      ref={chartRef}
      data={chartData}
      options={gaugeOptions}
      plugins={[chartArcText]}
    />
  );
};
