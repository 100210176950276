import { CogniteClient } from '@cognite/sdk/dist/src';
import { useQuery } from '@tanstack/react-query';

import { fetchFiles } from '../../network/fetchFiles';

import { FILES_KEYS } from './keys';

type Props = {
  client: CogniteClient;
  ids: { id: number }[];
};
export const useFilesByIdQuery = ({ client, ids }: Props) => {
  return useQuery(
    {
      queryKey: FILES_KEYS.ALL(),
      queryFn: () =>
        fetchFiles({
          client,
          ids,
        }),
    },
    // {
    //   enabled: ids.length > 0,
    // }
  );
};
