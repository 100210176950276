import { AUTH_STATE_KEY, AuthState } from '@cntxt/shared/util-auth';

import { StorageProvider } from '../storageProvider';

export const getInitialAuthState = async ({
  storageProvider,
}: {
  storageProvider: StorageProvider;
}): Promise<AuthState> => {
  const savedAuthStateRaw = await storageProvider.getItem(AUTH_STATE_KEY);
  if (savedAuthStateRaw) {
    const savedAuthState = JSON.parse(savedAuthStateRaw) as AuthState;
    // If we were authenticated, then lets try to see if we can auto-reauthenticate
    if (savedAuthState.status === 'AUTHENTICATED') {
      return {
        status: 'AUTHENTICATING',
        projectInfo: savedAuthState.projectInfo,
      };
    }
    return savedAuthState;
  }
  return { status: 'UNAUTHENTICATED' };
};
