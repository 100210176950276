import * as React from 'react';
import { Outlet } from 'react-router-dom';

import type { SxProps } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

/*
 * This layout is for pages that:
 *
 * - show the left menubar/navigation tree
 *
 */
export const SmallLeftLargeRight: React.FC<
  React.PropsWithChildren<{
    sx?: SxProps;
  }>
> = ({ sx, children }) => {
  return (
    <>
      <Grid
        xs={12}
        md={3}
        sx={{
          ...sx,
        }}
      >
        {children}
      </Grid>
      <Grid container xs={12} md={9}>
        {<Outlet />}
      </Grid>
    </>
  );
};
