import * as React from 'react';
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { Providers, useAuthContext } from '@cntxt/shared/providers/web';
import {
  ErrorFallback,
  Layout,
  NoMatch,
  StyleProvider,
} from '@cntxt/shared/ui';
import { Sidebar } from '@cntxt/shared/ui-sidebar';
import { RenderTree, useAssetTree } from '@cntxt/steam/data-access';
import { ROUTES } from '@cntxt/steam/util-ui';

import { environment } from '../environments/environment';
import { isEnabled } from '../utils/featureFlag';

import { SteamSidebar } from './Features/Sidebar/SteamSidebar';
import { Alerts } from './pages/Alerts';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { History as HistoryPage } from './pages/History/History';
import { HistorySidebar } from './pages/History/HistorySidebar';
import { Inspect } from './pages/Inspect/Inspect';
import { ReviewImages } from './pages/ReviewImages';
import { Settings } from './pages/Settings';
import { SteamTraps } from './pages/SteamTraps/SteamTraps';
import { Upload } from './pages/Upload/Upload';

const rootLoader = () => {
  return redirect(ROUTES.PAGE);
};

const AuthedPage = () => {
  if (environment.auth.project) {
    const project = environment.auth.project;

    return (
      <Providers
        config={{
          ...environment,
          auth: {
            ...environment.auth,
            project,
          },
        }}
      >
        <StyleProvider />
        <Layout.Main />
        {/* <Outlet /> */}
      </Providers>
    );
  }
  return null;
};

const nestedPages = [
  { path: ROUTES.SETTINGS, element: <Settings /> },
  { path: ROUTES.REVIEW, element: <ReviewImages /> },
];

if (isEnabled('notifications')) {
  nestedPages.push({ path: ROUTES.ALERTS, element: <Alerts /> });
}

if (isEnabled('upload')) {
  nestedPages.push({ path: ROUTES.UPLOAD, element: <Upload /> });
}

const pages = [
  {
    index: true,
    element: <PageWithAssetTree />,
  },
  {
    element: <PageWithAssetTree />,
    children: [
      {
        path: ROUTES.DASHBOARD,
        element: <Dashboard />,
      },
      {
        path: `${ROUTES.DASHBOARD}/:id/*`,
        element: <Dashboard />,
      },
      { path: `${ROUTES.DETAILS}/:id/*`, element: <Inspect /> },
    ],
  },
  {
    element: <PageWithJustMenu />,
    children: nestedPages,
  },
];

if (isEnabled('history')) {
  pages.push({
    element: <PageWrapperHistory />,
    children: [{ path: ROUTES.HISTORY, element: <HistoryPage /> }],
  });
}

if (isEnabled('steamTraps')) {
  pages.push({
    element: <PageWrapperAllSteamTraps />,
    children: [
      {
        path: ROUTES.INSPECTION,
        element: <SteamTraps />,
      },
      {
        path: `${ROUTES.INSPECTION}/:id/*`,
        element: <SteamTraps />,
      },
    ],
  });
}

const router = createBrowserRouter(
  [
    {
      path: ROUTES.HOME,
      loader: rootLoader,
      errorElement: <ErrorFallback link={ROUTES.HOME} />,
    },
    {
      path: ROUTES.PAGE,
      element: <AuthedPage />,
      errorElement: <ErrorFallback link={ROUTES.HOME} />,
      children: pages,
    },
    {
      path: '*',
      element: <NoMatch />,
      errorElement: <ErrorFallback link={ROUTES.HOME} />,
    },
  ],
  {
    basename: process.env['NX_BASENAME'],
  },
);

export const App = () => <RouterProvider router={router} />;

const useNavigateToFirstPlant = (data: RenderTree, url: string) => {
  const { id: plantId } = useParams<'id'>();
  const navigate = useNavigate();
  React.useEffect(() => {
    const navigateToFirstPlant = () => {
      const children = data?.children;
      const defaultNavigationOnLandingPage = children?.length && !plantId;
      if (defaultNavigationOnLandingPage) {
        const firstChildId = children?.[0].id;
        navigate(`${url}/${firstChildId}`);
      }
    };

    navigateToFirstPlant();
  }, [data]);
};

function PageWithAssetTree() {
  const { client } = useAuthContext();
  const dataSetIds = [{ id: environment.datasetId }];
  const { data } = useAssetTree({ client, dataSetIds });
  useNavigateToFirstPlant(data, ROUTES.DASHBOARD);

  return (
    <SteamSidebar assetTreeLayout>
      <Sidebar.AssetTree data={data} routePrefix={ROUTES.DASHBOARD} />
    </SteamSidebar>
  );
}

function PageWithJustMenu() {
  return <SteamSidebar />;
}

function PageWrapperAllSteamTraps() {
  const { client } = useAuthContext();
  const dataSetIds = [{ id: environment.datasetId }];
  const { data } = useAssetTree({
    client,
    dataSetIds,
    filterWithoutSteamTrap: true,
  });
  useNavigateToFirstPlant(data, ROUTES.STEAM_TRAPS_INSPECTION);
  return (
    <SteamSidebar assetTreeLayout>
      <Sidebar.AssetTree
        data={data}
        routePrefix={ROUTES.STEAM_TRAPS_INSPECTION}
      />
    </SteamSidebar>
  );
}

function PageWrapperHistory() {
  return (
    <SteamSidebar assetTreeLayout>
      <HistorySidebar />
    </SteamSidebar>
  );
}
