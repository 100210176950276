import { CogniteClient, AssetChange, Asset } from '@cognite/sdk';

import { updateAsset } from '@cntxt/shared/data-access-cdf';

type InternalEvent = Pick<Asset, 'metadata' | 'externalId'> & {
  id?: Asset['id'];
};

export const updateSteamTrapAsset = async ({
  client,
  asset,
}: {
  client: CogniteClient;
  asset: Partial<InternalEvent>;
}) => {
  const externalId = asset.externalId;

  if (!externalId) {
    throw new Error('Missing asset externalId');
  }
  const changes: AssetChange['update'] = {};
  if (asset.metadata) {
    changes.metadata = {
      set: asset.metadata,
    };
  }

  const changeAsset: AssetChange = { externalId, update: changes };

  console.log('Triggering change asset for:', changeAsset);

  return updateAsset({
    client,
    changeAsset,
  });
};
