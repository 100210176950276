import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { Chip, Styles } from '@cntxt/shared/ui';

export const ImageQualityGood = () => (
  <Chip
    sx={{
      color: Styles.colors.text.status.success,
      background: Styles.colors.surface.status.successMuted,
      display: 'flex',
      justifyContent: 'flex-start',
      gap: '9px',
    }}
  >
    <CheckCircleOutlineIcon />
    Image quality good
  </Chip>
);
