import { Datapoints } from '@cognite/sdk';

type ActionProps = Record<string, unknown>;
export type FetchOptions = { signal?: AbortSignal };

/**
 * firstActionProps - useful when passing 'count' aggregate into only the first request (eg: we don't need it when we search the cursors)
 *
 *
 * Example usage:
 *
 */
type DatapointResponse = (Datapoints & { nextCursor?: string })[];
// type DatapointResponse = {
// items: CursorResponse<DatapointAggregates>[] | CursorResponse<Datapoints>[];
// };
export const fetchAllTimeseriesCursors = async ({
  action,
  actionProps,
  firstActionProps,
  signal,
}: {
  action: (props: ActionProps) => Promise<DatapointResponse>;
  actionProps: ActionProps;
  firstActionProps?: ActionProps;
  signal?: AbortSignal;
}) => {
  const response = await action({
    ...actionProps,
    ...firstActionProps,
  });

  let shouldCancel = false;

  const markCancel = () => {
    shouldCancel = true;
  };

  signal?.addEventListener('abort', markCancel);

  const getCursor = (response: DatapointResponse) => response[0].nextCursor;

  let nextCursor = getCursor(response);

  let datapoints = response[0].datapoints;

  while (nextCursor) {
    // this eslint rule is made because generally we should do things in parallel
    // but this is a good exception case
    // as these are sequential cursors, so they are ok to block
    // eslint-disable-next-line no-await-in-loop
    const response = await action({
      ...actionProps,
      items: [
        {
          // @ts-expect-error actionProps might be undefined
          ...actionProps.items[0],
          cursor: nextCursor,
        },
      ],
    });
    nextCursor = getCursor(response);
    // @ts-expect-error response might be undefined
    datapoints = [...datapoints, ...response[0].datapoints];

    if (shouldCancel) {
      nextCursor = '';
      shouldCancel = false;
      signal?.removeEventListener('abort', markCancel);
    }
  }

  return datapoints;
};
