import * as React from 'react';

import { CogniteClient } from '@cognite/sdk';
import { useQueryClient } from '@tanstack/react-query';

import { reportError } from '@cntxt/shared/util-errors';

import { MutationEventContext, OfflineMutationEvents } from './types';

export const useRegisterMutations = ({
  platform,
  events,
  client,
  internalClient,
}: {
  platform: string;
  events: (
    data: Pick<MutationEventContext, 'client'>,
  ) => OfflineMutationEvents<unknown>;
  client: CogniteClient;
  internalClient: CogniteClient | undefined;
}) => {
  const queryClient = useQueryClient();
  const [done, setDone] = React.useState(false);

  // in web, which is testing
  // we assume the user has the permissions to write to CDF
  // but for mobile, we assume the user does not, and thus we
  // need the CDF client created with the internal keys
  const authClient = platform === 'web' ? client : internalClient;
  const clientExists = !!authClient;

  React.useEffect(() => {
    if (!clientExists) {
      return;
    }

    const networkOnlineEvents = events({
      client: authClient,
    });

    networkOnlineEvents.forEach((event) => {
      // we need a default mutation function so that paused mutations
      // can resume after a page reload/online
      queryClient.setMutationDefaults(event.key, {
        mutationFn: async (data) => {
          // to avoid clashes with our optimistic update when an offline mutation continues
          await queryClient.cancelQueries({ queryKey: event.key });
          // console.log('setMutationDefaults in provider, id:', data);

          // this is the only place we actually trigger updates to CDF
          return event.mutate(data, event.key);
        },
        onError: (error) => {
          reportError(error, 'Steam Mutation Events');
        },
        // perhaps link up to notifications?
        // onSuccess: (good) => {
        //   console.log('networkOnlineEvents good', good);
        // },
      });
    });

    setDone(true);
    // custom and tested to be ok... ish
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform, clientExists]);

  // if we want to update on changes, this can use used in the useEffect of the caller
  // const setupAll = queryClient.getMutationDefaults(videoKeys.all());
  // const setupRemove = queryClient.getMutationDefaults(videoKeys.remove());

  return { ready: done };
};
