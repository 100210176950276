import React from 'react';

import { AccordionSummary, type SxProps } from '@mui/material';

import { Icon, IconsList } from '@cntxt/shared/ui-icons';

import { Layout, Typography } from '../../material';
import { Styles } from '../../styles';

type Props = {
  iconName?: IconsList;
  text: string;
  sx?: SxProps;
  isCompleted: boolean;
  isReadOnly: boolean;
  isExpanded: boolean;
  showIconOnlyWhenCollapsed?: boolean;
  CustomTextComponent?: React.ComponentType<{ children: React.ReactNode }>;
};
export const ProgressTitle = ({
  iconName,
  text,
  sx,
  isCompleted,
  isExpanded,
  isReadOnly,
  showIconOnlyWhenCollapsed,
  CustomTextComponent,
}: Props) => {
  const titleSx: SxProps = {
    width: '100%',
    flexDirection: 'row-reverse',
    ...sx,
  };

  const TextComponent = CustomTextComponent || Typography.Title.ExtraSmall;

  const completionIcon = isCompleted
    ? IconsList.radioButtonChecked
    : IconsList.radioButton;

  const permissionIcon = isReadOnly ? IconsList.notActive : IconsList.editing;

  const defaultIcon = isReadOnly ? permissionIcon : completionIcon;

  const customIcon =
    !iconName || iconName?.toString() === 'undefined'
      ? null
      : (iconName as IconsList);

  const titleIcon = customIcon || defaultIcon;
  const showIcon = showIconOnlyWhenCollapsed ? !isExpanded : true;

  return (
    <AccordionSummary
      sx={titleSx}
      expandIcon={<Icon iconName={IconsList.expandArrow} />}
    >
      <Layout.BoxHorizontal sx={{ paddingLeft: '5px', ...titleSx }}>
        <Layout.BoxCenter sx={{ gap: Styles.sizes.pixels._1, ...titleSx }}>
          {showIcon && <Icon iconName={titleIcon} sx={{ fontSize: '20px' }} />}

          <TextComponent text={text} sx={{ flex: 1, fontWeight: '500' }}>
            {text}
          </TextComponent>
        </Layout.BoxCenter>
      </Layout.BoxHorizontal>
    </AccordionSummary>
  );
};
