import * as React from 'react';

import { Layout } from '../material/layout';
import { Typography } from '../material/typography';
import { Styles } from '../styles';

type Props = {
  text?: string;
  color?: string;
};

export const NoData: React.FC<Props> = ({
  text = 'No Data',
  color = Styles.colors.text.interactive.default,
}) => {
  return (
    <Layout.BoxCenter sx={styles.container}>
      <Typography.Title.Regular
        text={text}
        style={{
          ...styles.text,
          color,
        }}
      />
    </Layout.BoxCenter>
  );
};
const styles = {
  container: {
    width: Styles.layouts.width.full,
    height: Styles.layouts.width.full,
  },
  text: {
    alignText: 'center',
  },
};
