import * as React from 'react';

import { DrawerRight } from '@cntxt/shared/ui';
import { Inspect as InspectFeature } from '@cntxt/steam/feat-dashboard';

import { InspectPanelView } from '../../Features/InspectPanel/InspectPanelView';

export function Inspect() {
  const [fromUnixTime, setFromUnixTime] = React.useState<number>(0);
  const [toUnixTime, setToUnixTime] = React.useState<number>(0);
  const handleDurationChange = (startTime: number, endTime: number) => {
    setFromUnixTime(startTime);
    setToUnixTime(endTime);
  };
  return (
    <>
      <InspectFeature
        handleDurationChange={handleDurationChange}
        fromUnixTime={fromUnixTime}
        toUnixTime={toUnixTime}
      />
      <DrawerRight>
        <InspectPanelView />
      </DrawerRight>
    </>
  );
}
