import { Cards, Loading } from '@cntxt/shared/ui';

import { CalendarView } from './Calendar/CalendarView';
import { TableView } from './Table/TableView';
import { useData } from './useData';

type Props = {
  dataSetIds: { id: number }[];
  leaf: number;
  detailsRoute: string;
  handleDurationChange: (startTime: number, endTime: number) => void;
  fromUnixTime: number;
  toUnixTime: number;
};
export const DataView: React.FC<Props> = ({
  dataSetIds,
  leaf,
  detailsRoute,
  handleDurationChange,
  fromUnixTime,
  toUnixTime,
}) => {
  const { data, isLoading, id } = useData(
    leaf,
    dataSetIds,
    fromUnixTime,
    toUnixTime,
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Cards.Clear sx={styles.container}>
      <CalendarView
        id={id}
        calendarData={data.calendarData}
        handleDurationChange={handleDurationChange}
      />
      <TableView
        id={id}
        tableData={data.tableData}
        detailsRoute={detailsRoute}
      />
    </Cards.Clear>
  );
};
const styles: Record<string, React.CSSProperties> = {
  container: {
    width: '100%',
    height: '100%',
  },
};
