import { Bad } from './Bad';
import { Clear } from './Clear';
import { Good } from './Good';
import { Neutral } from './Neutral';
import { PolygonBox } from './PolygonBox';

export const Cards = {
  Bad,
  Clear,
  Good,
  Neutral,
  PolygonBox,
};
