import React, { useEffect, useState } from 'react';

import { ImageCardDetails } from '../../Inspect/useData';

import { SwitchCard } from './SwitchCard';

type SwitchCardsProps = {
  cards: ImageCardDetails[];
  handleOnImageClick: (
    index: number,
    imageCardDetails: ImageCardDetails[],
  ) => void;
  handleOnIconCallback: (
    index: number,
    imageCardDetails: ImageCardDetails[],
  ) => void;
};

export const SwitchCards: React.FC<SwitchCardsProps> = ({
  cards,
  handleOnImageClick,
  handleOnIconCallback,
}) => {
  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(
    null,
  );
  const [cardsState, setCardsState] = useState<ImageCardDetails[]>(cards);

  useEffect(() => {
    setCardsState(cards);
  }, [cards, selectedCardIndex]);

  const onImageSelect = (index: number) => {
    setSelectedCardIndex(index);
    if (cards[index]) {
      handleOnImageClick(index, cardsState);
    }
  };

  const onIconClick = (index: number) => {
    if (cards[index]) {
      const isImageSelected = index === selectedCardIndex;
      if (isImageSelected) {
        setSelectedCardIndex(null);
        handleOnIconCallback(-1, cardsState);
      }
      setCardsState((prevCards) =>
        prevCards.filter((_, cardIndex) => cardIndex !== index),
      );
    }
  };

  return (
    <>
      {cardsState.map((card, index) => (
        <SwitchCard
          key={card.title}
          title={card.title}
          imageSrc={card.imageSrc}
          iconCallback={() => onIconClick(index)}
          imageCallback={() => onImageSelect(index)}
          selected={index === selectedCardIndex}
          isIconVisible={false}
        />
      ))}
    </>
  );
};
