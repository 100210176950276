import { CogniteEvent } from '@cognite/sdk/dist/src';

export const getEventById = (
  events: CogniteEvent[],
  id: number,
): CogniteEvent | undefined => {
  const result = events.find((item) => item.id === id);

  return result;
};
