export enum IDP_TYPE {
  'AZURE_AD' = 'AZURE_AD',
  'ADFS2016' = 'ADFS2016',
  'AUTH0' = 'AUTH0',
  'AAD_B2C' = 'AAD_B2C',
  'KEYCLOAK' = 'KEYCLOAK',
  'TESTING' = 'none',
}

export type AuthOptions = {
  clientId: string;
  project: CustomerProject;
  enableOfflineAccess?: boolean;
};

export type TestProject = {
  idpType: IDP_TYPE.TESTING;
};

export type AzureADProject = {
  idpType: IDP_TYPE.AZURE_AD;
  aadDirectory: string;
};
export type ADFSProject = {
  idpType: IDP_TYPE.ADFS2016;
  authority: string;
  logout: string;
};

export type CustomerProject = {
  id: string;
  cluster: string;
  dataSetId?: Record<string, number>;
} & (ADFSProject | AzureADProject | TestProject);
