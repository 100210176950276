import * as React from 'react';
import { Link } from 'react-router-dom';

import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { ColumnDef, RowData } from '@tanstack/react-table';

import { Icons } from '@cntxt/shared/ui-icons';
import { getInspectionDetailsRoute } from '@cntxt/steam/util-ui';

import { SteamTrapTableData } from '../Inspection/TableView';

import {
  CellIcon,
  CellText,
  HeadContainer,
  HeaderContainer,
  RowContainer,
  TableContainer,
} from './elements';
import { TableColumnSortIcons } from './TableColumnSortIcons';
import { TableData as TableViewData } from './TableView';

export type ColumnsConfiguration = Record<
  string,
  {
    text: string;
    sortable?: boolean;
    component?: React.ReactElement;
    renderComponent?: React.FC<{ id: string; rowData: RowData; value: string }>;
  }
>;

export type TableData = TableViewData | SteamTrapTableData;
export const Table: React.FC<
  React.PropsWithChildren<{
    data: TableData[];
    detailsRoute?: string;
    columns: ColumnDef<unknown>[];
  }>
> = ({ data, detailsRoute, columns, children }) => {
  const table = useReactTable({
    data,
    columns,
    filterFromLeafRows: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  });

  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const handleRowClick = (_row: unknown) => () => {
    // console.log('row', row);
  };

  return (
    <div ref={tableContainerRef}>
      <TableContainer>
        <HeadContainer>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <HeaderContainer
                        {...{
                          'aria-label': 'sortable',
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : '',
                          $minwidth: header.getSize() + 'px', //NOTE - 'minWidth' not 'minwidth' will throw an error. use $ to bypass
                        }}
                        role="button"
                        tabIndex={index}
                      >
                        <CellText>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </CellText>
                        {header.column.getCanSort() && (
                          <CellIcon>
                            <TableColumnSortIcons
                              state={
                                (header.column.getIsSorted() as string) ?? null
                              }
                              onClick={header.column.getToggleSortingHandler()}
                            />
                          </CellIcon>
                        )}
                      </HeaderContainer>
                    )}
                  </th>
                );
              })}
              {/** to cover the extra element for: 'hover' */}
              <th></th>
              {/** to cover the extra element for: '>' */}
              <th></th>
            </tr>
          ))}
        </HeadContainer>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            const { metadata } = row?.original as TableViewData;

            return (
              <RowContainer key={row.id} onClick={handleRowClick(row)}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  );
                })}
                {children}
                {detailsRoute && (
                  <td>
                    <Link
                      to={getInspectionDetailsRoute(
                        detailsRoute,
                        metadata.datasetId,
                        metadata.parentId,
                        metadata.eventId,
                      )}
                    >
                      <Icons.RightArrow />
                    </Link>
                  </td>
                )}
              </RowContainer>
            );
          })}
        </tbody>
      </TableContainer>
    </div>
  );
};
