import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { Layout } from '@cntxt/shared/ui';

export const MenuBar: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Layout.Spacer />
      <Card
        sx={{
          flexGrow: '1',
          minWidth: '49px',
          marginLeft: '10px',
          boxShadow:
            '0px 1px 16px 4px rgba(79, 82, 104, 0.1), 0px 1px 8px rgba(79, 82, 104, 0.08), 0px 1px 2px rgba(79, 82, 104, 0.24)',
          borderRadius: '8px',
        }}
      >
        <CardContent
          sx={{
            padding: '0px',
            flexDirection: 'column',
            display: 'flex',
            height: '100%',
            rowGap: '4px',
            alignItems: 'center',
            a: {
              height: '41px',
            },
          }}
        >
          {children}
        </CardContent>
      </Card>
      <Layout.Spacer />
    </Box>
  );
};
