import * as React from 'react';

export type TelemetryOptions = {
  name: string;
  url: string;
  disabled?: boolean;
};
// disabled till we resolve the dependencies
export const OpenTelemetryProvider: React.FC<
  React.PropsWithChildren<TelemetryOptions>
> = ({ children }) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
