import { AuthStateUser } from '@cntxt/shared/util-auth';
import { reportError } from '@cntxt/shared/util-errors';

import { ADFSBase } from './ADFSBase';
import { Flow } from './Flow';

export abstract class ADFSBaseFlow extends Flow {
  static override user: AuthStateUser;
  protected abstract adfs: ADFSBase;

  override async getTokenFactory(): Promise<() => Promise<string>> {
    let token: string | void | null = await this.adfs.login();

    if (!token) {
      try {
        token = await this.adfs.getCDFToken();
      } catch (error) {
        reportError(error, 'Login error');
        // this should not be an error
        // its when the flow does: slient -> login
        throw new Error('Failed to get token, please try again.');
      }
    }

    return async () => {
      return token || '';
    };
  }

  private extractName(inputName: string): string {
    // Remove the first part before the double backslash (\\)
    const parts = inputName.split('\\');
    const name = parts[1] || inputName;

    // Split the name on dot (.)
    const nameParts = name.split('.');

    // Convert each part to title case
    const titleCaseName = nameParts
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
      .join(' ');

    return titleCaseName;
  }

  override async getUserState(): Promise<AuthStateUser> {
    const idToken = await this.adfs.getIdToken();
    if (!idToken)
      throw new Error('No ID token found. Check application configuration.');
    return {
      id: idToken.sub,
      email: idToken.upn,
      name: this.extractName(idToken.unique_name),
      idToken: idToken.token_string,
      accessToken: idToken.accessToken,
    };
  }
}
