import * as React from 'react';

import type { SxProps } from '@mui/material';

import { Layout, Styles } from '@cntxt/shared/ui';

import { MenuBar } from './MenuBar';
import { MenuBarDefaults } from './MenuBarDefaults';
import { SidebarTreeView } from './SidebarTreeView';

type Prop = {
  sx?: SxProps;
  assetTreeLayout?: boolean;
};
export const Sidebar: React.FC<React.PropsWithChildren<Prop>> & {
  MenuBar: typeof MenuBar;
  MenuBarDefaults: typeof MenuBarDefaults;
  AssetTree: typeof SidebarTreeView;
} = ({ children, sx, assetTreeLayout }) => {
  return (
    <Layout.SmallLeftLargeRight
      sx={{
        ...(assetTreeLayout ? assetTreeLayoutStyles : justMenubarLayoutStyles),
        ...sx,
      }}
    >
      <Layout.FlexBox>{children}</Layout.FlexBox>
    </Layout.SmallLeftLargeRight>
  );
};

Sidebar.MenuBar = MenuBar;
Sidebar.MenuBarDefaults = MenuBarDefaults;
Sidebar.AssetTree = SidebarTreeView;

const justMenubarLayoutStyles = {
  maxWidth: '60px',
};

const assetTreeLayoutStyles = {
  borderRight: `1px solid ${Styles.colors.border.default}`,
  overflow: 'hidden',
  maxWidth: '320px',
};
