import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

import { BaseIcon } from './BaseIcon';

export const User = (
  {
    onClick,
    variant,
  }: { onClick?: (event: unknown) => void; variant: 'normal' | 'outlined' } = {
    variant: 'normal',
  },
) => {
  if (variant === 'outlined') {
    return <BaseIcon Icon={PersonOutlineIcon} onClick={onClick} />;
  }

  return <BaseIcon Icon={PersonIcon} onClick={onClick} />;
};
