import * as React from 'react';

import { Layout } from '@cntxt/shared/ui';
import { Dashboard as DashboardFeature } from '@cntxt/steam/feat-dashboard';
import { ROUTES } from '@cntxt/steam/util-ui';

import { environment } from './../../../environments/environment';
import { TopBar } from './TopBar';

export const Dashboard: React.FC = () => {
  const [isLastDateActive, setIsLastDateActive] =
    React.useState<boolean>(false);
  return (
    <Layout.BoxVertical sx={styles.container}>
      <TopBar handleLastDate={setIsLastDateActive} />
      <Layout.PageContent>
        <DashboardFeature
          dataSetIds={[{ id: environment.datasetId }]}
          detailsRoute={`${ROUTES.DETAILS}`}
          isLastDateActive={isLastDateActive}
        />
      </Layout.PageContent>
    </Layout.BoxVertical>
  );
};
const styles: Record<string, React.CSSProperties> = {
  container: { width: '100%', maxWidth: '1280px', gap: 1 },
};
