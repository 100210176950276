import { CogniteClient } from '@cognite/sdk';
import { useQuery } from '@tanstack/react-query';

import { fetchAssets } from '../../network/fetchAssets';

import { ASSET_KEYS } from './keys';

export const useAssetRootQuery = ({
  client,
  dataSetIds,
}: {
  client: CogniteClient;
  dataSetIds: { id: number }[];
}) => {
  return useQuery({
    queryKey: ASSET_KEYS.ROOTS(),
    queryFn: () =>
      fetchAssets({
        client,
        filter: {
          root: true,
          dataSetIds,
        },
      }),
  });
};
