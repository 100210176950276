import { QueryClient } from '@tanstack/react-query';

/*
 * This is a generic function to help with adding things
 * to query-cache. It can quickly append an item to a list
 * very useful when dealing with your 'all' keys
 */
export const addThingToCache = <Thing>(
  queryClient: QueryClient,
  newThing: Thing,
  key: string[],
) => {
  console.log('Updating:', newThing, { key });
  return queryClient.setQueryData<Thing[]>(key, (allThings) => {
    return allThings ? [...allThings, newThing] : [newThing];
  });
};
