import { CogniteClient, EventFilter } from '@cognite/sdk';

export const fetchEvents = async ({
  client,
  filter,
}: {
  client: CogniteClient;
  filter: EventFilter;
}) => {
  const result = await client.events.search({
    filter,
  });

  return result;
};
