import * as React from 'react';

import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';

import { Styles } from '@cntxt/shared/ui';
import { Icons } from '@cntxt/shared/ui-icons';

export const TableColumnSortIcons: React.FC<{
  state: boolean | string;
  onClick?: (event: unknown) => void;
}> = ({ state, onClick }) => {
  if (state === 'desc') {
    return <Icons.Ascending onClick={onClick} />;
  }
  if (state === 'asc') {
    return (
      <Icons.Descending onClick={onClick} theme={Styles.colors.text.default} />
    );
  }

  return <SortByAlphaIcon aria-label="Click to sort" onClick={onClick} />;
};
