import * as React from 'react';
import type { ImageListType } from 'react-images-uploading';
import ImageUploading from 'react-images-uploading';

import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';

import { BackComponent, Buttons, Layout, Styles } from '@cntxt/shared/ui';
import { Icons } from '@cntxt/shared/ui-icons';

import { RightPanel } from './RightPanel';
export const Upload: React.FC = () => {
  const [images, setImages] = React.useState<ImageListType>([]);
  const [trapId, setTrapId] = React.useState<number | null>();
  const [isUploading, setIsUploading] = React.useState(false);
  const maxNumber = 100;
  const dropZoneWidth = images.length > 0 ? '80%' : '100%';

  const { enqueueSnackbar } = useSnackbar();

  const onSetId = (_event: unknown, newValue: { id: number } | null) => {
    setTrapId(newValue?.id);
  };

  const onUpload = async () => {
    if (!trapId) {
      console.log('Missing trap id');
      return;
    }
    if (!images[0].data_url) {
      console.log('Missing data_url info', images[0].data_url);
      return;
    }
    if (!images[0].file?.name) {
      console.log('Missing file info', images[0].file);
      return;
    }
    setIsUploading(true);
    enqueueSnackbar('Uploading...');

    enqueueSnackbar('Image uploaded', { variant: 'success' });
    setIsUploading(false);
  };

  const onChange = (
    imageList: ImageListType,
    addUpdatedIndex?: number[] | undefined,
  ) => {
    // data for submit
    console.log(imageList, addUpdatedIndex);
    setImages(imageList);
  };

  const showRightBar = images.length > 0;
  const showRemoveAllButton = images.length > 0;

  return (
    <Layout.PageContent>
      <BackComponent to={'/'} text="Upload images" />

      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={['jpg']}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <Box
            className="upload__image-wrapper"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                minHeight: '34px',
                width: dropZoneWidth,
              }}
            >
              <Layout.Expander />
              {showRemoveAllButton && (
                <Buttons.Button
                  onClick={onImageRemoveAll}
                  text="Remove all images"
                  sx={{
                    maxWidth: '220px',
                    backgroundColor: Styles.colors.surface.default,
                    color: Styles.colors.text.default,
                    border: Styles.colors.border.default,
                  }}
                />
              )}
            </Box>
            <Buttons.Button
              {...dragProps}
              onClick={onImageUpload}
              text="Click or Drop images here"
              sx={{
                border: `2px dashed ${Styles.colors.border.default}`,
                width: dropZoneWidth,
                height: '200px',
                ...(isDragging ? { color: 'red' } : { color: 'black' }),
              }}
            />
            <Layout.BoxHorizontal>
              {imageList.map((image, index) => (
                <Box key={index}>
                  <img src={image['data_url']} alt="" width="100" />
                  <Box>
                    <Icons.Delete
                      onClick={() => onImageRemove(index)}
                      color={Styles.colors.text.status.critical}
                    />
                  </Box>
                </Box>
              ))}
            </Layout.BoxHorizontal>
          </Box>
        )}
      </ImageUploading>

      {showRightBar && (
        <RightPanel
          onUpload={onUpload}
          isUploading={isUploading}
          onSetId={onSetId}
        />
      )}
    </Layout.PageContent>
  );
};
