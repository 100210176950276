import { CogniteClient, ExternalEvent } from '@cognite/sdk';

import { createEvent } from '@cntxt/shared/data-access-cdf';

import { UPLOAD_EVENT_METADATA } from '../constants';

export const createUploadEvent = async ({
  client,
  event,
}: {
  client: CogniteClient;
  event: ExternalEvent;
}) => {
  const uploadEventMetadata = {
    ...UPLOAD_EVENT_METADATA,
  };

  const events = await createEvent({
    client,
    event: {
      ...event,
      ...uploadEventMetadata,
    },
  });

  if (!events || events.length === 0) {
    throw new Error('Create upload event returned bad results.');
  }

  return events[0];
};
