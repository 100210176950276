import { ADFSBaseFlow, StorageProvider } from '@cntxt/shared/providers/shared';
import {
  ADFSProject,
  AuthStateUser,
  CustomerProject,
} from '@cntxt/shared/util-auth';

import { ADFS } from './ADFS';

export class ADFSFlow extends ADFSBaseFlow {
  static override user: AuthStateUser;
  protected adfs: ADFS;

  constructor(
    projectInfo: CustomerProject & ADFSProject,
    storageProvider: StorageProvider,
    clientId: string,
  ) {
    super();

    this.adfs = new ADFS({
      authority: projectInfo.authority,
      requestParams: {
        clientId,
        resource: projectInfo.cluster,
      },
      storageProvider,
    });
  }
}
