import * as React from 'react';

import { Divider, Layout } from '@cntxt/shared/ui';
import { Icons } from '@cntxt/shared/ui-icons';

import { MatchedLink } from './MatchedLink';

export const MenuBarDefaults: React.FC<
  React.PropsWithChildren<{ routes: { PAGE: string; SETTINGS: string } }>
> = ({ children, routes }) => {
  return (
    <>
      <MatchedLink to={routes.PAGE}>
        <Icons.Home />
      </MatchedLink>
      {children}
      <Layout.Expander />
      <div>
        <Divider />
      </div>
      <MatchedLink to={routes.SETTINGS}>
        <Icons.Settings />
      </MatchedLink>
    </>
  );
};
