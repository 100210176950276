import { RenderTree } from '@cntxt/steam/data-access';
import { Breadcrumb } from '@cntxt/steam/ui-mobile';

type FindAssetResult = {
  foundAsset: RenderTree | null;
  parent: RenderTree | null;
  breadcrumbs: Breadcrumb[];
};
export const findAssetByIdWithBreadcrumbs = (
  nodes: RenderTree[],
  idOrExternalId: string,
  path: Breadcrumb[] = [],
  parent: RenderTree | null = null,
): FindAssetResult => {
  for (const node of nodes) {
    const newPath =
      node.name.toLowerCase() !== 'root'
        ? [...path, { id: node.id, label: node.name }]
        : path;

    const isCorrectNode =
      node.id === idOrExternalId || node.externalId === idOrExternalId;
    if (isCorrectNode) {
      return {
        foundAsset: node,
        parent: parent,
        breadcrumbs: newPath.slice(0, -1),
      };
    }
    if (node.children) {
      const result = findAssetByIdWithBreadcrumbs(
        node.children,
        idOrExternalId,
        newPath,
        node,
      );
      if (result.foundAsset) {
        return result;
      }
    }
  }
  return { foundAsset: null, parent: null, breadcrumbs: [] };
};
