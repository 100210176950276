import queryString from 'query-string';

export const removeAccessTokenFromUrl = (): void => {
  const url = new URL(window.location.href);

  if (url.hash) {
    const queryParams = queryString.parse(url.hash);

    const queryParametersToRemove = [
      'access_token',
      'token_type',
      'expires_in',
      'id_token',
      'scope',
    ];

    // Check if the access_token parameter exists in the URL
    if (queryParametersToRemove.some((param) => queryParams[param])) {
      // Remove all query parameters in queryParametersToRemove
      queryParametersToRemove.forEach((param) => {
        delete queryParams[param];
      });

      // Check if there are remaining parameters before updating the URL
      const updatedQueryString = queryString.stringify(queryParams);
      if (updatedQueryString) {
        // Reconstruct the URL with remaining query parameters and the original hash
        url.hash = `#${updatedQueryString}`;
      } else {
        // No remaining parameters, remove the hash
        url.hash = '';
      }

      // Replace the current URL without reloading the page
      window.history.replaceState({}, document.title, url.toString());
    }
  }
};
