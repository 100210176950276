import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Autocomplete } from '@cntxt/shared/ui';
import { sanitizeHtml, sanitizeSql } from '@cntxt/shared/util-ui';
import {
  ROUTES,
  getAssetDashboardRoute,
  getInspectionDetailsRoute,
} from '@cntxt/steam/util-ui';

import { environment } from '../../../environments/environment';

import { SearchData, useData } from './useData';

export type Props = {
  assetURLToRoute: string;
};

export const SearchTextField = ({ assetURLToRoute }: Props) => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');

  const { searchData } = useData(searchValue);

  const handleSelect = (
    _event: React.SyntheticEvent,
    newValue: SearchData | string | null,
  ) => {
    if (!newValue || typeof newValue === 'string') {
      return;
    }
    setSearchValue(newValue.label || '');
    switch (newValue.type) {
      case 'event':
        navigate(
          getInspectionDetailsRoute(
            ROUTES.DETAILS,
            environment.datasetId,
            newValue.parentId,
            newValue.id,
          ),
        );
        break;
      case 'asset':
        navigate(
          getAssetDashboardRoute(
            assetURLToRoute,
            environment.datasetId,
            newValue.parentId,
            newValue.id,
          ),
        );
        break;
    }
  };

  const handleInputChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: string,
  ) => {
    let sanitizedValue = sanitizeHtml(value);
    sanitizedValue = sanitizeSql(value);
    sanitizedValue = sanitizedValue.trim();
    setSearchValue(sanitizedValue);
  };

  return (
    <Autocomplete
      data={searchData}
      id="search-autocomplete"
      onSelect={handleSelect}
      onInputChange={handleInputChange}
      isFreeSolo={true}
      label="Search"
    />
  );
};
