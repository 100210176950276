import { Pie as PieChart } from 'react-chartjs-2';

import type { PluginChartOptions, ChartData } from 'chart.js';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { useTheme } from '../../providers';

ChartJS.register(ArcElement, Tooltip, Legend);

export const Pie: React.FC<{
  data: ChartData<'pie', number[], unknown>;
  title?: string;
}> = ({ data, title }) => {
  const theme = useTheme();

  const options: Partial<PluginChartOptions<'pie'>> = {
    plugins: {
      // @ts-expect-error missing props
      title: {
        display: true,
        color: theme.text.default,
        text: title || '',
      },
      circumference: 360,
      radius: '100%',
      spacing: 0,
      animation: false,
    },
  };

  return <PieChart data={data} options={options} />;
};
