import * as React from 'react';

import { Cards } from '@cntxt/shared/ui';

import { CardContent } from './CardContent';

export const Blocked: React.FC<
  Omit<React.ComponentProps<typeof CardContent>, 'title'>
> = ({ value, total }) => {
  return (
    <Cards.Bad>
      <CardContent title="Unhealthy Blocked" value={value} total={total} />
    </Cards.Bad>
  );
};
