import { CogniteClient, FileInfo } from '@cognite/sdk';

import { findFiles } from '@cntxt/shared/data-access-cdf';

type Props = {
  client: CogniteClient;
  dataSetId: number;
  eventExternalId?: string;
  limit: number;
};
export const fetchEventImages = async ({
  client,
  dataSetId,
  eventExternalId,
  limit,
}: Props) => {
  if (!eventExternalId) {
    return [];
  }

  const files: FileInfo[] = await findFiles(client, {
    limit,
    filter: {
      dataSetIds: [{ id: dataSetId }],
      mimeType: 'image/jpeg',
      metadata: {
        eventExternalId: eventExternalId,
      },
    },
  });
  return files;
};
