import { RenderTree } from '@cntxt/steam/data-access';

type FindAssetSearchResult = {
  foundAssets: RenderTree[];
};
export const findAssetsByNameOrExternalId = (
  nodes: RenderTree[],
  nameOrExternalId: string,
  path: RenderTree[] = [],
): FindAssetSearchResult => {
  const matches: FindAssetSearchResult = { foundAssets: [] };

  nodes.forEach((node) => {
    const newPath = node.name.toLowerCase() !== 'root' ? [...path, node] : path;

    const isPartOfNameOrExternalId =
      node.externalId?.includes(nameOrExternalId) ||
      node.name.includes(nameOrExternalId);
    if (isPartOfNameOrExternalId) {
      matches.foundAssets.push(node);
    }
    if (node.children) {
      const childMatches = findAssetsByNameOrExternalId(
        node.children,
        nameOrExternalId,
        newPath,
      );
      matches.foundAssets = matches.foundAssets.concat(
        childMatches.foundAssets,
      );
    }
  });

  return matches;
};
