import React from 'react';

import { createColumnHelper, type RowData } from '@tanstack/react-table';

import { Layout } from '../../material/layout';

export type ColumnsConfiguration = Record<
  string,
  {
    text: string;
    sortable?: boolean;
    component?: React.ReactElement;
    renderComponent?: React.FC<{
      id: string;
      rowData: RowData;
      value: unknown;
    }>;
  }
>;

const columnHelper = createColumnHelper<Record<string, string>>();
export const getColumnsConfiguration = (
  columnsConfiguration: ColumnsConfiguration,
) => {
  const configurations = [];
  for (const column in columnsConfiguration) {
    const columnData = columnsConfiguration[column];
    configurations.push(
      columnHelper.accessor(column, {
        header: () => columnData.text,
        minSize: 100,
        enableSorting: columnData.sortable || false,
        footer: (props) => props.column.id,
        cell: ({ getValue, row }) => {
          // check if columnData has a component, if yes render it and pass rowData as props
          if (columnData.component) {
            return React.cloneElement(columnData.component, {
              id: row.id,
              rowData: row.original,
              value: getValue(),
            });
          }
          if (columnData.renderComponent) {
            return columnData.renderComponent({
              id: row.id,
              rowData: row.original,
              value: getValue(),
            });
          }

          const value = getValue() as string;
          return <Layout.BoxCenter>{value}</Layout.BoxCenter>;
        },
      }),
    );
  }
  return configurations;
};
