import * as React from 'react';

import type { SxProps } from '@mui/material';

import { Sidebar } from '@cntxt/shared/ui-sidebar';
import { ROUTES } from '@cntxt/steam/util-ui';

import { Menubar } from '../Menubar/Menubar';

export const SteamSidebar: React.FC<
  React.PropsWithChildren<{ sx?: SxProps; assetTreeLayout?: boolean }>
> = ({ children, sx, assetTreeLayout }) => {
  return (
    <Sidebar sx={sx} assetTreeLayout={assetTreeLayout}>
      <Sidebar.MenuBar>
        <Sidebar.MenuBarDefaults routes={ROUTES}>
          <Menubar />
        </Sidebar.MenuBarDefaults>
      </Sidebar.MenuBar>
      {children}
    </Sidebar>
  );
};
