export type CustomErrorType = {
  code: number;
  // It will be handled once we integrate schema validation
  isAutoRetryable?: boolean;
  missing?: Record<string, string>;
} & Error;

const getError = (code: number, message: string, name = 'unknown-error') => ({
  code,
  name,
  message,
});

type ErrorTypes = 'badRequest' | 'unauthorized' | 'notFound' | 'internalError';

export const HTTPErrors: Record<
  ErrorTypes,
  (message?: string, name?: string) => CustomErrorType
> = {
  badRequest: (message = 'Bad request', name = 'Bad request') =>
    getError(400, message, name),
  unauthorized: (message = 'Unauthorized', name = 'Unauthorized') =>
    getError(401, message, name),
  notFound: (message = 'Not found', name = 'Not found') =>
    getError(404, message, name),
  internalError: (
    message = 'An internal server error has occurred',
    name = 'InternalError',
  ) => getError(500, message, name),
};
