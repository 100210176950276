import { EchartsCalendarOption } from '@cntxt/shared/ui';
import { DateFormat, getDateWithFormat } from '@cntxt/shared/util-date';

const dateWithData = '#009988';
const dateWithNoData = '#D9D9D9';
const futureDate = '#F6F6F6';

enum CellStatus {
  default = -1,
  dateWithData = 1,
  dateWithNoData = 0,
}
const formatMonth = (month: number) => {
  const yearWithMonth = `${month}`;
  return getDateWithFormat(yearWithMonth, DateFormat.FULL_MONTH_NAME);
};

const generateDateRange = (startDate: Date, endDate: Date): Date[] => {
  const dates: Date[] = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

const generateCalendarConfig = (
  startDate: Date,
  endDate: Date,
): Partial<EchartsCalendarOption['calendar']>[] => {
  const startMonth = startDate.getMonth();
  const endMonth = endDate.getMonth();
  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  const calendars: Partial<EchartsCalendarOption['calendar']>[] = [];

  for (let year = startYear; year <= endYear; year++) {
    const monthStart = year === startYear ? startMonth : 0;
    const monthEnd = year === endYear ? endMonth : 11;

    for (let month = monthStart; month <= monthEnd; month++) {
      calendars.push({
        top: 'center',
        left: `center`,
        range: `${year}-${String(month + 1).padStart(2, '0')}`,
        cellSize: 17.5,
        splitLine: { show: false },
        yearLabel: { show: false },
        dayLabel: { show: false },
        monthLabel: {
          position: 'end',
          formatter: (param: { MM: string; yyyy: string }) =>
            `${formatMonth(Number(param.MM))}-${param.yyyy}`,
        },
        itemStyle: {
          borderColor: 'transparent', // Set border color to transparent
        },
      });
    }
  }

  return calendars;
};

const generateOptions = (
  completeDate: (string | number)[][],
  monthlyCalendar: Partial<EchartsCalendarOption['calendar']>[],
): Partial<EchartsCalendarOption>[] => {
  const maxDataValue = completeDate.reduce((max, current) => {
    const numberValue = typeof current[1] === 'number' ? current[1] : 0;
    return numberValue > max ? numberValue : max;
  }, 0);
  const monthlyOption: Partial<EchartsCalendarOption>[] = monthlyCalendar.map(
    (calendar: Partial<EchartsCalendarOption['calendar']>) => {
      return {
        visualMap: {
          show: false,
          pieces: [
            {
              min: CellStatus.dateWithData,
              max: maxDataValue,
              color: dateWithData,
            },
            {
              min: CellStatus.dateWithNoData,
              max: CellStatus.dateWithNoData,
              color: dateWithNoData,
            },
            {
              min: CellStatus.default,
              max: CellStatus.default,
              color: futureDate,
            },
          ],
        },
        calendar,
        series: [
          {
            type: 'heatmap',
            coordinateSystem: 'calendar',
            data: completeDate,
          },
        ],
      } as Partial<EchartsCalendarOption>;
    },
  );
  return monthlyOption;
};

const mapDatesToDataStatus = (
  allDates: Date[],
  data: [string, number][],
): (string | number)[][] => {
  const dataMap = new Map(
    data.map(([date, status]) => [date.split(' ')[0], status]),
  );
  const todayStr = new Date().toISOString().split('T')[0];

  const mappedDates = allDates.map((date) => {
    const dateString = date.toISOString().split('T')[0];
    const existingData = dataMap.get(dateString);

    if (dateString > todayStr) {
      return [dateString, CellStatus.default];
    } else if (existingData !== undefined) {
      return [dateString, existingData];
    } else {
      return [dateString, CellStatus.dateWithNoData];
    }
  });

  return mappedDates;
};

export const getData = (
  data: [string, number][],
  startDate: Date,
  endDate: Date,
): Partial<EchartsCalendarOption>[] => {
  const allDates = generateDateRange(startDate, endDate);

  const mappedDates = mapDatesToDataStatus(allDates, data);

  const monthlyCalendar = generateCalendarConfig(startDate, endDate);
  const monthlyCalendarOptions = generateOptions(mappedDates, monthlyCalendar);
  return monthlyCalendarOptions;
};
