import React from 'react';

import type { SelectChangeEvent } from '@mui/material';
import sortBy from 'lodash/sortBy';

import {
  Layout,
  Loading,
  Select,
  SelectOption,
  Styles,
} from '@cntxt/shared/ui';

type Status = {
  name: string;
  id: number;
};
type Props = {
  onStatusChange: (inspectionStatus: Status) => void;
};
type Id = Status['id'];
export const StatusSelector: React.FC<Props> = ({ onStatusChange }) => {
  const [selectedStatus, setSelectedStatus] = React.useState<Id | undefined>();
  const statusOptions = [
    {
      id: 100,
      name: 'All',
    },
    {
      id: 101,
      name: 'Completed',
    },
    {
      id: 102,
      name: 'Pending',
    },
  ];
  const handleLocationChange = (event: SelectChangeEvent<Id>) => {
    const statusName = event.target.value;
    const status = statusOptions?.find(
      (inspectionStatus) => inspectionStatus.id === statusName,
    );
    setSelectedStatus(status?.id);
  };

  // set initial status
  React.useEffect(() => {
    const withoutSelection =
      statusOptions && statusOptions.length > 0 && !selectedStatus;
    if (withoutSelection) {
      setSelectedStatus(sortBy(statusOptions, 'name')[0].id);
    }
    // this is because we only want this to run once on mount, to set the default
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusOptions?.length]);

  React.useEffect(() => {
    const selectedInspectionStatus = statusOptions?.find(
      (inspectionStatus) => inspectionStatus.id === selectedStatus,
    );
    if (selectedInspectionStatus) {
      onStatusChange(selectedInspectionStatus);
    }
  }, [selectedStatus]);

  const withoutOptionsAndSelection = !statusOptions || !selectedStatus;
  if (withoutOptionsAndSelection) {
    console.log('No status data, still loading...');
    return <Loading />;
  }

  const statusCollection: SelectOption<Id>[] = sortBy(
    statusOptions.map((item) => ({
      value: item.id,
      label: item.name,
    })),
    'label',
  );

  return (
    <Layout.BoxRelative>
      <Select<Id>
        label="Inspection Status"
        selectedValue={selectedStatus}
        onChange={handleLocationChange}
        options={statusCollection}
        layoutSx={style.layout}
        selectSx={style.select}
        labelProps={style.label}
      />
    </Layout.BoxRelative>
  );
};

const style = {
  layout: {
    width: '250px',
    height: '70px',
    color: Styles.colors.text.default,
  },
  select: {
    color: 'inherit',
    borderBottom: `1px solid ${Styles.colors.text.interactive.default}`,
    '&::after': { borderBottom: `none` },
    '& .MuiSelect-icon': { color: 'inherit' },
    paddingLeft: 2,
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },

  label: {
    color: Styles.colors.text.default,
    fontSize: Styles.sizes.raw._25,
    fontFamily: Styles.fonts.typography.sectionHeaderSmall,
  },
};
