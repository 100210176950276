import * as React from 'react';

import { styled } from 'styled-components';

import { logos } from '@cntxt/shared/ui-assets/images/index';

export const CNTXTLogo: React.FC = () => {
  return <Logo />;
};

const Logo = styled.div`
  width: 100%;
  height: 100px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${logos.logoCntxt2024 as unknown as string});
`;
