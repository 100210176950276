import { AssetFilterProps, CogniteClient, IdEither } from '@cognite/sdk';

import { fetchAssets } from './fetchAssets';

export const fetchAssetTree = async ({
  client,
  dataSetId,
  assetSubtreeIds,
}: {
  client: CogniteClient;
  dataSetId: number;
  assetSubtreeIds?: IdEither[];
}) => {
  const filter: AssetFilterProps = {
    dataSetIds: [{ id: dataSetId }],
  };

  if (assetSubtreeIds) {
    filter.assetSubtreeIds = assetSubtreeIds;
  }

  return fetchAssets({
    client,
    filter,
  });
};
