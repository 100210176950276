import { styled } from 'styled-components';

import { Styles } from '@cntxt/shared/ui';

// Hover cell (the one that pops out from the right)
export const HoverContentWrapper = styled.span`
  align-items: center;
  background: inherit;
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0;
  padding: 0 !important;
  pointer-events: none;
  position: sticky;
  right: 0;
  top: 0;
  transform: translateX(-4px);
  transition-delay: 0.025s;
  transition-timing-function: ease-out;
  transition: 0.2s;
  z-index: 2;
`;

export const TableContainer = styled.table`
  width: 100%;
  height: 100%;
  border-spacing: inherit;
  background: ${Styles.colors.surface.muted};
  padding: 10px;
  border-radius: 12px;
`;
export const HeaderContainer = styled.div`
  display: flex;
  padding: 10px;
`;
export const HeadContainer = styled.thead`
  background: ${Styles.colors.surface.backdrop};
  height: 44px;
  color: ${Styles.colors.text.default};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;
export const CellText = styled.div`
  width: 100%;
`;
export const CellIcon = styled.div`
  align-items: center;
  display: flex;
`;
export const RowContainer = styled.tr`
  height: 44px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  border-spacing: inherit;
  td {
    border-bottom: 1px solid ${Styles.colors.border.default};
    padding: 6px;
  }

  &:hover {
    & td > ${HoverContentWrapper} {
      opacity: 1;
      pointer-events: initial;
    }
  }
`;
