import CogniteClient from '@cognite/sdk/dist/src/cogniteClient';
import { Asset, IdEither } from '@cognite/sdk/dist/src/types';
import { useQuery } from '@tanstack/react-query';

import { findAssetsByFilter } from '@cntxt/shared/data-access-cdf';

import { ASSET_KEYS } from './keys';

type Props = {
  client: CogniteClient;
  dataSetIds: IdEither[];
  externalId: string;
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
  enabled?: boolean;
};

export const useFindAssetsByExternalIdQuery = ({
  client,
  dataSetIds,
  externalId,
  refetchOnWindowFocus = true,
  refetchOnMount = true,
  enabled = true,
}: Props) => {
  return useQuery({
    queryKey: ASSET_KEYS.ALL(),
    queryFn: () =>
      findAssetsByFilter({
        client,
        filter: {
          dataSetIds,
        },
      }),
    select: (data: Asset[]) =>
      data.filter(
        (asset) => asset.externalId && asset.externalId.startsWith(externalId),
      ),
    refetchOnWindowFocus,
    refetchOnMount,
    enabled,
  });
};
