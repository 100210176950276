import * as React from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import type { SxProps } from '@mui/material';
import { TreeView as MaterialTreeView, TreeItem } from '@mui/x-tree-view';

type TreeData = {
  id: string;
  name: string;
  route?: string;

  children?: readonly TreeData[];
};

const renderTree = (
  nodes: TreeData,
  {
    routePrefix,
    onNavigate,
  }: { routePrefix?: string; onNavigate: (route: string) => void },
): JSX.Element => {
  if (nodes.id === 'root' && Array.isArray(nodes.children))
    return (
      <>
        {nodes.children.map((node) =>
          renderTree(node, { routePrefix, onNavigate }),
        )}
      </>
    );

  // used to be: if (nodes.route)
  if (nodes.id) {
    return (
      <TreeItem
        key={`${routePrefix}/${nodes.id}`}
        nodeId={`${routePrefix}/${nodes.id}`}
        label={nodes.name}
        onClick={() => onNavigate(`${routePrefix}/${nodes.id}`)}
        className="leaf"
        sx={{
          padding: '8px 12px 8px 0px',
        }}
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) =>
              renderTree(node, {
                routePrefix: `${routePrefix}/${nodes.id}`,
                onNavigate,
              }),
            )
          : null}
      </TreeItem>
    );
  }

  // not used, as we render links on all nodes at the moment
  return (
    <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) =>
            renderTree(node, {
              routePrefix: `${routePrefix}/${nodes.route}`,
              onNavigate,
            }),
          )
        : null}
    </TreeItem>
  );
};

type Props = {
  data: TreeData;
  routePrefix?: string;
  sx?: SxProps;
  onNavigate: (route: string) => void;
  defaultExpanded?: string[];
  defaultSelected?: string;
};
export const TreeView: React.FC<Props> = ({
  sx,
  data,
  routePrefix,
  onNavigate,
  defaultExpanded,
  defaultSelected,
}) => {
  const [expanded, setExpanded] =
    React.useState<Props['defaultExpanded']>(defaultExpanded);
  const [selected, setSelected] =
    React.useState<Props['defaultSelected']>(defaultSelected);

  // this is to avoid:
  //
  // A component is changing the default selected state of an
  // uncontrolled TreeView after being initialized.
  React.useEffect(() => {
    setExpanded(undefined);
    setSelected(undefined);
  }, []);

  return (
    <MaterialTreeView
      aria-label="navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      defaultSelected={selected}
      defaultExpanded={expanded}
      sx={{ overflowY: 'auto', ...sx }}
    >
      {renderTree(data, { routePrefix, onNavigate })}
    </MaterialTreeView>
  );
};
