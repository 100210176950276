import * as React from 'react';

import { Chip, Styles } from '@cntxt/shared/ui';

export const ChipBlocked: React.FC<{ width?: string | number }> = ({
  width = '76px',
}) => {
  return (
    <Chip
      text={'Blocked'}
      sx={{
        width: { width },
        color: Styles.colors.text.status.critical,
        background: Styles.colors.surface.status.criticalMuted,
      }}
    />
  );
};
