import { useEffect, useState } from 'react';

import { Asset, CogniteEvent } from '@cognite/sdk/dist/src/types';

import { useAuthContext } from '@cntxt/shared/providers/web';
import {
  useFindAssetsByExternalIdQuery,
  useFindAssetsByNameQuery,
  useFindEventsByExternalIdQuery,
} from '@cntxt/steam/data-access';

import { environment } from '../../../environments/environment';

export type SearchData = {
  id: number;
  label?: string;
  parentId?: number;
  type?: string;
};

export const useData = (searchValue: string) => {
  const [searchData, setSearchData] = useState<SearchData[]>([]);
  const coreAuth = useAuthContext();

  const { data: eventsData, refetch: refetchEvents } =
    useFindEventsByExternalIdQuery({
      client: coreAuth.client,
      dataSetIds: [{ id: environment.datasetId }],
      externalId: searchValue,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    });

  const { data: assetsDataByExternalId, refetch: refetchAssetsByExternalId } =
    useFindAssetsByExternalIdQuery({
      client: coreAuth.client,
      dataSetIds: [{ id: environment.datasetId }],
      externalId: searchValue,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    });

  const { data: assetsDataByName, refetch: refetchAssetsByName } =
    useFindAssetsByNameQuery({
      client: coreAuth.client,
      dataSetIds: [{ id: environment.datasetId }],
      name: searchValue,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    });

  useEffect(() => {
    const uniqueSearchData = new Map<string, SearchData>();

    if (searchValue.trim()) {
      const combinedData = [
        ...processEventsData(eventsData || []),
        ...processAssetsData(assetsDataByExternalId || []),
        ...processAssetsByNameData(assetsDataByName || []),
      ];

      combinedData.forEach((data) => {
        if (!uniqueSearchData.has(data.label)) {
          uniqueSearchData.set(data.label, data);
        }
      });

      const uniqueDataArray = Array.from(uniqueSearchData.values());

      setSearchData(uniqueDataArray);
    } else {
      setSearchData([]);
    }
  }, [searchValue, eventsData, assetsDataByExternalId, assetsDataByName]);

  return {
    searchData,
    refetchEvents,
    refetchAssetsByExternalId,
    refetchAssetsByName,
  };
};

const processEventsData = (events: CogniteEvent[]) =>
  events?.map((event) => ({
    id: event.id,
    label: event.externalId || '',
    parentId: event.assetIds ? event.assetIds[0] : 0,
    type: 'event',
  })) || [];

const processAssetsData = (assets: Asset[]) =>
  assets?.map((asset) => ({
    id: asset.id,
    label: asset.externalId || '',
    parentId: asset.parentId,
    type: 'asset',
  })) || [];

const processAssetsByNameData = (assets: Asset[]) =>
  assets?.map((asset) => ({
    id: asset.id,
    label: asset.name || '',
    parentId: asset.parentId,
    type: 'asset',
  })) || [];
