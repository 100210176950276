import * as React from 'react';

import { Chip, Styles } from '@cntxt/shared/ui';

export const ChipHealthy: React.FC<{ width?: string | number }> = ({
  width = '76px',
}) => {
  return (
    <Chip
      text={'Healthy'}
      sx={{
        width: width,
        color: Styles.colors.text.status.success,
        background: Styles.colors.surface.status.successMuted,
      }}
    />
  );
};
