import { CogniteClient, EventSearchRequest } from '@cognite/sdk';

// use this if you need the total count of all events in a filter
export const aggregateEvents = async (
  client: CogniteClient,
  query: EventSearchRequest,
): Promise<{ count: number }[]> => {
  const events = await client.events.aggregate.count(query);

  return events;
};
