import { DateTime } from 'luxon';

import { DateFormat } from './getDisplayDate';

/**
 *
 * Display a relative date time string for the first x days
 *
 * Eg:
 *  - 2 hours ago
 *  - 1 day ago
 *  - yyyy-MM-dd HH:MM:ss
 *
 */
const getDisplayRelative = (
  dateTime: NonNullable<DateTime>,
  cutoffMS = 1000 * 60 * 60 * 24 * 1, // 1 day in milliseconds
  timeZone = 'local',
): string => {
  const now = DateTime.now().setZone(timeZone);
  const diff = Math.abs(now.diff(dateTime, 'milliseconds').milliseconds);

  if (diff <= cutoffMS) {
    return dateTime.setZone(timeZone).toRelative() || 'Unknown';
  } else {
    return dateTime.setZone(timeZone).toFormat(DateFormat.DAY_MONTH_YEAR_24HRs);
  }
};

export const getDisplayRelativeFromFormat = (timestamp: string): string => {
  const dateTime = DateTime.fromJSDate(new Date(timestamp));

  return getDisplayRelative(dateTime);
};

export const getDisplayRelativeFromUnix = (unixTime: number): string => {
  const dateTime = DateTime.fromMillis(unixTime);

  return getDisplayRelative(dateTime);
};
