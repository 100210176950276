import * as React from 'react';

import { CogniteClient, IdEither } from '@cognite/sdk/dist/src';

import { Asset } from '../../network/types';
import { useAssetTreeQuery } from '../queries/useAssetTreeQuery';

export const useSteamTraps = (client: CogniteClient, rootIds: IdEither[]) =>
  useAssetTreeQuery({
    client,
    rootIds,
    // ✅ memoizes with useCallback
    select: React.useCallback((data: Asset[]) => {
      const filtered = data.filter((asset) => {
        return asset.metadata?.['type'] === 'steam-trap';
      });
      return filtered;
    }, []),
    steamTrapsOnly: true,
  });
