import { Line } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js';
import type { PluginChartOptions } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

const options: Partial<PluginChartOptions<'line'>> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
    },
  },
  plugins: {
    // @ts-expect-error missing props
    legend: {
      display: false,
    },
  },
  elements: {
    point: {
      pointStyle: false,
    },
    // bar: {
    //   pointStyle: 'string',
    // },
  },
};

export const LineSimple: React.FC<{
  data: number[];
  tension: number;
  borderColor: string;
}> = (dataSet) => {
  const dataConfiguration = {
    labels: dataSet.data, // we hide them, so it just needs to be same length
    datasets: [dataSet],
  };

  return <Line width={300} options={options} data={dataConfiguration} />;
};
