'use client';

import { styled } from 'styled-components';

import { backgrounds } from '@cntxt/shared/ui-assets/images/index';

export const LoginForm = styled.div`
  width: 450px;
  max-width: 100%;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow:
    rgb(0 0 0 / 10%) 0px 16.8443px 50.5328px,
    rgb(0 0 0 / 7%) 0px 13.4754px 20.2131px;
  padding: 32px;
  padding-top: 12px;
  border-radius: 4px;
  header {
    position: relative;
    margin-bottom: 32px;
    h1 {
      color: var(--cogs-decorative--grayscale--1000);
      font-size: 24px;
      line-height: 32px;
      font-weight: bold;
      letter-spacing: -0.01em;
      padding: 4px 0px 12px;
    }
    h2 {
      font-weight: 700;
      line-height: 24px;
      font-size: 12px;
      text-transform: uppercase;
      color: var(--cogs-decorative--grayscale--900);
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 18px;
      height: 4px;
      background: black;
    }
  }
`;

export const Container = styled.div`
  .error {
    background: var(--cogs-bg-status-small--danger);
    border: 1px solid var(--cogs-bg-status-small--danger);
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 16px;
    border-left: 4px solid var(--cogs-danger);
  }
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${backgrounds.loginBackground2024 as unknown as string});
`;
