import {
  SelectedIdpDetails,
  storageProvider,
} from '@cntxt/shared/providers/shared';
import { MOST_RECENT_PROJECT } from '@cntxt/shared/util-auth';

export const getSelectedIdpDetails = (): SelectedIdpDetails | undefined => {
  try {
    const selectedIdpDetails = localStorage.getItem(MOST_RECENT_PROJECT) ?? '';
    const parsed = JSON.parse(selectedIdpDetails);
    if (typeof parsed?.internalId !== 'string') {
      throw new Error('IdP internalId is invalid');
    }
    return parsed as SelectedIdpDetails;
  } catch {
    return undefined;
  }
};

export const saveSelectedIdpDetails = (
  idpDetails: SelectedIdpDetails,
): boolean => {
  try {
    storageProvider.setItem(MOST_RECENT_PROJECT, idpDetails);
    return true;
  } catch {
    return false;
  }
};

export const removeSelectedIdpDetails = (): void => {
  storageProvider.removeItem(MOST_RECENT_PROJECT);
};
