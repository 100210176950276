import * as React from 'react';

import { Buttons } from '@cntxt/shared/ui';

type Props = {
  onSubmit: () => void;
};
export const SignInWithAAD: React.FC<Props> = ({ onSubmit }) => {
  const handleSubmit = () => {
    onSubmit();
  };

  return <Buttons.Button onClick={handleSubmit} text="Login"></Buttons.Button>;
};
