import jwtDecode from 'jwt-decode';
import isString from 'lodash/isString';

import { reportError } from '@cntxt/shared/util-errors';

import { StorageProvider } from '../../storageProvider';
import {
  ACCESS_TOKEN,
  ID_TOKEN,
  EXPIRES_IN,
  SCOPE,
  TOKEN_TYPE,
  LOGIN_IFRAME_NAME,
} from '../constants';
import {
  ADFSRequestParamsMapping,
  ADFSConfig,
  ADFSToken,
  ADFSRequestParams,
  ADFSQueryParams,
  ADFSRequestParamsWithDefaults,
  ADFSIdToken,
} from '../types';

import { extractToken } from './extractToken';

export function removeQueryParameterFromUrl(
  url: string,
  parameter: string,
): string {
  return url
    .replace(new RegExp('[?#&]' + parameter + '=[^&#]*(#.*)?$'), '$1')
    .replace(new RegExp('([?#&])' + parameter + '=[^&]*&'), '$1');
}

export function clearParametersFromUrl(...params: string[]): void {
  if (!window || !window.location || !window.history) {
    return;
  }

  let url = window.location.href;
  params.forEach((param) => {
    url = removeQueryParameterFromUrl(url, param);
  });
  window.history.replaceState(null, '', url);
}

const adfsRequestParamsMapping: ADFSRequestParamsMapping = {
  clientId: 'client_id',
  scope: 'scope',
  redirectUri: 'redirect_uri',
  responseMode: 'response_mode',
  responseType: 'response_type',
  resource: 'resource',
};

const sessionStorageProvider: StorageProvider = {
  clear: () => sessionStorage.clear(),
  removeItem: (key) => sessionStorage.removeItem(key),
  setItem: (key, value) => {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    sessionStorage.setItem(key, value);
  },
  getItem: (key) => Promise.resolve(sessionStorage.getItem(key)),
};

export abstract class ADFSBase {
  protected readonly authority: string;
  protected readonly requestParams: ADFSConfig['requestParams'];
  protected readonly storageProvider: StorageProvider;
  protected readonly sessionKey: string;
  protected token: ADFSToken | null = null;

  constructor({ authority, requestParams, storageProvider }: ADFSConfig) {
    this.authority = authority;
    this.requestParams = requestParams;
    this.sessionKey = `${authority}_${requestParams.clientId}_${requestParams.resource}`;
    this.storageProvider = storageProvider || sessionStorageProvider;
  }

  public parseHashes = (url = ''): ADFSToken | null => {
    const positionOfHash = url.indexOf('#');
    const hashes = url.substring(positionOfHash + 1);
    return extractADFSToken(hashes);
  };

  public abstract login(): Promise<string | void | null>;

  public handleLoginRedirect(hash?: string): ADFSToken | null {
    try {
      const queryParams =
        typeof hash === 'string' ? hash : window.location.hash;

      if (!queryParams) {
        return null;
      }

      const token = extractADFSToken(queryParams);

      clearParametersFromUrl(
        ACCESS_TOKEN,
        ID_TOKEN,
        EXPIRES_IN,
        SCOPE,
        TOKEN_TYPE,
      );
      this.setToken(token);

      return token;
    } catch (error) {
      reportError(error, 'Failed to extract ADFS token');
    }

    return null;
  }

  public async getCDFToken(): Promise<string | null> {
    const token = await this.acquireTokenSilently();

    return token
      ? token.accessToken
      : this.token
        ? this.token.accessToken
        : null;
  }

  public async getIdToken(): Promise<ADFSIdToken | null> {
    const token = await this.acquireTokenSilently();

    const idTokenStr = token
      ? token.idToken
      : this.token
        ? this.token.idToken
        : null;

    if (idTokenStr) {
      const idToken = jwtDecode(idTokenStr) as ADFSIdToken;
      idToken.token_string = idTokenStr;
      idToken.accessToken = token?.accessToken ?? '';
      return idToken;
    }
    return null;
  }

  protected abstract acquireTokenSilently(): Promise<ADFSToken | null>;

  protected getADFSQueryParams({
    resource,
    clientId,
    redirectUri,
  }: ADFSRequestParams & { redirectUri: string }): ADFSQueryParams {
    const responseMode = 'fragment';
    const responseType = 'id_token token';
    const scope = `user_impersonation IDENTITY`;

    const params = {
      clientId,
      scope,
      responseMode,
      responseType,
      resource,
      redirectUri,
    };
    return Object.entries(params).reduce<ADFSQueryParams>(
      (result, [key, value]) => {
        const param =
          adfsRequestParamsMapping[key as keyof ADFSRequestParamsWithDefaults];

        result[param] = value;

        return result;
      },
      {} as ADFSQueryParams,
    );
  }

  protected getADFSQueryParamString(params: ADFSQueryParams): string {
    return Object.entries(params).reduce((result, [key, value]) => {
      return `${result}${result.length > 1 ? '&' : ''}${key}=${value}`;
    }, '');
  }

  protected setToken(token: ADFSToken | null) {
    this.token = token;
    if (token) {
      this.storageProvider.setItem(this.sessionKey, JSON.stringify(token));
    } else {
      this.storageProvider.removeItem(this.sessionKey);
    }
  }

  protected async getToken(): Promise<ADFSToken | null> {
    const value = await this.storageProvider.getItem(this.sessionKey);

    if (!value) {
      return null;
    }
    try {
      const token = JSON.parse(value) as ADFSToken;
      if (token.expiresIn <= Date.now()) {
        throw new Error(`Token expired ${token.expiresIn}`);
      }
      return token;
    } catch (error) {
      reportError(error, 'Failed to parse token');
      this.storageProvider.removeItem(this.sessionKey);
      return null;
    }
  }
}

export function extractADFSToken(query: string): ADFSToken | null {
  const { accessToken, idToken, expiresIn } = extractToken(query);
  if (isString(accessToken) && isString(idToken)) {
    return {
      accessToken,
      idToken,
      expiresIn: Date.now() + Number(expiresIn) * 1000,
    };
  }

  return null;
}

export async function webSilentLoginViaIframe<TokenType>(
  url: string,
  extractor: (query: string) => TokenType,
  iframeName: string = LOGIN_IFRAME_NAME,
  locationPart: 'hash' | 'search' = 'hash',
): Promise<TokenType> {
  return new Promise<TokenType>((resolve, reject) => {
    const iframe = createInvisibleIframe(url, iframeName);

    iframe.onload = () => {
      try {
        const authTokens = extractor(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          iframe.contentWindow!.location[locationPart],
        );
        if (authTokens === null) {
          throw Error('Failed to login silently');
        }
        resolve(authTokens);
      } catch (error) {
        reportError(error, 'Failed to extract token');
        reject(error);
      } finally {
        document.body.removeChild(iframe);
      }
    };
    document.body.appendChild(iframe);
  });
}

export function createInvisibleIframe(
  url: string,
  name: string,
): HTMLIFrameElement {
  const iframe = document.createElement('iframe');
  iframe.name = name;
  iframe.style.width = '0';
  iframe.style.height = '0';
  iframe.style.border = '0';
  iframe.style.border = 'none';
  iframe.style.visibility = 'hidden';

  iframe.setAttribute('id', name);
  iframe.setAttribute('aria-hidden', 'true');

  iframe.src = url;
  return iframe;
}
