import * as React from 'react';
import { useParams } from 'react-router-dom';

import { CogniteEvent } from '@cognite/sdk/dist/src';
import { get, capitalize } from 'lodash';

import { useAuthContext } from '@cntxt/shared/providers/web';
import { DisabledTextField, Layout, Typography } from '@cntxt/shared/ui';
import { DateFormat, getDateWithFormat } from '@cntxt/shared/util-date';
import { getEventById, useEventsQuery } from '@cntxt/steam/data-access';
import { HistoryChangeTable } from '@cntxt/steam/feat-dashboard';
import {
  ChipBlocked,
  ChipHealthy,
  ChipPassing,
  ChipPending,
} from '@cntxt/steam/ui-chips';

const getChip = (subtype: string | undefined): React.ReactElement => {
  switch (subtype) {
    case 'passing':
      return <ChipPassing width={`100%`} />;
    case 'healthy':
      return <ChipHealthy width={`100%`} />;
    case 'blocked':
      return <ChipBlocked width={`100%`} />;
    case 'pending':
      return <ChipPending width={`100%`} />;
    default:
      return <ChipPending width={`100%`} />;
  }
};

const getTableData = (eventData: CogniteEvent[]) => {
  const tableHeaders = ['Captured Date', 'status'];
  const rows = eventData.map((event: CogniteEvent) => {
    const startTime = Number(event.startTime);
    return {
      capturedDate: getDateWithFormat(
        new Date(startTime).toDateString(),
        DateFormat.DAY_MONTH_YEAR,
      ),
      status: capitalize(event.subtype) || '',
      eventId: event.id,
    };
  });
  return {
    tableHeaders,
    rows,
  };
};

const useLeafItem = (): {
  datasetId: number;
  parentId: number;
  eventId: number;
} => {
  const { id, ...rest } = useParams<'id'>();

  const extras = get(rest, '*') || '';
  const splits = extras.split('/');
  const splitIds = splits.map(Number).filter((value) => !Number.isNaN(value));
  return {
    datasetId: Number(id),
    parentId: Number(splitIds[0] || 0),
    eventId: Number(splitIds[1] || 0),
  };
};

const useData = (eventIDFromQueryParam: string | null) => {
  const { client } = useAuthContext();
  const { datasetId, parentId, eventId } = useLeafItem();
  const { data: eventsData } = useEventsQuery({
    client,
    id: parentId,
    dataSetIds: [{ id: datasetId }],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const selectedEventId = eventIDFromQueryParam
    ? +eventIDFromQueryParam
    : eventId;
  const selectedEvent = getEventById(eventsData || [], selectedEventId);
  const tableData = getTableData(eventsData || []);

  return { selectedEvent, ...tableData, datasetId, parentId, eventId };
};
export const InspectionPanel: React.FC = () => {
  const query = new URLSearchParams(window.location.search);
  const eventId = query.get('eventId');
  const { selectedEvent, tableHeaders, rows } = useData(eventId);
  const startTime = Number(selectedEvent?.startTime);
  const formattedStartDate = getDateWithFormat(
    new Date(startTime).toDateString(),
    DateFormat.DAY_MONTH_YEAR_24HRs,
  );
  const Chip = getChip(selectedEvent?.subtype);
  return (
    <div>
      <Layout.BoxSpaced>
        <Typography.General.SubtitleBold text="Date Captured" caps />
        <DisabledTextField text={formattedStartDate} sx={styles.testFields} />
      </Layout.BoxSpaced>
      <Layout.BoxSpaced>
        <div style={styles.chipContainer}>
          <Typography.General.SubtitleBold text="Health Index" caps />
          {Chip}
        </div>
      </Layout.BoxSpaced>
      <Layout.BoxSpaced>
        <Typography.General.SubtitleBold text="Historical Changes" caps />
        <HistoryChangeTable
          data={{
            tableHeaders,
            rows,
          }}
        />
      </Layout.BoxSpaced>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  chipContainer: {
    width: '100%',
  },
  testFields: {
    width: '100%',
  },
  testFieldValid: {
    width: '100%',
  },
  testFieldInvalid: {
    width: '100%',
  },
};
