import { useCallback, useEffect, useRef, useState } from 'react';

import { CogniteClient } from '@cognite/sdk';

import {
  ADFSProject,
  AUTH_STATE_KEY,
  AuthState,
  AuthStateUser,
  CustomerProject,
  IDP_TYPE,
} from '@cntxt/shared/util-auth';
import { reportError } from '@cntxt/shared/util-errors';

import { StorageProvider } from '../storageProvider';

import AzureADFlow from './flows/AzureADFlow';
import { getInitialAuthState } from './getInitialAuthState';

/**
 * Note: Do not rely on hot reloading when editing this component
 */

type Props = {
  appId: string;
  clientId: string;
  projectInfo: CustomerProject;
  storageProvider: StorageProvider;
  adfsFlowRef: (
    customerProject: CustomerProject & ADFSProject,
    storageProvider: StorageProvider,
  ) => {
    getTokenFactory: () => Promise<() => Promise<string>>;
    getUserState: () => Promise<AuthStateUser>;
  };
  isOnline?: boolean;
};

export const useCoreAuth = ({
  clientId,
  appId,
  storageProvider,
  projectInfo,
  adfsFlowRef,
  isOnline,
}: Props) => {
  const [client, setClient] = useState<CogniteClient>(
    new CogniteClient({
      appId,
      project: '',
      getToken: async () => '',
    }),
  );
  const [authState, setAuthState] = useState<AuthState>({
    status: 'UNAUTHENTICATED',
  });

  const hasStorageProvider = !!storageProvider;

  useEffect(() => {
    if (!isOnline) {
      return;
    }
    // console.log('[useCoreAuth] storageProvider load initial');
    getInitialAuthState({ storageProvider }).then((state) => {
      console.log('[useCoreAuth] storageProvider initial state', state);
      // if (!isOnline) {
      //   setAuthState({
      //     ...state,
      //     status: 'AUTHENTICATED',
      //   });
      //   return;
      // }

      // reset auth state on each clean load
      // don't load in an initial error state
      if (state.status !== 'ERROR') {
        setAuthState(state);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasStorageProvider, isOnline]);

  const storeAuthState = useCallback(
    (state: AuthState) => {
      if (state.status === 'UNAUTHENTICATED') {
        storageProvider.removeItem(AUTH_STATE_KEY);
      }
      storageProvider.setItem(AUTH_STATE_KEY, JSON.stringify(state));

      setAuthState(state);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasStorageProvider],
  );

  const login = async () => {
    const nextAuthState: AuthState = {
      status: 'AUTHENTICATING',
      projectInfo,
    };
    storeAuthState(nextAuthState);
  };

  // Running onAttemptLogin will break legacy auth
  // Ensure it can never happen with a simple toggle
  // const loginAttempted = useRef(false);
  const loginCount = useRef(0);
  const onAttemptLogin = useCallback(
    async (customerProject: CustomerProject) => {
      // don't even bother trying to start auth if we're offline
      if (!isOnline) {
        console.log('Offline mode found, skipping authenticating.');
        // perhaps we also want to reset the auth status here?
        // storeAuthState({ status: 'UNAUTHENTICATED' });
        return;
      }

      const handleLoginError = (error: unknown) => {
        const nextAuthState: AuthState = {
          status: 'ERROR',
          message: (error as Error).message,
        };
        storeAuthState(nextAuthState);
        // since there was an error, let's clear everything so that
        // the user can start again fresh
        storageProvider.clear();
        throw error;
      };

      loginCount.current = loginCount.current += 1;
      // if (loginAttempted.current) {
      //   console.log('Attempt already done, exiting');
      //   return;
      // }
      // loginAttempted.current = true;
      let getToken: (() => Promise<string>) | null = null;
      let user: AuthStateUser = {
        id: '',
        name: '',
        email: '',
        idToken: '',
        accessToken: '',
      };
      if (customerProject.idpType === IDP_TYPE.AZURE_AD) {
        const azureADFlow = new AzureADFlow(customerProject, clientId);
        try {
          getToken = await azureADFlow.getTokenFactory();
          user = await azureADFlow.getUserState();
        } catch (error) {
          handleLoginError(error);
          reportError(error, 'Failed to get token');
          throw error;
        }
      }
      if (customerProject.idpType === IDP_TYPE.ADFS2016) {
        console.log('[useCoreAuth] 🔒 Starting ADFS flow', {
          loginCount: loginCount.current,
          // loginAttempted: loginAttempted.current,
        });
        if (loginCount.current > 5) {
          console.log('Attempt count exceeded');
          return;
        }
        const adfsFlow = adfsFlowRef(customerProject, storageProvider);

        try {
          getToken = await adfsFlow.getTokenFactory();
          user = await adfsFlow.getUserState();
        } catch (error) {
          storeAuthState({ status: 'UNAUTHENTICATED' });
          handleLoginError(error);
          reportError(error, 'Failed to Attempt Login');
          throw error;
        }
      }

      if (!getToken) {
        throw new Error(
          'Unable to determine method to get token for user. Please check your env variables.',
        );
      }

      const nextClient = new CogniteClient({
        appId,
        project: customerProject.id,
        getToken,
        baseUrl: customerProject.cluster,
      });

      setClient(nextClient);

      // If we have no access token after authenticating, set back to idle
      const result = await nextClient.authenticate();
      if (!result) {
        storeAuthState({ status: 'UNAUTHENTICATED' });
      } else {
        // reset count if everything went ok
        loginCount.current = 1;
        storeAuthState({
          status: 'AUTHENTICATED',
          projectInfo: customerProject,
          user,
        });
      }
    },
    [appId, !!storeAuthState, isOnline, clientId],
  );

  useEffect(() => {
    // If localStorage says we're authenticated, autorun login once
    if (authState.status === 'AUTHENTICATED') {
      onAttemptLogin(authState.projectInfo);
    }
    if (authState.status === 'ERROR') {
      storeAuthState({ status: 'UNAUTHENTICATED' });
    }
    // run login only once! eg: don't add authState.status here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  useEffect(() => {
    if (authState.status !== 'AUTHENTICATING') return;
    try {
      onAttemptLogin(authState.projectInfo);
    } catch (error) {
      reportError(error, 'Failed to Attempt Login');
      throw error;
    }
  }, [authState.status, !!onAttemptLogin, isOnline]);

  const logout = async () => {
    storageProvider.clear();

    if (authState.status === 'AUTHENTICATED') {
      storeAuthState({ status: 'UNAUTHENTICATED' });

      if (authState.projectInfo.idpType === IDP_TYPE.ADFS2016) {
        // don't run this on mobile
        if (window && window.location) {
          window.location.replace(authState.projectInfo.logout);
          // cors issues from localhost:
          // await fetch(authState.projectInfo.logout, { method: 'GET' });
        }
      } else {
        window.location.reload();
      }
    }
  };

  return {
    authState,
    client,
    login,
    logout,
  };
};
