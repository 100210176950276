import { CSSProperties } from 'react';

import { Box } from '@mui/material';

import { Cards, Layout, Styles, Typography } from '@cntxt/shared/ui';
import { Icons } from '@cntxt/shared/ui-icons';

export type Props = {
  title: string;
  imageSrc: string;
  iconCallback: () => void;
  imageCallback: () => void;
  sx?: CSSProperties;
  isIconVisible?: boolean;
};
export const ImageCard: React.FC<Props> = ({
  title = '',
  imageSrc = '',
  iconCallback,
  imageCallback,
  sx = styles.defaultContainer,
  isIconVisible = true,
}) => {
  return (
    <Cards.Clear sx={sx}>
      <Layout.BoxCenter>
        <Layout.BoxVertical>
          <Box onClick={imageCallback}>
            <img
              src={imageSrc}
              style={styles.clickableImage}
              alt={'frame from the steam'}
            />
          </Box>

          <Layout.BoxHorizontal sx={styles.textWithIconContainer}>
            <Typography.General.Text text={title} sx={styles.cardTitle} />
            {isIconVisible ? (
              <Icons.Delete
                onClick={iconCallback}
                color={Styles.colors.text.status.critical}
              />
            ) : null}
          </Layout.BoxHorizontal>
        </Layout.BoxVertical>
      </Layout.BoxCenter>
    </Cards.Clear>
  );
};

const styles: Record<string, CSSProperties> = {
  defaultContainer: {
    background: Styles.colors.surface.default,
    borderColor: Styles.colors.surface.default,
    flex: '0 0 200px',
    height: '',
    padding: '8px',
  },
  clickableImage: {
    width: '175px',
    height: '130px',
    cursor: 'pointer',
    borderRadius: '4px',
  },
  textWithIconContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  cardTitle: {
    justifyContent: 'flex-end',
    overflow: 'hidden',
    flex: '0 0 auto;',
    // border: 'red 1px solid',
    textOverflow: 'ellipsis',
  },
};
