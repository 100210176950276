import { CogniteClient, EventChange } from '@cognite/sdk';

export const updateEvent = async ({
  client,
  changeEvent,
}: {
  client: CogniteClient;
  changeEvent: EventChange;
}) => {
  // note: this function is a helper to update just one event at a time
  return client.events.update([changeEvent]);
};
