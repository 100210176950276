import { CSSProperties, useEffect } from 'react';

import { InputLabel } from '@mui/material';

import { StylesUtil, getTimeFromDuration } from '@cntxt/shared/util-ui';

import { ButtonsGrouped, Layout } from '../material';
import { Styles } from '../styles';
import { colors } from '../styles/colors4IR';

import { BoldCornersSquare } from './BoldCornersSquare';

const buttonWidth = 36;
const buttonHeight = 34;
const defaultBorderColor = StylesUtil.hexToRgbA(
  Styles.colors.surface.muted,
  0.5,
);
const fontSize = Styles.sizes.raw._25;
const buttonComponent = () => {
  return (
    <BoldCornersSquare
      width={buttonWidth}
      height={buttonHeight}
      borderColor={defaultBorderColor}
    />
  );
};

const data: React.ComponentProps<typeof ButtonsGrouped>['data'] = [
  {
    buttonComponent,
    label: '7D',
    value: '7D',
  },
  {
    buttonComponent,
    label: '1M',
    value: '1M',
  },
  {
    buttonComponent,
    label: '3M',
    value: '3M',
  },
  {
    buttonComponent,
    label: '6M',
    value: '6M',
  },
  {
    buttonComponent,
    label: '1Y',
    value: '1Y',
  },
];
const applyStylesFn = (isActive: boolean, activeButtonColor: string) => {
  return {
    color: isActive ? 'transparent' : Styles.colors.text.interactive.default,
    fontWeight: 'bold',
    backgroundColor: isActive
      ? `${activeButtonColor} !important`
      : 'transparent',
    width: buttonWidth,
    height: buttonHeight,
    margin: 0,
    padding: 0,
    fontFamily: Styles.fonts.semiBold.manifaTashkeel,
  } as CSSProperties;
};

const defaultGroupButtonColor = {
  active: colors.surface.backdrop,
  inactive: Styles.colors.text.interactive.default,
};

type Props = {
  onClick: (value: number) => void;
  labelColor?: string;
  groupButtonsColor?: {
    active: string;
    inactive: string;
  };
  activeButtonColor?: string;
};
export const DurationSelector: React.FC<Props> = ({
  onClick,
  labelColor = Styles.colors.text.interactive.default,
  groupButtonsColor = defaultGroupButtonColor,
  activeButtonColor = Styles.colors.text.interactive.default,
}) => {
  useEffect(() => {
    handleDurationChange('7D');
  }, []);

  const handleDurationChange = (value: string) => {
    onClick(getTimeFromDuration(value));
  };

  return (
    <Layout.BoxVertical sx={{ gap: 0 }}>
      <InputLabel
        sx={{
          ...styles.text,
          ...Styles.fonts.typography.sectionHeaderSmall,
          color: labelColor,
        }}
      >
        Duration
      </InputLabel>
      <ButtonsGrouped
        onClick={handleDurationChange}
        label="Duration"
        data={data}
        isVertical={true}
        applyStylesFn={(isActive: boolean) => {
          return applyStylesFn(isActive, activeButtonColor);
        }}
        buttonsLabelStyle={{
          activeButtonTextColor: groupButtonsColor.active,
          inactiveButtonTextColor: groupButtonsColor.inactive,
        }}
      />
    </Layout.BoxVertical>
  );
};

const styles = {
  text: {
    fontSize,
    transform: 'translate(0, -1.5px) scale(0.75)',
  },
};
