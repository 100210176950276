import Box from '@mui/material/Box';

import { Chip, Styles, Typography } from '@cntxt/shared/ui';

export const HistorySidebar = () => {
  return (
    <Box
      sx={{
        paddingTop: Styles.sizes.pixels._2,
        paddingLeft: Styles.sizes.pixels._2,
      }}
    >
      <Box
        sx={{
          padding: Styles.sizes.pixels._2,
        }}
      >
        <Typography.General.SubtitleBold text="Recently Uploaded" />
      </Box>
      <Box
        sx={{
          gap: '10px',
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        <Chip
          text={'22-01-2021, 22:00'}
          sx={{
            color: Styles.colors.text.default,
            background: Styles.colors.surface.default,
          }}
        />
        <Chip
          text={'22-01-2021, 22:00'}
          sx={{
            color: Styles.colors.text.default,
            background: Styles.colors.surface.default,
          }}
        />
      </Box>
    </Box>
  );
};
