import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';

import { Styles, TreeView, useTreeDefaults } from '@cntxt/shared/ui';

export type TreeProps = React.ComponentProps<typeof TreeView>;

export const SidebarTreeView: React.FC<{
  data: TreeProps['data'];
  routePrefix: string;
}> = ({ data, routePrefix }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // this will configure the tree to show the selected items from the url on initial load
  const { defaultSelected, defaultExpanded } = useTreeDefaults({
    pathname,
    routePrefix,
  });

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  if (defaultSelected.length === 0) {
    return null;
  }

  if (defaultExpanded.length === 0) {
    return null;
  }

  if (!data) {
    return null;
  }

  return (
    <Box
      sx={{
        paddingLeft: Styles.sizes.pixels._2,
        minWidth: '200px',
      }}
    >
      <TreeView
        data={data}
        routePrefix={routePrefix}
        onNavigate={handleNavigate}
        defaultSelected={defaultSelected}
        defaultExpanded={defaultExpanded}
      />
    </Box>
  );
};
