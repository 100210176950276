export const hexToRgbA = (hex: string, alpha = 1) => {
  let chars: string[];
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    chars = hex.substring(1).split('');
    if (chars.length === 3) {
      chars = [chars[0], chars[0], chars[1], chars[1], chars[2], chars[2]];
    }

    const hexToRgbA = '0x' + chars.join('');

    const red = shiftHexString(hexToRgbA, 16);
    const green = shiftHexString(hexToRgbA, 8);
    const blue = shiftHexString(hexToRgbA, 0);
    const rgba = `rgba(${red}, ${green}, ${blue}, ${alpha})`;

    return rgba;
  }
  throw new Error('bad hex: not right length or not a hex');
};

function shiftHexString(hexString: string, shiftAmount: number): number {
  const num = parseInt(hexString, 16);

  const shiftedNum = (num >> shiftAmount) & 255;

  return shiftedNum;
}
