'use client';

import { colors } from './colors';
import { fonts } from './fonts';
import { generic } from './generic';
import { Global } from './Global';
import { layouts } from './layouts';
import { sizes } from './sizes';

export type { Theme } from './theme';

export const Styles = {
  colors,
  fonts,
  Global,
  layouts,
  sizes,
  generic,
};
