import { CogniteClient, IdEither } from '@cognite/sdk';

export const fetchFiles = async ({
  client,
  ids,
}: {
  client: CogniteClient;
  ids: IdEither[];
}) => {
  const result = await client.files.retrieve(ids);

  return result;
};
