import last from 'lodash/last';
import takeRight from 'lodash/takeRight';

export const getBreadcrumbSegments = ({
  path,
  amountToShow,
}: {
  path: string;
  amountToShow: number;
}) => {
  const segments = path ? path.split('/') : [];
  const segmentsToShow =
    segments.length >= amountToShow
      ? takeRight(segments, amountToShow)
      : segments;

  return segmentsToShow;
};

export const getBreadcrumbs = ({
  path,
  amountToShow,
}: {
  path: string;
  amountToShow: number;
}): string[] => {
  const segments = getBreadcrumbSegments({ path, amountToShow });

  if (last(segments) === '') {
    segments.pop();
  }

  return segments;
};
