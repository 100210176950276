import { Line } from 'react-chartjs-2';

import type { ChartData, DefaultDataPoint, PluginChartOptions } from 'chart.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { useTheme } from '../../providers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

type CustomData = ChartData<'line', DefaultDataPoint<'line'>, unknown>;

export const LineMulti: React.FC<{
  data: CustomData;
  displayLegend?: boolean;
}> = ({ data, displayLegend }) => {
  const theme = useTheme();
  const options: Partial<PluginChartOptions<'line'>> = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    scales: {
      x: {
        ticks: {
          callback: function (value: number, index: number): string {
            // Remove every other x-axis label
            // @ts-expect-error don't have type for this class
            return index % 2 === 0 ? this.getLabelForValue(value) : '';
          },
          color: theme.text.default,
        },
      },
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        title: {
          display: true,
          text: 'Production (tonnes)',
          color: theme.text.default,
        },
        ticks: {
          color: theme.text.default,
        },
      },
      y1: {
        spanGaps: true,
        type: 'linear' as const,
        display: true,
        position: 'right' as const,
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: 'Sales (USD)',
          color: theme.text.default,
        },
        ticks: {
          color: theme.text.default,
        },
      },
    },
    plugins: {
      legend: {
        display: displayLegend === undefined ? true : displayLegend,
        position: 'top' as const,
        // @ts-expect-error missing props
        labels: {
          color: theme.text.default,
        },
      },

      // @ts-expect-error missing props
      title: {
        display: true,
        text: 'MAC Production and Sales Forecast for 2023',
        color: theme.text.default,
      },
    },
  };
  return <Line options={options} data={data} />;
};
