import * as React from 'react';

import { ADFS } from '@cognite/sdk-core';
import { useQuery } from '@tanstack/react-query';

import { Loading, Buttons } from '@cntxt/shared/ui';
import { IDP_TYPE } from '@cntxt/shared/util-auth';

import { getSelectedIdpDetails, saveSelectedIdpDetails } from './localstorage';

type SignInWithADFS2016Props = {
  authority: string;
  clientId: string;
  label?: string;
  cluster: string;
  id: string;
  handleSubmit: (id: string) => void;
};

const SignInButton = Buttons.Button;

export const SignInWithADFS2016 = ({
  authority,
  clientId,
  id,
  cluster,
  label,
  handleSubmit,
}: SignInWithADFS2016Props): JSX.Element => {
  const { id: activeInternalId } = getSelectedIdpDetails() ?? {};
  const active = activeInternalId === id;
  const adfs = React.useMemo(
    () =>
      new ADFS({
        authority: `${authority}/authorize`,
        requestParams: {
          clientId,
          resource: cluster,
        },
      }),
    [authority, clientId, cluster],
  );

  const {
    data: token,
    refetch,
    isFetched,
  } = useQuery({
    queryKey: [IDP_TYPE.ADFS2016, authority, cluster],
    queryFn: () => {
      return adfs.getCDFToken();
    },
  });

  React.useEffect(() => {
    if (active) {
      const tokens = adfs.handleLoginRedirect();
      if (tokens) {
        refetch();
      }
    }
  }, [active, adfs, refetch]);

  React.useEffect(() => {
    if (token) {
      handleSubmit('steam');
    }
  }, [token, handleSubmit]);

  const beginLogin = () => {
    saveSelectedIdpDetails({
      id,
      type: IDP_TYPE.ADFS2016,
    });
    adfs.login();
  };

  if (!isFetched) {
    return <Loading />;
  }

  return (
    <SignInButton disabled={!isFetched} onClick={beginLogin} text={label} />
  );
};
