import { Config } from './baseConfig';
import { updateConfigRecursively } from './updateConfigRecursively';

export const environmentLoader = (environment: Config) => {
  return new Promise((resolve) => {
    if (environment.production) {
      const xmlhttp = new XMLHttpRequest();
      const method = 'GET';
      const url = './assets/environments/environment.json';
      xmlhttp.open(method, url, true);
      xmlhttp.onload = function () {
        if (xmlhttp.status === 200) {
          try {
            const env = JSON.parse(xmlhttp.responseText);
            if (env) {
              updateConfigRecursively(environment, env);
              resolve(env);
            }
          } catch (error) {
            // Handle JSON parse error
            console.error('Error parsing JSON:', error);
            resolve(null);
          }
        } else {
          resolve(null);
        }
      };
      xmlhttp.send();
    } else {
      resolve(null);
    }
  });
};
