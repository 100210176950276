import { useAuthContext } from '@cntxt/shared/providers/web';
import { Layout, Loading, Typography } from '@cntxt/shared/ui';
import { useSteamTrapsData } from '@cntxt/steam/util-ui';

import { TableView } from './TableView';

type Props = {
  dataSetIds: { id: number }[];
  leafItem: string[];
  fromUnixTime?: number;
  toUnixTime: number;
  status?: string;
};

export const SteamTrapInspection: React.FC<Props> = ({
  dataSetIds,
  leafItem,
  fromUnixTime,
  toUnixTime,
  status = 'All',
}) => {
  const { client } = useAuthContext();
  const { id, data, isLoading } = useSteamTrapsData(
    client,
    leafItem,
    dataSetIds,
    fromUnixTime,
    toUnixTime,
    status,
  );
  const withoutAnyFilter = !id && isLoading;
  if (withoutAnyFilter) {
    return (
      <Layout.PageContentBox sx={styles.container}>
        <Typography.Title.ExtraSmall
          text={'Please choose a plant to view steam trap details'}
        />
      </Layout.PageContentBox>
    );
  }
  if (isLoading) {
    return (
      <Layout.PageContentBox sx={styles.container}>
        <Loading />
      </Layout.PageContentBox>
    );
  }
  return (
    <Layout.PageContentBox>
      <Typography.Title.Small text={'Steam Traps Inspection'} />
      <TableView data={data} />
    </Layout.PageContentBox>
  );
};
const styles: Record<string, React.CSSProperties> = {
  container: {
    display: 'flex',
    width: '100%',
  },
};
