import { Asset, CogniteClient } from '@cognite/sdk';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';

import { useAssetRootQuery } from '../queries/useAssetRootQuery';
import { useAssetTreeQuery } from '../queries/useAssetTreeQuery';

export type RenderTree = {
  id: string;
  externalId: string | undefined;
  name: string;
  children?: RenderTree[];
  type?: string;
};

const getAsset = (assets: AssetWithChildren[], id: number) =>
  assets.find((asset) => asset.id === id);

type AssetWithChildren = Asset & { children?: AssetWithChildren[] };

const moveToParent = (
  asset: AssetWithChildren,
  assets: AssetWithChildren[],
) => {
  if (asset.parentId) {
    const parent = getAsset(assets, asset.parentId);

    if (parent) {
      if (!parent.children) {
        parent.children = [];
      }
      // console.log(`Adding ${asset.name} into ${parent.name}`);
      const childExists = getAsset(parent.children, asset.id);
      if (!childExists) {
        parent.children.push(asset);
        parent.children = sortBy(parent.children, 'name');
      }
    }
  }

  return assets;
};

const convertAssetsIntoTree = (assets?: AssetWithChildren[]) => {
  let workingAssets = [...(assets || [])];

  workingAssets?.forEach((asset) => {
    workingAssets = moveToParent(asset, workingAssets);
  });

  return workingAssets;
};

const mapToChildren = (asset: AssetWithChildren): RenderTree => {
  return {
    id: `${asset.id}`,
    externalId: asset.externalId,
    name: asset.name,
    children: asset.children ? asset.children.map(mapToChildren) : [],
    type:
      asset.metadata && asset.metadata['type']
        ? asset.metadata['type']
        : undefined,
  };
};

const mapToRenderTree = (assets: AssetWithChildren[]): RenderTree => {
  return {
    id: 'root',
    externalId: 'root',
    name: 'Root',
    children: assets.map(mapToChildren),
  };
};

/**
 * useAssetTree
 *
 * This converts assets into an asset hierarchy
 */
type AssetTreeProps = {
  client: CogniteClient;
  dataSetIds: { id: number }[];
  filterWithoutSteamTrap?: boolean;
};
export const useAssetTree = ({
  client,
  dataSetIds,
  filterWithoutSteamTrap,
}: AssetTreeProps) => {
  const { data: roots } = useAssetRootQuery({ client, dataSetIds });
  const rootIds = (roots || []).map((asset) => ({ id: asset.id }));

  const { data: results } = useAssetTreeQuery({ client, rootIds });
  let filterAssetData = results;
  if (filterWithoutSteamTrap) {
    filterAssetData = results?.filter(
      // metadata should not be empty for Steam Traps
      (item) => item.metadata && Object.keys(item.metadata).length === 0,
    );
  }
  const assetTree = convertAssetsIntoTree(filterAssetData);

  const finalTree = roots?.map((root) => getAsset(assetTree, root.id));

  return { data: mapToRenderTree(compact(finalTree)) };
};
