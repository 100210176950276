import * as React from 'react';

import { type SxProps, type Theme, useTheme } from '@mui/material';

export const withSx = <T,>(
  Component: React.FC<React.PropsWithChildren<T & { sx?: SxProps }>>,
) => {
  return (styleCallback: ({ theme }: { theme: Theme }) => SxProps) => {
    const ResultComponent = (props: React.ComponentProps<typeof Component>) => {
      const theme = useTheme();
      const sx = styleCallback({ theme, ...props });
      return <Component {...props} sx={sx} />;
    };

    ResultComponent.displayName = `WithSx(${
      Component.displayName || Component.name || 'Component'
    })`;

    return ResultComponent;
  };
};
