import { CogniteClient, ExternalId } from '@cognite/sdk/dist/src';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AssetInternal } from '@cntxt/shared/data-access-cdf';
import { reportError } from '@cntxt/shared/util-errors';

import { updateSteamTrapAsset } from '../../network/updateSteamTrapAsset';

type SteamTrapAsset = AssetInternal & {
  metadata: {
    lastInspectionDate: string;
    lastInspectionStatus: string;
  };
};

export type UpdatableSteamTrapAsset = Partial<SteamTrapAsset> & ExternalId;

export const steamTrapKeys = {
  all: () => ['steamTraps'],
};

/**
 * This hook updates the cache for steam trap asset. The actual mutation is handled by the 'network assets'.
 *
 * @returns {MutationObserver} A mutation observer that can be used to mutate the steam trap asset data and handle the mutation lifecycle.
 */
export const useUpdateSteamTrapAssetMutation = (client: CogniteClient) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: steamTrapKeys.all(),
    mutationFn: async (asset: UpdatableSteamTrapAsset) => {
      console.log('Updating Steam Trap Asset');
      return updateSteamTrapAsset({
        client,
        asset,
      });
    },
    onMutate: async (_assetToUpdate: UpdatableSteamTrapAsset) => {
      return;
    },
    onError: (error, _variables, _context) => {
      console.error('Asset Update failed:', error, _variables);
      reportError(error);
    },
    onSuccess: (_data) => {
      console.log('Asset updated successfully!!');
    },
    onSettled: () => {
      // Invalidate queries to ensure data is fresh
      queryClient.invalidateQueries({ queryKey: steamTrapKeys.all() });
    },
  });
};
