import noop from 'lodash/noop';

import {
  ADFSBase,
  ADFSConfig,
  ADFSToken,
  DEBUG,
  LOGIN_IFRAME_NAME,
  extractADFSToken,
  webSilentLoginViaIframe,
} from '@cntxt/shared/providers/shared';
import { reportError } from '@cntxt/shared/util-errors';

export class ADFS extends ADFSBase {
  constructor({ authority, requestParams, storageProvider }: ADFSConfig) {
    super({ authority, requestParams, storageProvider });
  }

  public getQueryParams = async () => {
    const redirectUri = document.location.href;
    const queryParams = this.getADFSQueryParams({
      ...this.requestParams,
      redirectUri,
    });

    return queryParams;
  };

  public async login(): Promise<string | void | null> {
    DEBUG && console.log('[ADFS] Starting Login');
    const token = await this.acquireTokenSilently();

    if (
      token &&
      typeof token.accessToken === 'string' &&
      token.accessToken !== ''
    ) {
      return Promise.resolve(token.accessToken);
    }

    const queryParams = await this.getQueryParams();
    const url = `${this.authority}?${this.getADFSQueryParamString(
      queryParams,
    )}`;

    window.location.href = url;
    // this.handleLoginRedirect();
    // return;
  }

  /**
   * This method going to work only if 'X-Frame-Options' header
   * set to 'allow-from https://www.example.com' on the ADFS server.
   * And this is the only way to acquire token with ADFS silently
   * (using implicit grant flow)
   */
  protected async acquireTokenSilently(): Promise<ADFSToken | null> {
    let token = await this.getToken();
    // console.log('[sdk acquireTokenSilently] token', token, {
    //   Platform: Platform.OS,
    // });

    if (token) {
      return token;
    }

    const queryParams = await this.getQueryParams();

    const url = `${this.authority}?prompt=none&${this.getADFSQueryParamString(
      queryParams,
    )}`;

    try {
      token = await webSilentLoginViaIframe<ADFSToken | null>(
        url,
        extractADFSToken,
        LOGIN_IFRAME_NAME,
      );
    } catch (error) {
      reportError(
        error,
        'Failed to silently acquire an access token via iframe',
      );
      noop();
    }
    if (token) {
      this.setToken(token);
    }

    return token;
  }
}
