export const sanitizeHtml = (value: string) => {
  return value
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
};

export const sanitizeUrl = (value: string) => {
  return encodeURIComponent(value);
};

export const sanitizeSql = (value: string) => {
  return value.replace(/['";]/g, '');
};
