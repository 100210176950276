import { AssetFilterProps, CogniteClient } from '@cognite/sdk';

export const findAssetsByFilter = async ({
  client,
  filter,
}: {
  client: CogniteClient;
  filter: AssetFilterProps;
}) => {
  const result = await client.assets.search({
    filter,
  });

  return result;
};
export const findAssetsBySearch = async ({
  client,
  filter,
  search,
}: {
  client: CogniteClient;
  filter: AssetFilterProps;
  search?: AssetFilterProps;
}) => {
  const result = await client.assets.search({
    filter,
    search,
  });

  return result;
};
