import { Cards } from '@cntxt/shared/ui';

import { CardContent } from './CardContent';

export const Passing: React.FC<
  Omit<React.ComponentProps<typeof CardContent>, 'title'>
> = ({ value, total }) => {
  return (
    <Cards.Neutral>
      <CardContent title="Unhealthy Passing" value={value} total={total} />
    </Cards.Neutral>
  );
};
