import React from 'react';

import { Accordion, AccordionDetails, type SxProps } from '@mui/material';

import { IconsList } from '@cntxt/shared/ui-icons';

import { ProgressItem } from './ProgressItem';
import { ProgressTitle } from './ProgressTitle';

type Props = {
  title: string;
  titleIcon?: IconsList;
  isExpanded?: boolean;
  onClick?: (id: string) => void;
  steps: React.ComponentProps<typeof ProgressItem>[];
  titleSx?: SxProps;
  showTitleIconOnlyWhenCollapsed?: boolean;
  TitleCustomTextComponent?: React.ComponentType<{ children: React.ReactNode }>;
  ContentCustomTextComponent?: React.ComponentType<{
    children: React.ReactNode;
  }>;
};
export const ProgressStatus = ({
  title,
  titleIcon,
  isExpanded,
  steps,
  titleSx,
  showTitleIconOnlyWhenCollapsed,
  TitleCustomTextComponent,
  ContentCustomTextComponent,
  onClick,
}: Props) => {
  const isGroupReadOnly = steps.every((step) => step.isReadOnly);
  const isGroupCompleted = steps.every((step) => step.isCompleted);
  const isGroupActive = steps.some((step) => step.isActive);
  const [isGroupExpanded, setIsGroupExpanded] = React.useState(
    isExpanded || isGroupActive,
  );

  const handleAccordionChange = (_event: unknown, expanded: boolean) => {
    setIsGroupExpanded(expanded);
  };

  return (
    <Accordion
      disableGutters
      defaultExpanded={isGroupExpanded}
      expanded={isGroupExpanded}
      sx={{
        flexGrow: 1,
        borderWidth: '1px',
        boxShadow: 'none',
        borderStyle: 'solid',
        marginBottom: '10px',
      }}
      onChange={handleAccordionChange}
    >
      <ProgressTitle
        isExpanded={isGroupExpanded}
        isCompleted={isGroupCompleted}
        isReadOnly={isGroupReadOnly}
        iconName={titleIcon}
        text={title}
        sx={titleSx}
        showIconOnlyWhenCollapsed={showTitleIconOnlyWhenCollapsed}
        CustomTextComponent={TitleCustomTextComponent}
      />
      <AccordionDetails sx={{ padding: 0 }}>
        {steps.map((item, index) => {
          return (
            <ProgressItem
              key={`${item.id}_${index}`}
              id={item.id}
              isDisabled={item.isDisabled}
              isCompleted={item.isCompleted}
              isReadOnly={item.isReadOnly}
              text={item.text}
              clickLink={item.clickLink}
              isActive={item.isActive}
              icon={item.icon}
              CustomTextComponent={ContentCustomTextComponent}
              sx={{
                borderBottomWidth: index === steps.length - 1 ? '0' : '1px', // Hide border if last item
              }}
              onClick={onClick ? onClick.bind(this, item.id) : undefined}
            />
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};
