import * as React from 'react';
import { useParams } from 'react-router-dom';

import get from 'lodash/get';
import last from 'lodash/last';
import { DateTime } from 'luxon';

import { ErrorBoundary, Layout, Styles } from '@cntxt/shared/ui';

import { DataView } from './DataView';
import { StatusGroup } from './StatusGroup';

const useLeafItem = (): number => {
  const { id, ...rest } = useParams<'id'>();

  const extras = get(rest, '*') || '';

  return Number(last(extras.split('/')) || id);
};

type Props = {
  dataSetIds: { id: number }[];
  detailsRoute: string;
  isLastDateActive: boolean;
};
export const Dashboard: React.FC<React.PropsWithChildren<Props>> = ({
  dataSetIds,
  detailsRoute,
  isLastDateActive,
}) => {
  const leaf = useLeafItem();
  const [fromUnixTime, setFromUnixTime] = React.useState<number>(0);
  const [toUnixTime, setToUnixTime] = React.useState<number>(0);
  const [recentlySelectedDates, setRecentlySelectedDates] = React.useState<{
    fromUnixTime: number;
    toUnixTime: number;
  }>();
  const handleDurationChange = (startTime: number, endTime: number) => {
    setFromUnixTime(startTime);
    setToUnixTime(endTime);
  };
  React.useEffect(() => {
    if (isLastDateActive) {
      const now = DateTime.now().setZone('local');
      const startOfDayMillis = now.startOf('day').toMillis();
      const endOfDayMillis = now.endOf('day').toMillis();
      setRecentlySelectedDates({
        fromUnixTime,
        toUnixTime,
      });
      setFromUnixTime(startOfDayMillis);
      setToUnixTime(endOfDayMillis);
    } else if (recentlySelectedDates) {
      setFromUnixTime(recentlySelectedDates.fromUnixTime);
      setToUnixTime(recentlySelectedDates.toUnixTime);
    }
  }, [isLastDateActive]);

  return (
    <Layout.FlexBox
      sx={{
        gap: Styles.sizes.pixels._2,
      }}
    >
      <StatusGroup
        dataSetIds={dataSetIds}
        leaf={leaf}
        fromUnixTime={fromUnixTime}
        toUnixTime={toUnixTime}
      />
      <Layout.Spacer />
      <Layout.BoxVertical>
        <Layout.BoxCenter>
          <ErrorBoundary>
            <DataView
              dataSetIds={dataSetIds}
              leaf={leaf}
              detailsRoute={detailsRoute}
              handleDurationChange={handleDurationChange}
              fromUnixTime={fromUnixTime}
              toUnixTime={toUnixTime}
            />
          </ErrorBoundary>
        </Layout.BoxCenter>
      </Layout.BoxVertical>
    </Layout.FlexBox>
  );
};
