import { CogniteClient, AssetFilterProps } from '@cognite/sdk';

export const fetchAssets = async ({
  client,
  filter,
}: {
  client: CogniteClient;
  filter: AssetFilterProps;
}) => {
  const result = await client.assets.list({
    filter,
  });

  return result.items;
};
