import { clusters } from './clusters';
import { CustomerProject, IDP_TYPE } from './types';

export const projects: CustomerProject[] = [
  {
    id: 'demo-cntxt',
    idpType: IDP_TYPE.AZURE_AD,
    cluster: clusters.ew1,
    aadDirectory: 'fc87248c-df19-4714-ba28-a9dbee9eb920', // cntxtbycognite ad
    dataSetId: {
      steam: 1671183426811340,
    },
  },
  {
    id: 'dev-cntxt-ew1-1',
    idpType: IDP_TYPE.AZURE_AD,
    cluster: clusters.ew1,
    aadDirectory: 'fc87248c-df19-4714-ba28-a9dbee9eb920', // cntxtbycognite ad
    dataSetId: {
      steam: 5908663478080763,
    },
  },
  {
    id: 'dev2-cntxt-open',
    idpType: IDP_TYPE.ADFS2016,
    cluster: clusters.openfield,
    authority: 'https://test.adfs.cntxt.ai/adfs/oauth2/authorize',
    // logout - potentially add: &wreply=https://localhost:19006
    logout: 'https://test.adfs.cntxt.ai/adfs/ls/?wa=wsignout1.0',
    dataSetId: {
      steam: 1671183426811340,
      flare: 5491724393752012,
    },
  },
  {
    id: 'dev-cntxt-open',
    idpType: IDP_TYPE.ADFS2016,
    cluster: clusters.openfield,
    authority: 'https://test.adfs.cntxt.ai/adfs/oauth2/authorize',
    // logout - potentially add: &wreply=https://localhost:19006
    logout: 'https://test.adfs.cntxt.ai/adfs/ls/?wa=wsignout1.0',
    dataSetId: {
      steam: 1671183426811340,
    },
  },
];
