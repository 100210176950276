import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';

import { Chip, Styles } from '@cntxt/shared/ui';

export const ImageQualityPending = () => (
  <Chip
    sx={{
      color: Styles.colors.text.status.warning,
      background: Styles.colors.surface.status.warningMuted,
      display: 'flex',
      justifyContent: 'flex-start',
      gap: '9px',
    }}
  >
    <PendingActionsOutlinedIcon />
    Checking image quality
  </Chip>
);
