export const getNumberFromString = (
  numberAsDisplayString: string,
  decimals = 0,
) => {
  if (numberAsDisplayString === undefined) {
    return NaN;
  }

  const cleanStringNumber = numberAsDisplayString.replace(/[^0-9.-]+/g, '');

  if (cleanStringNumber === '') {
    return NaN;
  }

  if (decimals > 0) {
    return Number(Number(cleanStringNumber).toFixed(decimals));
  }

  return parseFloat(cleanStringNumber);
};
