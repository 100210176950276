import * as React from 'react';

import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { Layout } from '@cntxt/shared/ui';
import { DateFormat, getDateWithFormat } from '@cntxt/shared/util-date';
import {
  ChipHealthy,
  ChipPending,
  ChipBlocked,
  ChipPassing,
  ChipCompleted,
} from '@cntxt/steam/ui-chips';

import { Table } from '../Table';

export type SteamTrapTableData = {
  id: string;
  location: number;
  status: string;
  lastInspectionStatus: string;
  lastInspectionDate: string;
  facility: string;
};

const columnHelper = createColumnHelper<SteamTrapTableData>();

const columns = [
  columnHelper.accessor('id', {
    header: () => 'Steam Trap ID',
    minSize: 100,
    enableSorting: false,
    footer: (props) => props.column.id,
  }),
  columnHelper.accessor('location', {
    header: () => 'Location',
    minSize: 100,
    enableSorting: false,
    footer: (props) => props.column.id,
    cell: ({ getValue }) => {
      const value = getValue();
      return <Layout.BoxCenter>{value}</Layout.BoxCenter>;
    },
  }),
  columnHelper.accessor('facility', {
    header: () => 'Facility',
    minSize: 100,
    enableSorting: false,
    footer: (props) => props.column.id,
    cell: ({ getValue }) => {
      const value = getValue();
      return <Layout.BoxCenter>{value}</Layout.BoxCenter>;
    },
  }),

  columnHelper.accessor('lastInspectionStatus', {
    header: () => 'Last Inspection Status',
    enableSorting: false,
    footer: (props) => props.column.id,
    cell: ({ getValue, row }) => {
      const value = getValue();
      const Chip = States[value?.toLowerCase()];
      if (!value) {
        return '';
      }
      if (!Chip) {
        return <div key={`state-${row.id}`}>Invalid state: {value}</div>;
      }
      return (
        <Layout.BoxCenter>
          <Chip key={`state-${row.id}`} />
        </Layout.BoxCenter>
      );
    },
  }),

  columnHelper.accessor('lastInspectionDate', {
    header: () => 'Last Inspection Date',
    enableSorting: false,
    footer: (props) => props.column.id,
    cell: ({ getValue }) => {
      const value = getValue();
      if (!value) {
        return;
      }
      return (
        <Layout.BoxCenter>
          {getDateWithFormat(value, DateFormat.DAY_MONTH_YEAR)}
        </Layout.BoxCenter>
      );
    },
  }),
  columnHelper.accessor('status', {
    header: () => 'Status',
    enableSorting: false,
    footer: (props) => props.column.id,
    cell: ({ getValue, row }) => {
      const value = getValue();
      if (!value) {
        return;
      }
      const Chip = States[value];
      if (!Chip) {
        return <div key={`state-${row.id}`}>Invalid state: {value}</div>;
      }
      return (
        <Layout.BoxCenter>
          <Chip key={`state-${row.id}`} />
        </Layout.BoxCenter>
      );
    },
  }),
];

export const TableView = ({ data }: { data: SteamTrapTableData[] }) => {
  return <Table data={data} columns={columns as ColumnDef<unknown>[]} />;
};

const States: Record<SteamTrapTableData['status'], React.FC> = {
  completed: ChipCompleted,
  pending: ChipPending,
  healthy: ChipHealthy,
  blocked: ChipBlocked,
  passing: ChipPassing,
};
