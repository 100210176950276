import { Link } from 'react-router-dom';

import {
  Buttons,
  Divider,
  DrawerRight,
  Layout,
  Typography,
} from '@cntxt/shared/ui';
import { ROUTES } from '@cntxt/steam/util-ui';

export const RightPanel: React.FC<{
  onUpload: () => void;
  isUploading?: boolean;
  onSetId: (event: unknown, newValue: { id: number } | null) => void;
}> = ({ onUpload, isUploading }) => {
  return (
    <DrawerRight>
      <Layout.BoxVertical
        sx={{
          padding: '20px',
          flex: 1,
        }}
      >
        <Layout.BoxSpaced>
          <Typography.General.SubtitleBold text="Steam Trap" caps />
          {/* <Autocomplete
            data={
              data?.map((item) => ({ label: item.name, id: item.id })) || []
            }
            id="Steam-Service-Type"
            onSelect={onSetId}
            isFreeSolo={false}
          /> */}
        </Layout.BoxSpaced>
        {/* <Layout.BoxSpaced>
          <Typography.General.SubtitleBold text="Steam Trap Identifier" caps />
          <TextField
            id="filled-basic"
            variant="filled"
            hiddenLabel
            disabled
            size="small"
            value={'150-05'}
          />
        </Layout.BoxSpaced> */}
        {/* <Layout.BoxSpaced>
          <Typography.General.SubtitleBold text="Steam Service Type" caps />
          <Autocomplete data={data} id="Steam-Service-Type" />
        </Layout.BoxSpaced> */}
        {/* <Layout.BoxSpaced>
          <Typography.General.SubtitleBold text="Date Captured" caps />
          <TextField
            id="filled-basic"
            variant="filled"
            hiddenLabel
            disabled
            size="small"
            value={'150-05'}
          />
        </Layout.BoxSpaced>
        <Layout.BoxSpaced>
          <Typography.General.SubtitleBold text="Unit" caps />
          <TextField
            id="filled-basic"
            variant="filled"
            hiddenLabel
            disabled
            size="small"
            value={'150-05'}
          />
        </Layout.BoxSpaced> */}
        <Layout.Expander />
        <Layout.BoxVertical>
          <Divider />
          <Link to={ROUTES.HOME}>
            <Buttons.Cancel />
          </Link>

          <Buttons.Upload onClick={onUpload} loading={isUploading} />
          {/* <Link
            to={ROUTES.REVIEW}
            css={css`
              button {
                width: 100%;
              }
            `}
          >
            <Buttons.Upload />
          </Link> */}
        </Layout.BoxVertical>
      </Layout.BoxVertical>
    </DrawerRight>
  );
};
