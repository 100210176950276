import type { FC, PropsWithChildren } from 'react';
import { useEffect, createContext, useMemo, useState } from 'react';

import { CogniteClient } from '@cognite/sdk';
import { isString } from 'lodash';
import queryString from 'query-string';

import { LoginScreen } from '@cntxt/shared/feature-login';
import {
  StorageProvider,
  useCoreAuth,
  storageProvider,
} from '@cntxt/shared/providers/shared';
import {
  ADFSProject,
  AuthOptions,
  AuthState,
  AuthStateAuthenticated,
  CustomerProject,
  removeAccessTokenFromUrl,
} from '@cntxt/shared/util-auth';
import { reportError } from '@cntxt/shared/util-errors';

import { ADFSFlow } from '../flows/ADFSFlow';

export type AuthContextType = {
  client: CogniteClient;
  authState: AuthStateAuthenticated;
  logout: () => void;
  login: () => void;
};

export const AuthContext = createContext<AuthContextType | null>(null);

type Props = {
  appId?: string;
  name: string;
  authOptions: AuthOptions;
};
export const AuthProvider: FC<PropsWithChildren<Props>> = ({
  children,
  authOptions,
  appId = 'Unknown App',
  name,
}) => {
  const [error, setError] = useState('');

  const { login, authState, client, logout } = useCoreAuth({
    clientId: authOptions.clientId,
    appId,
    projectInfo: authOptions.project,
    storageProvider,
    isOnline: true,
    adfsFlowRef: (
      customerProject: CustomerProject & ADFSProject,
      storageProvider: StorageProvider,
    ) => {
      const adfs = new ADFSFlow(
        customerProject,
        storageProvider,
        authOptions.clientId,
      );
      return {
        getTokenFactory: () => Promise.resolve(adfs.getTokenFactory()),
        getUserState: () => Promise.resolve(adfs.getUserState()),
      };
    },
  });

  const handleCustomerSelect = async () => {
    setError('');
    try {
      await login();
    } catch (error: unknown) {
      setError((error as Error).message);
      reportError(error, 'Failed to login with customer id');
    }
  };

  useEffect(() => {
    const queryParams = queryString.parse(document.location.hash);
    const errorMessage = queryParams['error'];
    const errorMessageLong = queryParams['error_description'];
    if (isString(errorMessage) && isString(errorMessageLong)) {
      setError(errorMessageLong);
      // we show the message once then
      // reset it so we don't get this continually showing
      document.location.hash = '';
    }

    removeAccessTokenFromUrl();
  }, []);

  const value: Omit<AuthContextType, 'authState'> & { authState: AuthState } =
    useMemo(
      () => ({
        client,
        authState,
        logout,
        login,
      }),
      [client, authState, logout, login],
    );

  // this is the case for the unit tests and storybooks
  // it should not live here and shows how this is potentially flawed
  if (authOptions.project.idpType === 'none') {
    const mockClient = new CogniteClient({
      appId: '',
      project: 'cntxt-app',
      getToken: async () => '',
    });
    return (
      <AuthContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          client: mockClient,
          authState: {
            status: 'AUTHENTICATED',
            user: { name: 'Test User' },
          } as AuthStateAuthenticated,
          logout: () => null,
          login: async () => null,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }

  if (
    value.authState.status === 'UNAUTHENTICATED' ||
    value.authState.status === 'AUTHENTICATING'
  ) {
    return (
      <LoginScreen
        onSubmit={handleCustomerSelect}
        error={error}
        isLoading={authState.status === 'AUTHENTICATING'}
        authOptions={authOptions}
        name={name}
      />
    );
  }

  if (value.authState.status === 'ERROR') {
    return (
      <LoginScreen
        name={name}
        authOptions={authOptions}
        onSubmit={handleCustomerSelect}
        error={value.authState.message || error}
      />
    );
  }

  return (
    <AuthContext.Provider value={value as AuthContextType}>
      {children}
    </AuthContext.Provider>
  );
};
