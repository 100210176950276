import { CogniteClient, CogniteEvent, EventSearchRequest } from '@cognite/sdk';

// use this if you have a filter to find all matching events by as opposed to find events that has a limit
//currently used in dashboard to fetch all flow events.
//REVIEW - Check if this is best approach
export const findAllEvents = async <EventType extends CogniteEvent>(
  client: CogniteClient,
  query: EventSearchRequest,
): Promise<EventType[]> => {
  let nextCursor: string | undefined = 'initialValue';

  let allEvents: EventType[] = [];

  while (nextCursor) {
    const response = await client.events.list({
      ...query,
      cursor: nextCursor === 'initialValue' ? undefined : nextCursor,
    });

    if (!response.items) {
      // Handle the absence of items property in the response
      break;
    }

    const eventsOnPage = response.items as EventType[];

    // Append events on the current page to the result array
    allEvents = allEvents.concat(eventsOnPage);

    // Update nextCursor for the next page
    nextCursor = response.nextCursor;
  } // Continue looping until there's no more nextCursor

  return allEvents;
};
