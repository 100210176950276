import { Icons } from '@cntxt/shared/ui-icons';
import { MatchedLink } from '@cntxt/shared/ui-sidebar';
import { ROUTES } from '@cntxt/steam/util-ui';

import { isEnabled } from '../../../utils/featureFlag';

export function Menubar() {
  return (
    <MenubarInternal
      showNotifications={isEnabled('notifications')}
      showHistory={isEnabled('history')}
      showSteamTraps={isEnabled('steamTraps')}
    />
  );
}

export function MenubarInternal({
  showNotifications,
  showHistory,
  showSteamTraps,
}: {
  showNotifications?: boolean;
  showHistory?: boolean;
  showSteamTraps?: boolean;
}) {
  return (
    <>
      {showNotifications && (
        <MatchedLink to={ROUTES.ALERTS}>
          <Icons.Notification />
        </MatchedLink>
      )}
      {showHistory && (
        <MatchedLink to={ROUTES.HISTORY}>
          <Icons.History />
        </MatchedLink>
      )}
      {showSteamTraps && (
        <MatchedLink to={ROUTES.INSPECTION}>
          <Icons.Pending />
        </MatchedLink>
      )}
    </>
  );
}
