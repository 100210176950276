import {
  CogniteClient,
  FileUploadResponse,
  ExternalFileInfo,
  HttpHeaders,
} from '@cognite/sdk';
import type { FileContent } from '@cognite/sdk';
import axios from 'axios';

import { reportError } from '@cntxt/shared/util-errors';

export const createFile = ({
  client,
  metaData,
  fileContents,
}: {
  client: CogniteClient;
  metaData: ExternalFileInfo;
  fileContents?: FileContent;
}) => {
  const overwrite = true;
  const waitUntilAcknowledged = true;

  if (fileContents) {
    // console.log('Doing upload with content');
    return client.files
      .upload(metaData, fileContents, overwrite, waitUntilAcknowledged)
      .catch((error) => {
        // console.log('Upload with content error:', error);
        reportError(error, 'files-upload');
      });
  }

  // in mobile, we upload directly to the url via native libs
  // so we don't send the content with this first request
  return client.files.upload(metaData).catch((error) => {
    // console.log('File metadata upload error:', error);
    reportError(error, 'files-upload-metadata');
  });

  // return genericFileUploader(client, metaData, fileContents);
};

export const genericFileUploader = async (
  client: CogniteClient,
  metadata: ExternalFileInfo,
  fileContents: FileContent,
) => {
  // @ts-expect-error using internal private fields
  const filesApi = client.filesApi;

  try {
    const response = await axios.post<FileUploadResponse>(
      `${filesApi.httpClient.baseUrl}${filesApi.resourcePath}`,
      metadata,
      {
        headers: filesApi.httpClient.defaultHeaders,
      },
    );

    const file = response.data;

    if (fileContents != null) {
      const headers: HttpHeaders = {
        'Content-Type': metadata.mimeType || 'application/octet-stream',
      };

      return await axios
        .put(file.uploadUrl, fileContents, { headers })
        .then(() => {
          return file;
        })
        .catch((error) => {
          reportError(error, 'files-upload');
        });
    } else {
      return file;
    }
  } catch (error: unknown) {
    if (error && typeof error === 'object' && 'response' in error) {
      if (
        error.response &&
        typeof error.response === 'object' &&
        'data' in error.response
      ) {
        reportError(error.response.data);
      } else {
        reportError(error.response);
      }
    }

    return false;
  }

  // throw new Error(`Upload of file failed ${data.name}`);
};
