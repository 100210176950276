export const storageProvider = {
  clear: () => localStorage.clear(),
  removeItem: (key: string) => localStorage.removeItem(key),
  setItem: (key: string, value: string | object) => {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
  },
  getItem: (key: string) => Promise.resolve(localStorage.getItem(key)),
};

export type StorageProvider = typeof storageProvider;
