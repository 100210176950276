import { CogniteClient, FileInfo } from '@cognite/sdk';

import { createFile } from '@cntxt/shared/data-access-cdf';

import { CreatableImageFile } from '../../files/types';

export const uploadImage = async ({
  client,
  dataSetId,
  newFile,
}: {
  client: CogniteClient;
  dataSetId: FileInfo['dataSetId'];
  newFile: CreatableImageFile;
}) => {
  try {
    const uploadResult = await createFile({
      client,
      metaData: {
        dataSetId,
        ...newFile.metaData,
      },
      fileContents: newFile.fileContents,
    });

    return uploadResult;
  } catch (error) {
    console.error(
      'Error uploading asset metadata, cannot begin image content upload',
    );
    reportError(error);
  }
};
