const EVENT_KEY = 'event';

export const EVENT_KEYS = {
  ALL: () => [EVENT_KEY, 'getAll'],
  ONE: (id: number) => [EVENT_KEY, 'one', id],
  UPLOAD: (fileId: string) => [EVENT_KEY, 'upload', fileId],
  UPLOAD_ALL: () => [EVENT_KEY, 'getAll'],
  SET: (id: number) => [EVENT_KEY, 'set', id],
  BY_EXTERNAL_ID: (externalId?: string) => [
    EVENT_KEY,
    'byExternalId',
    externalId,
  ],
};
