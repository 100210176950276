import { Layout, Loading } from '@cntxt/shared/ui';
import { StatusCards } from '@cntxt/steam/ui-cards';

import { useData } from './useData';

type Props = {
  dataSetIds: { id: number }[];
  leaf: number;
  fromUnixTime: number;
  toUnixTime: number;
};
export const StatusGroup: React.FC<Props> = ({
  dataSetIds,
  leaf,
  fromUnixTime,
  toUnixTime,
}) => {
  const { data, isLoading } = useData(
    leaf,
    dataSetIds,
    fromUnixTime,
    toUnixTime,
  );
  if (isLoading) {
    return <Loading />;
  }
  const numberOfRecords = data.tableData.length;
  return (
    <Layout.BoxVertical>
      <StatusCards.Healthy
        value={data.totals.healthy}
        total={numberOfRecords}
      />
      <StatusCards.Passing
        value={data.totals.passing}
        total={numberOfRecords}
      />
      <StatusCards.Blocked
        value={data.totals.blocked}
        total={numberOfRecords}
      />
    </Layout.BoxVertical>
  );
};
