import { CogniteClient } from '@cognite/sdk';

import { reportError } from '@cntxt/shared/util-errors';

export const base64Header = 'data:application/octet-stream;base64,';

export const downloadImage = async ({
  client,
  externalId,
  stripHeader,
}: {
  client: CogniteClient;
  externalId?: string;
  stripHeader?: boolean;
}) => {
  if (!externalId) {
    return;
  }

  try {
    const fileDownload = await client.files.getDownloadUrls([{ externalId }]);
    if (!fileDownload?.[0]) {
      return;
    }
    const url = fileDownload?.[0].downloadUrl;

    const base64Image = (await fetchAsBlob(url).then(
      convertBlobToBase64,
    )) as string;

    if (stripHeader) {
      return base64Image.replace(base64Header, '');
    }

    return base64Image;
  } catch (error: unknown) {
    reportError(error);
    if (error && typeof error === 'object' && 'status' in error) {
      throw new Error(String(error.status));
    } else {
      throw new Error('Unknown image download error');
    }
  }
};

export type ImageDownloading = ReturnType<typeof downloadImage>;

const fetchAsBlob = (url: string) =>
  fetch(url).then((response) => response.blob());

const convertBlobToBase64 = (blob: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
