import { CSSProperties } from 'react';

import { TableContainer } from '@mui/material';

import { Styles, Typography } from '@cntxt/shared/ui';

import { CellText, HeaderContainer } from './elements';
import { HistoryChangeTableRows } from './HistoryChangeTableRows';

export type HistoryRow = {
  capturedDate: string;
  status: string;
  eventId?: number;
};
type Prop = {
  data: {
    tableHeaders: string[];
    rows: HistoryRow[];
  };
};

export const HistoryChangeTable: React.FC<Prop> = ({ data }) => {
  return (
    <TableContainer>
      <table style={styles.table}>
        <thead>
          <tr style={styles.tableContainerRow}>
            {data.tableHeaders.map((header, index) => (
              <th key={`header-${header}-${index}`} style={styles.tableHeader}>
                <HeaderContainer
                  style={{
                    ...styles.tableWidth,
                    ...styles.tableHeaderContainer,
                  }}
                >
                  <CellText style={styles.tableHeaderCellText}>
                    <Typography.General.Text text={header} caps />
                  </CellText>
                </HeaderContainer>
              </th>
            ))}
          </tr>
        </thead>
        <tbody style={styles.tableBody}>
          <HistoryChangeTableRows rowData={data.rows} />
        </tbody>
      </table>
    </TableContainer>
  );
};

const styles: Record<string, CSSProperties> = {
  table: {
    width: '100%',
  },
  tableContainerRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableContainerHeader: {
    width: '100%',
  },
  tableHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '50px',
    alignItems: 'center',
    background: `${Styles.colors.surface.backdrop}`,
  },
  tableHeaderCellText: {
    flexGrow: 1,
    width: '100%',
  },
  tableWidth: {
    minWidth: '20px',
  },
  tableBody: { display: 'flex', flexDirection: 'column' },
  bodyRowCellText: { width: '100%' },
};
