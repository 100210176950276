import type { SxProps } from '@mui/material';
import Box from '@mui/material/Box';

export const BoxHorizontal = ({
  children,
  sx,
}: React.PropsWithChildren<{ sx?: SxProps }>) => {
  return (
    <Box
      sx={{
        gap: '10px',
        flexDirection: 'row',
        display: 'flex',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
