import * as ReactDOM from 'react-dom/client';

import { environmentLoader } from '@cntxt/shared/util-envs';

import { App } from './app/App';
import { environment } from './environments/environment';

environmentLoader(environment).then(() => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );

  root.render(<App />);
});
