import * as React from 'react';

import HexagonOutlinedIcon from '@mui/icons-material/HexagonOutlined';
import Box from '@mui/material/Box';
import { styled } from 'styled-components';

import { ButtonsGrouped, Styles } from '@cntxt/shared/ui';

import { InspectionPanel } from './InspectionPanel';

const TabContentWrapper = styled.div`
  margin-top: 10px;
`;

const wrapComponent = (Component: React.FC) => () => (
  <TabContentWrapper>
    <Component />
  </TabContentWrapper>
);

export const InspectPanelView: React.FC = () => {
  const onClick = () => {
    console.log('wip');
  };

  const data: React.ComponentProps<typeof ButtonsGrouped>['data'] = [
    {
      buttonComponent: HexagonOutlinedIcon,
      contentComponent: wrapComponent(InspectionPanel),
      label: 'Inspection',
      value: '1',
    },
  ];
  const applyStyleFn = (_isActive = true): React.CSSProperties => {
    return {
      width: '300px',
      display: 'flex',
    };
  };

  const activeButtonsLabelStyle = Styles.colors.text.default;
  const inactiveButtonsLabelStyle = Styles.colors.text.default;

  return (
    <Box sx={{ padding: '20px' }}>
      <ButtonsGrouped
        onClick={onClick}
        label="Inspection Panel"
        data={data}
        isVertical={false}
        applyStylesFn={applyStyleFn}
        buttonsLabelStyle={{
          activeButtonTextColor: activeButtonsLabelStyle,
          inactiveButtonTextColor: inactiveButtonsLabelStyle,
        }}
      />
    </Box>
  );
};
