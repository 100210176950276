export const getInspectionEventName = (
  assetExternalId: string,
  imageId: string,
) => {
  if (!assetExternalId.startsWith('event-')) {
    return `event-${assetExternalId}-${imageId}`;
  } else {
    return assetExternalId;
  }
};
