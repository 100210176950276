import axios from 'axios';

import { reportError } from '@cntxt/shared/util-errors';

export type ClientCredentials = {
  clientId: string;
  clientSecret: string;
  scope: string;
  uri: string;
  resource?: string;
};
export type InternalClientCredentials = {
  cdfProject: string;
  clientId: string;
  clientSecret: string;
  resource: string;
  uri: string;
};
export const getTokenFromCredentials = async ({
  clientId,
  clientSecret,
  scope,
  uri,
  resource,
}: ClientCredentials): Promise<string> => {
  // console.log('Starting get token', uri);

  const params = new URLSearchParams();

  params.append('grant_type', 'client_credentials');
  params.append('client_id', clientId);
  params.append('client_secret', clientSecret);
  params.append('scope', scope);
  if (resource) {
    // only needed for adfs, not aad
    // note: the value should be the cluster url
    params.append('resource', resource);
  }

  const token = await axios
    .post(uri, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((x) => {
      // console.log('Internal token created', x.data);
      if (!x.data.access_token) {
        throw new Error('Token malformed');
      }
      return x.data.access_token;
    })
    .catch((error) => {
      console.error('\n\nError making token:');
      if ('response' in error && error.response) {
        if ('data' in error.response) {
          reportError(error.response.data);
        } else {
          reportError(error.response);
        }
      } else {
        reportError(error);
      }
    });

  return token;
};
