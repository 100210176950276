import { ECElementEvent } from 'echarts/types/dist/echarts';

import { CalendarChart, EchartsCalendarOption } from '@cntxt/shared/ui';

type Props = {
  graphOptions: Partial<EchartsCalendarOption>;
  onSelect?: (param: ECElementEvent) => void;
};
export const Calendar: React.FC<Props> = ({ graphOptions, onSelect }) => {
  return (
    <CalendarChart
      style={styles.chartStyle}
      options={graphOptions}
      onSelect={onSelect}
    />
  );
};

const styles: Record<string, React.CSSProperties> = {
  chartStyle: {
    flex: '0 0 150px',
    height: '175px',
  },
};
