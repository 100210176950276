import React from 'react';
import { useParams } from 'react-router-dom';

import get from 'lodash/get';
import omit from 'lodash/omit';
import { DateTime } from 'luxon';

import { Cards, Layout } from '@cntxt/shared/ui';
import { SteamTrapInspection } from '@cntxt/steam/feat-dashboard';

import { environment } from '../../../environments/environment';

import { TopBar } from './TopBar';

const useLeafItem = (): string[] => {
  const params = useParams<'id'>();
  const rest = omit(params, ['id']);
  const extras = get(rest, '*') || '';
  return extras.split('/');
};

export function SteamTraps() {
  const leafItem = useLeafItem();
  const dataSetIds = [{ id: environment.datasetId }];

  const formattedDate = DateTime.now().startOf('day').setZone('local');
  const toUnixTime = formattedDate.toMillis();
  const [fromUnixTime, setFromUnixTime] = React.useState<number | undefined>();
  const [status, setStatus] = React.useState<string>();

  return (
    <Cards.Clear sx={styles.container}>
      <Layout.PageContentBox>
        <TopBar setFromUnixTime={setFromUnixTime} setStatus={setStatus} />
        <Layout.BoxVertical>
          <Layout.Spacer />
          <Layout.Spacer />

          <SteamTrapInspection
            dataSetIds={dataSetIds}
            leafItem={leafItem}
            fromUnixTime={fromUnixTime}
            toUnixTime={toUnixTime}
            status={status}
          />
        </Layout.BoxVertical>
      </Layout.PageContentBox>
    </Cards.Clear>
  );
}
const styles: Record<string, React.CSSProperties> = {
  container: {
    width: '100%',
    height: '100%',
    maxWidth: '1280px',
  },
};
