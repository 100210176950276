import { Box } from '@mui/material';

import { Styles } from '@cntxt/shared/ui';
import {
  ImageQualityBad,
  ImageQualityGood,
  ImageQualityPending,
} from '@cntxt/steam/ui-chips';

import image21 from '../../../assets/images/2-1.jpg';

export const ImageWrapper = ({ state }: { state: string }) => {
  return (
    <Box
      sx={{
        border: `1px solid ${Styles.colors.border.default}`,
        borderRadius: '12px',
        width: '235px',
        height: '240px',
        padding: '15px',
        img: {
          paddingBottom: '10px',
        },
      }}
    >
      <Box>
        <img
          src={image21}
          alt="Thermal inspection result"
          width="200"
          height="140"
        />
        {state === 'good' && <ImageQualityGood />}
        {state === 'bad' && <ImageQualityBad />}
        {state === 'pending' && <ImageQualityPending />}
      </Box>
    </Box>
  );
};
