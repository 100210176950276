import { CogniteClient, CogniteEvent } from '@cognite/sdk';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { reportError } from '@cntxt/shared/util-errors';

import { createUploadEvent } from '../../network/createUploadEvent';
import { CreatableProcessedImageEvent } from '../../types';
import { EVENT_KEYS } from '../keys';

export const useCreateUploadEventMutation = (
  client: CogniteClient,
  dataSetId: number,
) => {
  const queryClient = useQueryClient();

  return useMutation<CogniteEvent, Error, CreatableProcessedImageEvent>({
    mutationKey: EVENT_KEYS.UPLOAD_ALL(),
    mutationFn: async (imageEvent: CreatableProcessedImageEvent) => {
      console.log('Creating Upload Event');
      return createUploadEvent({
        client,
        event: {
          ...imageEvent,
          dataSetId,
        },
      });
    },
    onMutate: async (newEvent: CreatableProcessedImageEvent) => {
      // Optionally, update cache immediately
      await queryClient.cancelQueries({ queryKey: EVENT_KEYS.ALL() });
      const previousEvents =
        queryClient.getQueryData<CreatableProcessedImageEvent[]>(
          EVENT_KEYS.ALL(),
        ) || [];
      queryClient.setQueryData<CreatableProcessedImageEvent[]>(
        EVENT_KEYS.ALL(),
        (old = []) => {
          return old ? [...old, newEvent] : [newEvent];
        },
      );
      return { previousEvents };
    },
    onError: (error, _variables, _context) => {
      reportError(error, 'Upload event creation failed');
      throw error;
    },
    onSuccess: (_data) => {
      console.log('Event uploaded successfully!!');
    },
    onSettled: () => {
      // Invalidate queries to ensure data is fresh
      queryClient.invalidateQueries({ queryKey: EVENT_KEYS.ALL() });
    },
  });
};
