import { CogniteClient, CogniteEvent, EventSearchRequest } from '@cognite/sdk';

// use this if you have a filter to find events by
export const findEvents = async <EventType extends CogniteEvent>(
  client: CogniteClient,
  query: EventSearchRequest,
): Promise<EventType[]> => {
  const events = await client.events.search(query);

  return events as EventType[];
};
