import { CogniteClient } from '@cognite/sdk/dist/src';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { reportError } from '@cntxt/shared/util-errors';

import { EVENT_KEYS } from '../../events/internal/keys';
import { CreatableImageFile } from '../../files/types';
import { uploadImage } from '../network/createFile';

export const useCreateFrameFileMutation = (
  client: CogniteClient,
  dataSetId: number,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: EVENT_KEYS.ALL(),
    mutationFn: async (imageEvent: CreatableImageFile) => {
      return uploadImage({
        client,
        dataSetId,
        newFile: imageEvent,
      });
    },
    onMutate: async (_newFile: CreatableImageFile) => {
      return;
    },
    onError: (error, _variables, _context) => {
      reportError(error, 'Upload file creation was unsuccessful.');
      throw error;
    },
    onSuccess: (data) => {
      console.log('File uploaded successfully!!', data);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: EVENT_KEYS.ALL(),
      });
    },
  });
};
