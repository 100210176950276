import React from 'react';

import { Button, useTheme, type SxProps } from '@mui/material';

import { Icon, IconsList } from '@cntxt/shared/ui-icons';

import { Layout, Typography } from '../../material';

type Props = {
  id: string;
  text: string;
  isActive: boolean;
  isCompleted: boolean;
  isDisabled: boolean;
  isReadOnly: boolean;
  clickLink?: string;
  icon?: IconsList;
  sx?: SxProps;
  CustomTextComponent?: React.ComponentType<{
    children: React.ReactNode;
  }>;
  onClick?: () => void;
};
export const ProgressItem = ({
  id,
  isActive,
  text,
  isCompleted,
  clickLink,
  isDisabled,
  isReadOnly,
  icon,
  sx,
  CustomTextComponent,
  onClick,
}: Props) => {
  const theme = useTheme();

  const completionIcon = isCompleted
    ? IconsList.radioButtonChecked
    : IconsList.radioButton;

  const permissionIcon = isReadOnly ? IconsList.notActive : IconsList.editing;

  const progressIcon = isReadOnly ? permissionIcon : completionIcon;

  const itemIcon =
    !icon || icon?.toString() === 'undefined'
      ? IconsList.info
      : (icon as IconsList);

  const color = 'inherit';

  const activeStatusColor = isActive ? theme.palette.success.main : 'inherit';
  const completionStatusColor = isCompleted
    ? theme.palette.action.disabledBackground
    : 'inherit';
  const backgroundColor = isActive ? activeStatusColor : completionStatusColor;

  const TextComponent = CustomTextComponent || Typography.Title.ExtraSmall;

  return (
    <Button
      key={id}
      variant={'text'}
      fullWidth={true}
      disabled={isDisabled}
      href={clickLink}
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        paddingX: '16px',
        paddingY: '12px',
        color,
        backgroundColor,
        width: '100%',
        textTransform: 'none',
        borderBottomStyle: 'solid',
        ...sx,
      }}
      onClick={onClick}
    >
      <Layout.BoxHorizontal sx={{ width: '100%' }}>
        <Layout.BoxCenter
          sx={{ gap: '8px', width: '100%', justifyContent: 'space-evenly' }}
        >
          <Icon iconName={itemIcon} sx={{ fontSize: '24px' }} />
          <TextComponent sx={{ flex: 1 }} text={text}>
            {text}
          </TextComponent>
          <Icon iconName={progressIcon} sx={{ fontSize: '24px' }} />
        </Layout.BoxCenter>
      </Layout.BoxHorizontal>
    </Button>
  );
};
