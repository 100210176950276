import { Divider } from '@mui/material';

import { Layout, Typography } from '@cntxt/shared/ui';

import { ImageWrapper } from './ImageWrapper';

export function History() {
  return (
    <Layout.FlexBox>
      <Layout.PageContent>
        <Layout.BoxVertical>
          <Layout.BoxVertical>
            <Typography.General.SubtitleBold text="zuhair.almutawaah uploaded 3 images" />
            <Typography.General.Subtitle text="3 images selected for 1 asset." />
            <Typography.General.Subtitle text="Images classification scheduled at 22-03-2021, 22:30" />
          </Layout.BoxVertical>
          <Divider />
          <Layout.BoxHorizontal>
            <ImageWrapper state="good" />
            <ImageWrapper state="bad" />
            <ImageWrapper state="pending" />
          </Layout.BoxHorizontal>
        </Layout.BoxVertical>
      </Layout.PageContent>
    </Layout.FlexBox>
  );
}
