import * as React from 'react';
import { useMatch, Link } from 'react-router-dom';

import { styled } from 'styled-components';

import { Styles } from '@cntxt/shared/ui';

const MenubarLink = styled(Link)`
  background: ${Styles.colors.surface.interactive.pressed};
  padding: 10px;
  border-radius: 8px;
  display: block;
`;

export const MatchedLink: React.FC<React.PropsWithChildren<{ to: string }>> = ({
  to,
  children,
}) => {
  const matched = useMatch(to);

  if (matched) {
    return <MenubarLink to={to}>{children}</MenubarLink>;
  }

  return (
    <MenubarLink to={to} style={{ background: 'none' }}>
      {children}
    </MenubarLink>
  );
};
