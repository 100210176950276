import React from 'react';

import { StylesUtil } from '@cntxt/shared/util-ui';

import { BoxCenter } from '../material/layout/BoxCenter';
import { FlexBox } from '../material/layout/FlexBox';
import { Typography } from '../material/typography';

import { Styles } from './../styles';

const defaultBorderColor = StylesUtil.hexToRgbA(
  Styles.colors.surface.backdrop,
  0.3,
);
const defaultBoldCornerColor = StylesUtil.hexToRgbA(
  Styles.colors.surface.backdrop,
  1,
);

type Props = {
  width?: string | number;
  height?: string | number;
  text?: string;
  textColor?: string;
  borderStyle?: string;
  borderColor?: string;
  borderThickness?: number;
  backgroundColor?: string;
  boldCornerColor?: string;
};

export const BoldCornersSquare: React.FC<Props> = ({
  width = '100%',
  height = '100%',
  text = '',
  textColor = Styles.colors.text.interactive.default,
  borderStyle = 'solid',
  borderColor = defaultBorderColor,
  borderThickness = 1,
  backgroundColor = 'transparent',
  boldCornerColor = defaultBoldCornerColor,
}) => {
  return (
    <BoxCenter
      sx={{
        width: width,
        height: height,
        border: `${borderStyle}`,
        borderColor: borderColor,
        position: 'relative',
        borderWidth: borderThickness,
        backgroundColor: backgroundColor,
      }}
    >
      <FlexBox
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        {/* top */}
        <FlexBox
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          {/* top left */}
          <LShapedComponent
            color={boldCornerColor}
            rotate={0}
            mirrorY={true}
            top={`-${borderThickness}px`}
            left={`-${borderThickness}px`}
            thickness={borderThickness}
          />
          {/* top right */}
          <LShapedComponent
            color={boldCornerColor}
            rotate={180}
            top={`-${borderThickness}px`}
            left={`${borderThickness}px`}
            thickness={borderThickness}
          />
        </FlexBox>
        <FlexBox
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            justifyContent: 'center',
            alignSelf: 'center',
          }}
        >
          <BoxCenter>
            <Typography.General.Subtitle
              text={text}
              textAlign={'center'}
              style={{ color: textColor }}
            />
          </BoxCenter>
        </FlexBox>
        {/* bottom */}
        <FlexBox
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
          }}
        >
          {/* bottom left */}
          <LShapedComponent
            color={boldCornerColor}
            rotate={0}
            top={`${borderThickness}px`}
            left={`-${borderThickness}px`}
            thickness={borderThickness}
          />
          {/* bottom right */}
          <LShapedComponent
            color={boldCornerColor}
            rotate={180}
            mirrorY={true}
            top={`${borderThickness}px`}
            left={`${borderThickness}px`}
            thickness={borderThickness}
          />
        </FlexBox>
      </FlexBox>
    </BoxCenter>
  );
};

const mirrorComponent = (mirrorX: boolean, mirrorY: boolean) => {
  const scaleX = mirrorX ? -1 : 1;
  const scaleY = mirrorY ? -1 : 1;
  return `scaleX(${scaleX}) scaleY(${scaleY})`;
};

type LShapedComponentProps = {
  color?: string;
  thickness?: number;
  height?: number;
  width?: number;
  rotate?: number;
  mirrorX?: boolean;
  mirrorY?: boolean;
  top?: string | number;
  left?: string | number;
};

const LShapedComponent: React.FC<LShapedComponentProps> = ({
  color = Styles.colors.surface.backdrop,
  thickness = 3,
  height = 5,
  width = 8,
  rotate = 0,
  mirrorX = false,
  mirrorY = false,
  top = 0,
  left = 0,
}) => {
  const outerStyle: React.CSSProperties = {
    position: 'relative',
    top: `${top}`,
    left: `${left}`,
    width: `${width}px`,
    height: `${height}px`,
    rotate: `${rotate}deg`,
    transform: mirrorComponent(mirrorX, mirrorY),
    backgroundColor: 'transparent',
  };

  const horizontalStyle: React.CSSProperties = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: `${width}px`,
    height: `${thickness}px`,
    backgroundColor: color,
  };

  const verticalStyle: React.CSSProperties = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: `${thickness}px`,
    height: `${height}px`,
    backgroundColor: color,
  };

  return (
    <div style={outerStyle}>
      <div style={horizontalStyle} />
      <div style={verticalStyle} />
    </div>
  );
};
