import { Typography, Cards } from '@cntxt/shared/ui';

type Props = {
  title: string;
};
export const Clear: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  children,
}) => {
  return (
    <Cards.Clear sx={{ paddingTop: '16px', width: '273px', height: '184px' }}>
      <Typography.Title.Small text={title} />
      {children}
    </Cards.Clear>
  );
};
