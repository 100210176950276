import React from 'react';

import { CogniteEvent, FileInfo } from '@cognite/sdk/dist/src';

import { downloadImage } from '@cntxt/shared/data-access-cdf';
import { useAuthContext } from '@cntxt/shared/providers/web';
import { useImagesByEventExternalIdQuery } from '@cntxt/steam/data-access';

type LoadedImageInfo = {
  base64Image: string;
  imageInfo: FileInfo;
};
export type ImageCardDetails = {
  title: string;
  imageSrc: string;
};
export const useThumbnailData = (
  dataSetId: number,
  selectedEvent: CogniteEvent | undefined,
) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const { client } = useAuthContext();

  const [imageFilesInfo, setImageFilesInfo] = React.useState<FileInfo[]>([]);
  const [loadedImages, setLoadedImages] = React.useState<LoadedImageInfo[]>([]);
  const [imageCardDetails, setImageCardDetails] = React.useState<
    ImageCardDetails[]
  >([]);
  const eventExternalId = selectedEvent?.externalId || '';
  const {
    data: fetchedImagesInfo,
    isLoading: isLoadingImages,
    refetch: refetchImagesByEventsData,
  } = useImagesByEventExternalIdQuery({
    client,
    dataSetId: dataSetId,
    eventExternalId,
    limit: 100,
  });
  React.useEffect(() => {
    // Manually trigger refetch when either startTime or endTime changes
    refetchImagesByEventsData();
  }, [selectedEvent?.externalId]);

  React.useEffect(() => {
    const hasImages =
      selectedEvent?.externalId && !isLoadingImages && fetchedImagesInfo;
    if (hasImages) {
      setImageFilesInfo(fetchedImagesInfo);
    }
  }, [fetchedImagesInfo, selectedEvent?.externalId, isLoadingImages]);

  React.useEffect(() => {
    const downloadPromises = imageFilesInfo.map((imageInfo) => {
      return downloadImage({
        client,
        externalId: imageInfo?.externalId,
        stripHeader: true,
      }).then((value) => ({
        imageInfo,
        base64Image: value ?? '',
      }));
    });

    Promise.all(downloadPromises).then((result) => {
      setLoadedImages(result);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, imageFilesInfo]);

  React.useEffect(() => {
    setImageCardDetails(
      loadedImages.map((loadedImage) => {
        if (!loadedImage.imageInfo) {
          return {
            title: '',
            imageSrc: loadedImage.base64Image,
          };
        }

        return {
          title:
            loadedImage.imageInfo.name
              .split('.')[0]
              .replace(/event-|-image/g, '') || '',
          imageSrc: loadedImage.base64Image,
        };
      }),
    );
    const isLoadingComplete =
      selectedEvent?.externalId &&
      !isLoadingImages &&
      fetchedImagesInfo?.length === loadedImages.length;
    if (isLoadingComplete) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedImages]);

  return {
    imageCardDetails,
    isLoading,
  };
};
