import * as React from 'react';

import { CogniteClient } from '@cognite/sdk/dist/src';

import { Asset } from '../../network/types';
import { useAssetTreeQuery } from '../queries/useAssetTreeQuery';

type Props = {
  client: CogniteClient;
  ids: Asset['id'][];
};
export const useAssetsByIdQuery = ({ client, ids }: Props) =>
  useAssetTreeQuery({
    client,
    rootIds: [],

    // ✅ memoizes with useCallback
    select: React.useCallback(
      (data: Asset[]) => {
        const filtered = data.filter((asset) => {
          if (!asset.id) {
            return false;
          }
          return ids.includes(asset.id);
        });
        return filtered;
      },
      [ids],
    ),
  });
