import * as React from 'react';

import { CogniteClient } from '@cognite/sdk';

import {
  ClientCredentials,
  getTokenFromCredentials,
} from '@cntxt/shared/util-auth';

/* NOTE: this only works for mobile - in web we get a CORS error
 *
 * getInternalToken is for when we need to make a token
 * for writing to CDF from inside the app
 * it is so the user doesn't need to have 'write access'
 * this makes it a little bit more secure
 * ...but it is still venerable to network interception
 *
 *
 */
const getInternalToken = async ({
  clientId,
  clientSecret,
  uri,
  resource,
}: Omit<ClientCredentials, 'scope'>) => {
  const token = await getTokenFromCredentials({
    uri,
    resource,
    clientId,
    clientSecret,
    scope: 'openid user_impersonation',
  });

  return token;
};

export const useInternalCogniteClient = ({
  clientId,
  clientSecret,
  uri,
  resource,
  cdfProject,
}: Omit<ClientCredentials, 'scope'> & { cdfProject: string }) => {
  const [client, setClient] = React.useState<CogniteClient>();
  React.useEffect(() => {
    getInternalToken({
      clientId,
      clientSecret,
      uri,
      resource,
    }).then((token) => {
      const internalClient = new CogniteClient({
        baseUrl: resource,
        appId: 'ai.cntxt.steam',
        project: cdfProject,
        getToken: () => Promise.resolve(token),
      });

      setClient(internalClient);
    });
  }, [clientId, clientSecret, uri, resource, cdfProject]);

  return client;
};
