import { css } from 'styled-components';

export const applyFonts = () => css`
  @font-face {
    font-family: 'ManifaBoldHiddenTashkeel';
    font-style: normal;
    font-weight: bold;
    src: url(${require('@cntxt/shared/ui-assets/fonts/ManifaPro2_2.224-BoldHiddenTashkeel.otf')})
      format('opentype');
  }

  @font-face {
    font-family: 'ManifaHefty';
    font-style: normal;
    font-weight: normal;
    src: url(${require('@cntxt/shared/ui-assets/fonts/ManifaPro2_2.224-Hefty.otf')})
      format('opentype');
  }
  @font-face {
    font-family: 'ManifaRegular';
    font-style: normal;
    font-weight: 900;
    src: url(${require('@cntxt/shared/ui-assets/fonts/ManifaPro2_2.224-Regular.otf')})
      format('opentype');
  }
  @font-face {
    font-family: 'ManifaSemiBold';
    font-style: normal;
    font-weight: bold;
    src: url(${require('@cntxt/shared/ui-assets/fonts/ManifaPro2_2.224-SemiBold.otf')})
      format('opentype');
  }
  @font-face {
    font-family: 'ManifaSemiBoldHiddenTashkeel';
    font-style: normal;
    font-weight: bold;
    src: url(${require('@cntxt/shared/ui-assets/fonts/ManifaPro2_2.224-SemiBoldHiddenTashkeel.otf')})
      format('opentype');
  }
  @font-face {
    font-family: 'Good Times Regular';
    font-style: normal;
    font-weight: 900;
    src: url(${require('@cntxt/shared/ui-assets/fonts/Good_Times_Regular.ttf')})
      format('truetype');
  }
  @font-face {
    font-family: 'BebasNeue-Regular';
    font-style: normal;
    font-weight: 900;
    src: url(${require('@cntxt/shared/ui-assets/fonts/BebasNeue-Regular.ttf')})
      format('truetype');
  }
`;

const types = {
  regular: {
    bebas: "'BebasNeue-Regular', sans-serif",
    goodTimes: "'Good Times Regular', sans-serif",
    manifa: "'ManifaRegular', sans-serif",
  },
  bold: {
    manifaTashkeel: "'ManifaBoldHiddenTashkeel', sans-serif",
  },
  semiBold: {
    manifa: "'ManifaSemiBold', sans-serif",
    manifaTashkeel: "'ManifaSemiBoldHiddenTashkeel', sans-serif",
  },
  hefty: { manifa: 'ManifaHefty, sans-serif' },
};

const titleLarge = {
  fontFamily: types.regular.bebas,
  WebkitFontSmoothing: 'antialiased',
};

const titleSmall = {
  fontFamily: types.semiBold.manifa,
  WebkitFontSmoothing: 'antialiased',
};

const sectionHeader = {
  fontFamily: types.hefty.manifa,
  WebkitFontSmoothing: 'antialiased',
};

const sectionHeaderSmall = {
  fontFamily: types.semiBold.manifaTashkeel,
  WebkitFontSmoothing: 'antialiased',
};

const subHeader = {
  fontFamily: types.bold.manifaTashkeel,
  WebkitFontSmoothing: 'antialiased',
};

const subHeaderSmall = {
  fontFamily: types.regular.manifa,
  WebkitFontSmoothing: 'antialiased',
};
const small = {
  fontFamily: types.regular.goodTimes,
  WebkitFontSmoothing: 'antialiased',
};

const typography = {
  sectionHeader,
  sectionHeaderSmall,
  subHeader,
  subHeaderSmall,
  small,
  titleLarge,
  titleSmall,
};

export const fonts = { typography, applyFonts, ...types };
