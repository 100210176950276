import queryString from 'query-string';

import { ACCESS_TOKEN, EXPIRES_IN, ID_TOKEN } from '../constants';

const cleanQueryString = (query: string) => {
  if (query.includes('#')) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_unused, goodPart] = query.split('#');
    return goodPart;
  }

  return query;
};

export const extractToken = (query: string) => {
  const {
    [ACCESS_TOKEN]: accessToken,
    [ID_TOKEN]: idToken,
    [EXPIRES_IN]: expiresIn,
  } = queryString.parse(cleanQueryString(query));

  return {
    accessToken,
    idToken,
    expiresIn,
  };
};
