import { IDP_TYPE } from '../lib/types';
import { AuthState } from '../typesAuthState';

export const getMockAuthState = (dataSetId: number): AuthState => {
  return {
    status: 'AUTHENTICATED',
    user: {
      id: 1234,
      name: 'test',
      email: 'test',
      idToken: 'test',
      accessToken: 'test',
    },
    projectInfo: {
      dataSetId: {
        test: dataSetId,
      },
      id: '123',
      cluster: '123',
      idpType: IDP_TYPE.AZURE_AD,
      aadDirectory: 'test',
    },
  };
};
