import { DateTime } from 'luxon';

export const getFieldExtractor = (value: string) => {
  const date = new Date(value);
  const datetime = DateTime.fromJSDate(date);

  return {
    getMonth: () => {
      return datetime.toFormat('MMMM');
    },
    getDay: () => {
      return datetime.toFormat('dd');
    },
    getYear: () => {
      return datetime.toFormat('yyyy');
    },
  };
};
