import CogniteClient from '@cognite/sdk/dist/src/cogniteClient';
import { Asset, IdEither } from '@cognite/sdk/dist/src/types';
import { useQuery } from '@tanstack/react-query';

import { findAssetsBySearch } from '@cntxt/shared/data-access-cdf';

import { ASSET_KEYS } from './keys';

type Props = {
  client: CogniteClient;
  dataSetIds: IdEither[];
  name: string;
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
  enabled?: boolean;
};

export const useFindAssetsByNameQuery = ({
  client,
  dataSetIds,
  name,
  refetchOnWindowFocus = true,
  refetchOnMount = true,
  enabled = true,
}: Props) => {
  const assets = useQuery({
    queryKey: ASSET_KEYS.ALL(),
    queryFn: () =>
      findAssetsBySearch({
        client,
        filter: {
          dataSetIds,
        },
      }),
    select: (data: Asset[]) =>
      data.filter((asset) => asset.name && asset.name.startsWith(name)),
    refetchOnWindowFocus,
    refetchOnMount,
    enabled,
  });

  return assets;
};
