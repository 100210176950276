import { type Row } from '@tanstack/react-table';

export const getDateFilterStartsWith =
  <Fields extends Record<string, string | object | null>>(
    field: keyof Fields,
  ) =>
  (row: Row<Fields>, _id: string, filterValue: string | number): boolean => {
    if (!row?.original) {
      return false;
    }

    const possibleStringField = row.original[field];

    if (typeof possibleStringField !== 'string') {
      return false;
    }

    return possibleStringField.includes(filterValue.toLocaleString());
  };
