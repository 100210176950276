import { Asset } from '@cognite/sdk/dist/src';

import { ProcessedImageWithUploadStatus } from '@cntxt/steam/data-types';

import { getInspectionFileName } from './getInspectionFileName';

export const makeNewEventFile = async (
  imageDetails: ProcessedImageWithUploadStatus,
  steamTrapAsset: Asset,
  metadata: {
    source: string;
    mimeType: string;
    eventExternalId: string;
  },
  imageInBytes: ArrayBufferLike,
  index: number,
) => {
  const imageFileExternalId = getInspectionFileName(
    `${metadata.eventExternalId}-${index}`,
  );
  const { healthy, blocked, passing } = imageDetails.metaData as Record<
    string,
    string
  >;
  const mlScores = JSON.stringify({
    healthy,
    blocked,
    passing,
  });
  return {
    metaData: {
      name: imageFileExternalId,
      externalId: imageFileExternalId,
      assetIds: [steamTrapAsset.id],
      source: metadata.source,
      metadata: {
        confidenceScore: `${imageDetails.confidenceScore}`,
        eventExternalId: metadata.eventExternalId || '',
        mlScores,
      },
      mimeType: 'image/jpeg',
    },
    fileContents: imageInBytes,
  };
};
