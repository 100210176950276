import { useAuthContext } from '@cntxt/shared/providers/web';
import { Buttons, Layout, Typography } from '@cntxt/shared/ui';

export function Settings() {
  const { logout } = useAuthContext();

  const handleLogout = () => {
    logout();
  };

  return (
    <Layout.FlexBox>
      <Layout.PageContent>
        <Typography.Title.Regular text="Settings" />
        <Layout.BoxVertical
          sx={{
            padding: '20px',
          }}
        >
          <Buttons.Logout onClick={handleLogout} />
        </Layout.BoxVertical>
      </Layout.PageContent>
    </Layout.FlexBox>
  );
}
