import { INSPECT } from './routes';

const buildPath = (...segments: (string | number | undefined)[]): string => {
  return segments.filter(Boolean).join('/');
};

export const getInspectionDetailsRoute = (
  baseRoute: string,
  datasetId: string | number = '',
  parentId: string | number = '',
  eventId: string | number = '',
): string => {
  return buildPath(baseRoute, datasetId, parentId, eventId) + `${INSPECT}`;
};

export const getAssetDashboardRoute = (
  baseRoute: string,
  datasetId: string | number = '',
  parentId: string | number = '',
  assetId: string | number = '',
): string => {
  return buildPath(baseRoute, datasetId, parentId, assetId);
};
