export const colors = {
  text: {
    default: '#e1e1e1',
    muted: '#333',
    interactive: {
      default: '#FFFFFF',
      hover: '#4A67FB',
      pressed: '',
    },
    status: {
      neutral: '#5D5D5D',
      success: '#2C6935',
      warning: '#8F4C0F',
      critical: '#A8361C',
    },
  },

  surface: {
    default: '#333333',
    backdrop: '#191A1A',
    muted: '#444444',
    interactive: {
      default: '#4A67FB',
      hover: '#BFBFBF',
      pressed: 'rgba(74, 103, 251, 0.08)',
    },
    status: {
      neutral: '#BFBFBF',
      neutralMuted: 'rgba(102, 102, 102, 0.1)',
      success: '#9FE2AC',
      successMuted: 'rgba(57, 162, 99, 0.1)',
      warning: '#FFDC7F',
      warningMuted: 'rgba(255, 187, 0, 0.12)',
      critical: '#F8AAB9',
      criticalMuted: 'rgba(223, 64, 55, 0.08)',
    },
  },

  border: {
    default: '#666666',
    interactive: {
      default: '',
      hover: '#D9D9D9',
    },
    status: {
      neutral: '',
      success: '',
      warning: '',
      critical: '',
    },
  },
};
