import { Chart } from 'react-chartjs-2';

import type {
  ChartData,
  Color,
  PluginChartOptions,
  Scriptable,
} from 'chart.js';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import times from 'lodash/times';

import { Styles } from '../../styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,

  BarElement,
  BarController,

  PointElement,

  LineElement,
  LineController,
);

const chartOptions: Partial<PluginChartOptions<'bar'>> = {
  responsive: true,
  plugins: {
    // @ts-expect-error missing props
    legend: {
      display: false,
    },
    // @ts-expect-error missing props
    title: {
      display: false,
    },
  },
  pointRadius: 0,
  barPercentage: 1.2,
  scales: {
    x: {
      display: false,
      grid: {
        display: false,
      },
    },
    y: {
      display: false,
      grid: {
        display: false,
      },
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
const realData = [1000, 200, 60, 650, 300, 800];
const dataSize = realData.length;

// @TODO: change to config
const blockedThreshold = 100;
const warningThreshold = 400;
const passingThreshold = 600;
// const blockedThreshold = config.thresholds.blocked;
// const warningThreshold = config.thresholds.passing;
// const passingThreshold = config.thresholds.passing;

const backgroundColor: Scriptable<Color, { raw: unknown }> = (value) => {
  if (Number(value.raw) < blockedThreshold) {
    return Styles.colors.surface.status.critical;
  }
  if (Number(value.raw) < warningThreshold) {
    return Styles.colors.surface.status.warning;
  }
  if (Number(value.raw) < passingThreshold) {
    return Styles.colors.surface.status.neutral;
  }

  return Styles.colors.surface.status.success;
};

const barData: ChartData<'bar', number[], string>['datasets'] = [
  {
    type: 'bar' as const,
    data: realData,
    backgroundColor,
  },
];

const chartData: ChartData<'bar' | 'line', number[], string> = {
  labels,

  datasets: [
    ...barData,
    {
      type: 'line' as const,
      data: times(dataSize).map(() => warningThreshold),
      borderColor: Styles.colors.surface.status.warning,
      borderWidth: 1,
    },
    {
      type: 'line' as const,
      data: times(dataSize).map(() => blockedThreshold),
      borderColor: Styles.colors.surface.status.critical,
      borderWidth: 1,
    },
  ],
};

type Props = {
  data?: ChartData<'bar' | 'line', number[], string>;
  options?: Partial<PluginChartOptions<'bar'>>;
};
export const BarVertical = (_props: Props) => {
  const data = _props.data || chartData;
  const options = _props.options || chartOptions;
  // @ts-expect-error todo fix types
  return <Chart options={options} data={data} />;
};
