import * as React from 'react';

import type { SxProps } from '@mui/material';
import Box from '@mui/material/Box';

import { sizes } from '../../styles/sizes';

export const BoxVertical = React.forwardRef(
  (
    {
      children,
      sx,
    }: React.PropsWithChildren<{
      sx?: SxProps;
    }>,
    ref,
  ) => {
    return (
      <Box
        ref={ref}
        sx={{
          gap: sizes.pixels._3,
          flexDirection: 'column',
          display: 'flex',
          ...sx,
        }}
      >
        {children}
      </Box>
    );
  },
);
