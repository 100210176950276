import React, { useState } from 'react';

import ReactECharts from 'echarts-for-react';

import { Styles } from '../../styles';

export enum ColorMode {
  Source = 'source',
  Target = 'target',
  Gradient = 'gradient',
  None = 'none',
}

const startColor = '#41cae8';
const endColor = '#2991ab';

const sankeyColors = [endColor, startColor];

type SankeyNode = {
  name: string;
};

type SankeyLink = {
  source: string;
  target: string;
  value: number;
  lineStyle: {
    opacity?: number;
    color?: {
      type: string;
      x: number;
      y: number;
      x2: number;
      y2: number;
      colorStops: {
        offset: number;
        color: string;
      }[];
    };
  };
};

type SankeyData = {
  data: SankeyNode[];
  links: SankeyLink[];
};

type ChartClickEventParams = {
  dataType: string;
  dataIndex: number;
};

type Props = {
  width?: string;
  height?: string;
  data: (string | number)[][];
  onClick: (row: number) => void;
};

export const SankeyChart: React.FC<Props> = ({
  width = '100%',
  height = '500px',
  data,
  onClick,
}) => {
  const [selectedLinkIndex, setSelectedLinkIndex] = useState<number | null>(
    null,
  );

  const useData = (inputData: (string | number)[][]): SankeyData => {
    const nodes = new Set<string>();
    const links: SankeyLink[] = [];

    inputData.slice(1).forEach((item, index) => {
      const [source, target, value] = item;
      const isStackSelected = selectedLinkIndex === index;
      nodes.add(source as string);
      nodes.add(target as string);
      links.push({
        source: source as string,
        target: target as string,
        value: value as number,
        lineStyle: {
          opacity: isStackSelected ? 1 : 0.5,
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [
              { offset: 1, color: sankeyColors[1] },
              { offset: 0.6, color: sankeyColors[1] },
              { offset: 0, color: sankeyColors[0] },
            ],
          },
        },
      });
    });

    const data = Array.from(nodes).map((name, index) => {
      let opacity = 0.8;
      const isZeroIndex = index === 0;
      const isStackSelected = selectedLinkIndex === index - 1;
      const isValidStackIndex = selectedLinkIndex !== null;
      if (isValidStackIndex) {
        if (isZeroIndex || isStackSelected) {
          opacity = 0.8;
        } else {
          opacity = 0.3;
        }
      } else {
        opacity = 0.8;
      }
      return {
        name,
        itemStyle: {
          opacity: opacity,
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [
              { offset: 0, color: sankeyColors[0] },
              { offset: 1, color: sankeyColors[1] },
            ],
          },
        },
        label: {
          position: isZeroIndex ? 'left' : 'right',
          overflow: 'visible',
          color: Styles.colors.text.interactive.default,
          rich: {
            center: {
              align: 'center',
            },
          },
          formatter: `{center| ${name}}`,
        },
      };
    });

    return { data, links };
  };
  const chartData = useData(data);
  const option = {
    grid: {
      containLabel: true,
    },
    series: [
      {
        type: 'sankey',
        left: 120.0, // horizontal positioning specifying the distance.
        right: 100.0, // horizontal positioning specifying the distance
        animation: false,
        nodeWidth: 10,
        nodeGap: Styles.sizes.raw._3,
        data: chartData.data,
        links: chartData.links,
        selectedMode: true,
        label: {
          fontSize: Styles.sizes.raw._25,
        },
        select: { disabled: true },
      },
    ],
  };

  const handleChartClick = (params: ChartClickEventParams) => {
    const isEdgeDataType = params.dataType === 'edge';
    const stackIndex = params.dataIndex;
    if (isEdgeDataType) {
      onClick(stackIndex);
      setSelectedLinkIndex(stackIndex);
    } else {
      onClick(-1);
      setSelectedLinkIndex(null);
    }
  };

  return (
    <ReactECharts
      option={option}
      style={{ width, height, overflow: 'visible' }}
      onEvents={{
        click: handleChartClick,
      }}
    />
  );
};
