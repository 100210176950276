import logoCntxt2024 from './logo_cntxt_2024.png';
import logoCntxtWhite from './logo_cntxt_white.png';
import logoCntxt from './logo_cntxt.png';
import logoSignIn from './logoSignIn.png';
import logoSideBar from './logoSideBar.png';

import loginBackground2024 from './background_2024.png';
import loginBackground from './background.jpg';

export const logos = {
  logoCntxt2024,
  logoCntxtWhite,
  logoCntxt,
  logoSideBar,
  logoSignIn,
};

export const backgrounds = {
  loginBackground2024,
  loginBackground,
};
