export const layouts = {
  width: {
    full: '100%',
    half: '50%',
  },
  height: {
    full: '100%',
    half: '50%',
  },
};
