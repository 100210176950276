import type { AuthenticationResult, Configuration } from '@azure/msal-browser';
import { PublicClientApplication } from '@azure/msal-browser';

import {
  AuthStateUser,
  AzureADProject,
  CustomerProject,
} from '@cntxt/shared/util-auth';
import { reportError } from '@cntxt/shared/util-errors';

import { storageProvider } from '../../storageProvider';

import Flow from './Flow';

class AzureADFlow extends Flow {
  static override user: AuthStateUser;
  public cdfScopes;
  public pca: PublicClientApplication;
  private idToken = '';
  private accessToken = '';
  constructor(projectInfo: CustomerProject & AzureADProject, clientId: string) {
    super();

    const tenantId = projectInfo.aadDirectory;
    this.cdfScopes = [
      `${projectInfo.cluster}/user_impersonation`,
      `${projectInfo.cluster}/IDENTITY`,
    ];

    const configuration: Configuration = {
      auth: {
        clientId,
        authority: `https://login.microsoftonline.com/${tenantId || 'common'}`,
        redirectUri: window.location.origin,
        navigateToLoginRequestUrl: true,
      },
    };
    this.pca = new PublicClientApplication(configuration);
  }
  override async getTokenFactory(): Promise<() => Promise<string>> {
    const account = await storageProvider.getItem('account');
    if (!account) {
      try {
        const response: AuthenticationResult = await this.pca.loginPopup({
          prompt: 'select_account',
          scopes: this.cdfScopes,
        });

        if (response.account) {
          this.idToken = response.idToken;
          this.accessToken = response.accessToken;
          storageProvider.setItem('account', response.account.localAccountId);
        }
      } catch (error) {
        reportError(error, 'Failed to Login');
        return Promise.reject(error);
      }
    }
    return async () => {
      const accountId = await storageProvider.getItem('account');
      if (!accountId) throw new Error('No user id found');

      // Get account by ID.
      const account = this.pca.getAccountByLocalId(accountId);
      if (!account) throw new Error('No user found');

      // Get token information.
      try {
        const token = await this.pca.acquireTokenSilent({
          account,
          scopes: this.cdfScopes,
        });
        this.idToken = token.idToken;
        this.accessToken = token.accessToken;

        return token.accessToken;
      } catch (error) {
        reportError(error, 'Failed to get token');
        throw new Error('Failed to get token');
      }
    };
  }

  override async getUserState(): Promise<AuthStateUser> {
    const accountId = await storageProvider.getItem('account');
    if (!accountId) throw new Error('no user_id found');
    const account = this.pca.getAccountByLocalId(accountId);
    if (!account) throw new Error('no user found');
    return {
      id: account?.localAccountId,
      email: account?.username,
      name: account?.name || account?.username,
      idToken: this.idToken,
      accessToken: this.accessToken,
    };
  }
}

export default AzureADFlow;
