import { Styles } from '@cntxt/shared/ui';

import { ImageCard, Props } from './ImageCardBase';

export const SelectedImageCard: React.FC<Props> = ({
  title,
  imageSrc,
  iconCallback,
  imageCallback,
  isIconVisible = true,
}) => {
  return (
    <ImageCard
      title={title}
      imageSrc={imageSrc}
      iconCallback={iconCallback}
      imageCallback={imageCallback}
      sx={styles.selectedContainer}
      isIconVisible={isIconVisible}
    />
  );
};

const styles = {
  selectedContainer: {
    background: Styles.colors.surface.default,
    borderRadius: '8px',
    borderColor: Styles.colors.surface.interactive.default,
    borderWidth: '3px',
    borderStyle: 'solid',
    flex: '0 0 200px',
    height: '200px',
    padding: '8px',
  },
};
