import { CogniteClient, IdEither } from '@cognite/sdk';

// use this when you need a list of known ids
export const fetchEvents = async (
  client: CogniteClient,
  ids: Partial<IdEither>[],
) => {
  const safeIds = ids.filter((id) => {
    if (!id) {
      return false;
    }

    if ('id' in id) {
      return id.id;
    }

    if ('externalId' in id) {
      return id.externalId;
    }

    return false;
  });

  if (safeIds.length === 0) {
    console.log('Error: you are looking for nothing, in fetchEvents!');
    return Promise.resolve([]);
  }

  const events = await client.events.retrieve(safeIds as IdEither[]);

  return events;
};
