import { Add } from './Add';
import { Back } from './Back';
import { Button, defaultButtonStyles } from './Button';
import { Cancel } from './Cancel';
import { Dashboard } from './Dashboard';
import { Done } from './Done';
import { Logout } from './Logout';
import { PrimaryButton } from './themed/PrimaryButton';
import { SecondaryButton } from './themed/SecondaryButton';
import { Upload } from './Upload';

export const Buttons = {
  defaultButtonStyles,
  Add,
  Back,
  Button,
  PrimaryButton,
  SecondaryButton,
  Cancel,
  Dashboard,
  Done,
  Logout,
  Upload,
};
