import { CogniteClient } from '@cognite/sdk/dist/src';
import { useQuery } from '@tanstack/react-query';

import { fetchEvents } from '../../../report/network/fetchEvents';
import { EVENT_KEYS } from '../keys';

type Props = {
  client: CogniteClient;
  dataSetIds: { id: number }[];
};
export const useUploadEventsQuery = ({ client, dataSetIds }: Props) => {
  return useQuery({
    queryKey: EVENT_KEYS.UPLOAD_ALL(),
    queryFn: () =>
      fetchEvents({
        client,
        filter: {
          dataSetIds,
          metadata: {
            //   fileId,
            //   runId: '',
          },
          type: 'upload',
          subtype: 'image',
        },
      }),
  });
};

// export const useUploadEventsQuery = ({
//   fileId,
//   dataSetIds,
// }: {
//   fileId: string;
//   dataSetIds: { id: number }[];
// }) => {
//   const coreAuth = useAuthContext();

//   return useQuery(EVENT_KEYS.UPLOAD(fileId), () =>
//     fetchEvents({
//       client: coreAuth.client,
//       filter: {
//         dataSetIds,
//         metadata: {
//           fileId,
//           runId: '',
//         },
//       },
//     })
//   );
// };
