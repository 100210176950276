import React from 'react';

import { ECElementEvent, EChartsOption } from 'echarts/types/dist/echarts';
import ReactECharts from 'echarts-for-react';

type CustomSeriesOption = {
  type: 'heatmap';
  coordinateSystem: 'calendar';
} & Partial<EChartsOption['series']>;

export type EchartsCalendarOption = {
  visualMap: Partial<EChartsOption['visualMap']>;
  calendar: Partial<EChartsOption['calendar']>;
  series: CustomSeriesOption[];
} & EChartsOption;

const defaultOptions: EchartsCalendarOption = {
  visualMap: {
    show: false,
    min: 0,
    max: 1,
  },
  calendar: {
    range: '2024',
  },
  series: [
    {
      type: 'heatmap',
      coordinateSystem: 'calendar',
    },
  ],
};

type Props = {
  options: Partial<EchartsCalendarOption>;
  style?: React.CSSProperties;
  onSelect?: (param: ECElementEvent) => void;
};

export const CalendarChart: React.FC<Props> = ({
  options = defaultOptions,
  style,
  onSelect,
}) => {
  const handleChartClick = (param: ECElementEvent) => {
    onSelect && onSelect(param);
  };

  return (
    <ReactECharts
      style={style}
      option={{
        ...options,
        series: options.series,
      }}
      onEvents={{
        click: (param: ECElementEvent) => handleChartClick(param),
      }}
    />
  );
};
