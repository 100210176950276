import { CogniteClient, AssetChange } from '@cognite/sdk';

export const updateAsset = async ({
  client,
  changeAsset,
}: {
  client: CogniteClient;
  changeAsset: AssetChange;
}) => {
  // note: this function is a helper to update just one asset at a time
  return client.assets.update([changeAsset]);
};
