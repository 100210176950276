import { Add } from './Add';
import { DropDownArrow } from './ArrowDropDown';
import { Ascending } from './Ascending';
import { Cancel } from './Cancel';
import { Delete } from './Delete';
import { Descending } from './Descending';
import { Edit } from './Edit';
import { Filter } from './Filter';
import { History } from './History';
import { Home } from './Home';
import { LeftArrow } from './LeftArrow';
import { Logout } from './Logout';
import { MoreDots } from './MoreDots';
import { Notification } from './Notifications';
import { Pending } from './Pending';
import { Right } from './Right';
import { RightArrow } from './RightArrow';
import { Search } from './Search';
import { Settings } from './Settings';
import { User } from './User';

export const Icons = {
  Add,
  Edit,
  Filter,
  Ascending,
  Cancel,
  Delete,
  Descending,
  Home,
  History,
  LeftArrow,
  Logout,
  MoreDots,
  Notification,
  Pending,
  Right,
  RightArrow,
  DropDownArrow,
  Settings,
  Search,
  User,
};
