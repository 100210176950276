import { DateTime } from 'luxon';

export const getDisplaySeconds = (dateString: Date): string => {
  const datatime = DateTime.fromJSDate(dateString).setZone('local');
  const formattedDate = datatime.toLocaleString(DateTime.TIME_WITH_SECONDS);

  if (formattedDate === 'Invalid DateTime') {
    return 'Invalid date: ' + dateString;
  }

  return formattedDate;
};
