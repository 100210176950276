import React from 'react';

import { DurationSelector, Layout, Styles } from '@cntxt/shared/ui';
import { StatusSelector } from '@cntxt/steam/feat-dashboard';
import { ROUTES } from '@cntxt/steam/util-ui';

import { Actions } from '../Dashboard/Actions';

type Props = {
  setFromUnixTime: (duration: number) => void;
  setStatus: (status: string) => void;
};
type Status = {
  id: number;
  name: string;
};
export const TopBar = ({ setFromUnixTime, setStatus }: Props) => {
  const handleDurationChange = (selectedDuration: number) => {
    setFromUnixTime(selectedDuration);
  };
  const handleStatusChange = (selectedStatus: Status) => {
    setStatus(selectedStatus.name);
  };
  return (
    <Layout.Page sx={styles.wrapper}>
      <Layout.BoxVertical>
        <Layout.BoxCenter sx={styles.container}>
          <Actions assetURLToRoute={ROUTES.STEAM_TRAPS_INSPECTION} />
          <DurationSelector
            onClick={handleDurationChange}
            labelColor={Styles.colors.text.default}
            groupButtonsColor={{
              active: Styles.colors.text.default,
              inactive: Styles.colors.text.interactive.hover,
            }}
            activeButtonColor={Styles.colors.surface.interactive.pressed}
          />
          <StatusSelector onStatusChange={handleStatusChange} />
        </Layout.BoxCenter>
      </Layout.BoxVertical>
    </Layout.Page>
  );
};

const styles: Record<string, React.CSSProperties> = {
  wrapper: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    alignItems: 'flex-end',
    gap: 4,
  },
};
