import React from 'react';

import { type SxProps } from '@mui/material';

import { Buttons, Layout, Styles, Typography } from '@cntxt/shared/ui';
import { Icon, IconsList } from '@cntxt/shared/ui-icons';
import { Breadcrumbs } from '@cntxt/steam/feat-dashboard';
import { ROUTES } from '@cntxt/steam/util-ui';

import { Actions } from './Actions';

export const TopBar: React.FC<{
  handleLastDate: (lastDateActive: boolean) => void;
}> = ({ handleLastDate }) => {
  const [selected, setSelected] = React.useState<boolean>();
  const handleButtonClick = () => {
    setSelected(!selected);
    handleLastDate(!selected);
  };
  return (
    <Layout.Page sx={styles.container}>
      <Layout.PageContentBox>
        <Typography.Title.Regular text="Advanced Steam Trap Inspection" />
        <Typography.Title.Regular text=" | Central Dashboard | " muted />
        <Breadcrumbs separatorCharacter={', '} />
      </Layout.PageContentBox>
      <Actions assetURLToRoute={ROUTES.DASHBOARD} />
      <Layout.PageContentBox sx={styles.buttonContainer}>
        <Buttons.Button
          startIcon={
            <Icon
              iconName={IconsList.calender}
              sx={selected ? styles.active : styles.inActive}
            />
          }
          onClick={handleButtonClick}
          text="Date: Last Day"
          sx={styles.calendarContainer}
        />
      </Layout.PageContentBox>
    </Layout.Page>
  );
};
const styles: Record<string, SxProps> = {
  container: {
    justifyContent: 'space-between',
    width: '100%',
    height: 'auto',
  },
  buttonContainer: {
    display: 'flex',
    gap: Styles.sizes.pixels._1,
    button: {
      alignItems: 'center',
    },
  },
  calendarContainer: {
    width: 150,
    height: 50,
    color: Styles.colors.text.default,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  inActive: {
    color: Styles.colors.text,
  },
  active: {
    color: Styles.colors.text.interactive.hover,
  },
};
