import WarningAmberSharpIcon from '@mui/icons-material/WarningAmberSharp';

import { Chip, Styles } from '@cntxt/shared/ui';

export const ImageQualityBad = () => (
  <Chip
    sx={{
      color: Styles.colors.text.status.critical,
      background: Styles.colors.surface.status.criticalMuted,
      display: 'flex',
      justifyContent: 'flex-start',
      gap: '9px',
    }}
  >
    <WarningAmberSharpIcon />
    Image quality bad
  </Chip>
);
