import ReactECharts from 'echarts-for-react';

type LineChartProps = {
  options?: Record<string, string | number | object>;
  styles?: { width: number | string; height: number | string };
};

export const LineChart: React.FC<LineChartProps> = ({ options, styles }) => {
  return (
    <ReactECharts
      option={options}
      style={styles}
      lazyUpdate={true}
      notMerge={true}
    />
  );
};
