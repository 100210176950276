import CogniteClient from '@cognite/sdk/dist/src/cogniteClient';
import { CogniteEvent, IdEither } from '@cognite/sdk/dist/src/types';
import { useQuery } from '@tanstack/react-query';

import { findEvents } from '@cntxt/shared/data-access-cdf';

import { EVENT_KEYS } from '../keys';

type Props = {
  client: CogniteClient;
  dataSetIds: IdEither[];
  externalId: string;
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
  enabled?: boolean;
};
export const useFindEventsByExternalIdQuery = ({
  client,
  dataSetIds,
  externalId,
  refetchOnWindowFocus = true,
  refetchOnMount = true,
  enabled = true,
}: Props) => {
  return useQuery({
    queryKey: EVENT_KEYS.ALL(),
    queryFn: () =>
      findEvents(client, {
        filter: {
          dataSetIds,
          externalIdPrefix: externalId,
        },
      }),
    select: (data: CogniteEvent[]) =>
      data.filter(
        (event) => event.externalId && event.externalId.startsWith(externalId),
      ),
    refetchOnWindowFocus,
    refetchOnMount,
    enabled,
  });
};
