import { DateTime } from 'luxon';
export const getTimeFromDuration = (duration: string) => {
  const defaultTime = +new Date();
  if (duration.includes('Y')) {
    return (
      DateTime.local()
        .minus({ years: parseInt(duration) })
        .toMillis() || defaultTime
    );
  }
  if (duration.includes('M')) {
    return (
      DateTime.local()
        .minus({ months: parseInt(duration) })
        .toMillis() || defaultTime
    );
  }
  if (duration.includes('D')) {
    return (
      DateTime.local()
        .minus({ days: parseInt(duration) })
        .toMillis() || defaultTime
    );
  }
  return defaultTime;
};
