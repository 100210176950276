import { useSnackbar } from 'notistack';

type Success<Data> = { success: true; data: Data };
type Failure = { success: false };

/**
 * This processes results from the API and displays messages based on the result
 *
 * Usage:
 *
 * const { processResult } = useCRUDnotifications();
 * const result = await something.mutateAsync({...});
 * processResult(result);
 *
 * Note: this will check for the 'id' field in the result object
 * if your result does not contain 'id' and you want to test for something else
 * you can pass in a string to use as the accessor, eg:
 *
 * const { processResult } = useCRUDnotifications('name');
 */
export function useCRUDnotifications<Accessor extends string = 'id'>(
  accessor: Accessor = 'id' as Accessor,
) {
  const { enqueueSnackbar } = useSnackbar();

  type Data = { [K in Accessor]?: string };

  const processResult = (
    result: { error: unknown } | Data,
    options: {
      successMessage?: string;
      errorMessage?: string;
    } = { successMessage: 'Update success', errorMessage: 'Update failed' },
  ): Success<Data> | Failure => {
    if (accessor in result) {
      enqueueSnackbar(options.successMessage, { variant: 'success' });
      return { success: true, data: result as Data };
    } else {
      enqueueSnackbar(options.errorMessage, { variant: 'error' });
      return { success: false };
    }
  };

  return { processResult };
}
