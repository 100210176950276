import { Doughnut as DoughnutChart } from 'react-chartjs-2';

import type { ChartData, PluginChartOptions } from 'chart.js';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { Styles } from '../../styles';

ChartJS.register(ArcElement, Tooltip, Legend);

const doughnutOptions: Partial<PluginChartOptions<'doughnut'>> = {
  plugins: {
    legend: {
      position: 'right',
      // @ts-expect-error strange type
      labels: {
        usePointStyle: true,
        boxHeight: 5,
        boxWidth: 5,
      },
    },
    maintainAspectRatio: false,
  },
  layout: {
    padding: 0,
  },
};
const doughnutData = {
  labels: [],
  datasets: [
    {
      label: '',
      data: [5, 2, 3],
      backgroundColor: [
        Styles.colors.surface.status.success,
        Styles.colors.surface.status.neutral,
        Styles.colors.surface.status.critical,
      ],
      borderWidth: 0,
      borderRadius: 0,
    },
  ],
};

export function Doughnut({
  options,
  data,
}: {
  options?: Partial<PluginChartOptions<'doughnut'>>;
  data?: ChartData<'doughnut', number[], string>;
}) {
  const chartData = { ...doughnutData, ...data };
  const chartOptions = options || doughnutOptions;

  return <DoughnutChart data={chartData} options={chartOptions} />;
}
