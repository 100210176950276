import * as React from 'react';
import { Link } from 'react-router-dom';

import { Buttons } from '../material/buttons';
import { Typography } from '../material/typography';

export const BackComponent: React.FC<{ to: string; text: string }> = ({
  to,
  text,
}) => {
  return (
    <>
      <Link to={to}>
        <Buttons.Back />
      </Link>

      <Typography.Title.Small text={text} sx={{ marginLeft: '10px' }} />
    </>
  );
};
