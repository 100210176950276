import { CogniteClient } from '@cognite/sdk/dist/src';
import { useQuery } from '@tanstack/react-query';

import { fetchEvents } from '../../../report/network/fetchEvents';
import { EVENT_KEYS } from '../keys';

type Props = {
  client: CogniteClient;
  id: number;
  dataSetIds: { id: number }[];
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
  startTime?: number;
  endTime?: number;
};

export const useEventsQuery = ({
  client,
  id,
  dataSetIds,
  refetchOnWindowFocus = true,
  refetchOnMount = true,
  startTime,
  endTime,
}: Props) => {
  return useQuery({
    queryKey: EVENT_KEYS.SET(id),
    queryFn: () =>
      fetchEvents({
        client,
        filter: {
          dataSetIds,
          assetSubtreeIds: [{ id }],
          startTime: { min: startTime, max: endTime },
        },
      }),
    refetchOnWindowFocus,
    refetchOnMount,
  });
};
