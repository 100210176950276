import { Box } from '@mui/material';

import { Layout, Typography } from '@cntxt/shared/ui';

type CardContentProps = {
  value: number;
  title: string;
  total: number;
};

export const CardContent: React.FC<CardContentProps> = ({
  title,
  value,
  total,
}) => {
  const percentage = (value / total) * 100;
  let percentValue = percentage.toFixed(2);
  if (Number.isInteger(percentage)) {
    percentValue = percentage.toFixed(0);
  }
  if (value) {
    return (
      <Layout.BoxVertical sx={styles.container}>
        <Typography.Title.Regular text={title} sx={styles.fontNormal} />
        <Box>
          <Typography.Title.ExtraLarge text={value} />
          <Typography.Title.Medium text={'/'} />
          <Typography.Title.Small text={total} />
        </Box>
        <Layout.FlexBox sx={styles.contentEnd}>
          <Typography.Title.Small text={`${percentValue}%`} />
        </Layout.FlexBox>
      </Layout.BoxVertical>
    );
  }
  return (
    <Box>
      <Typography.Title.Regular text={title} sx={styles.fontNormal} />
      <Box>
        <Typography.Title.ExtraLarge text={value} />
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    gap: 0,
    justifyContent: 'space-between',
    height: '100%',
  },
  fontNormal: {
    fontWeight: 'normal',
  },
  contentEnd: {
    justifyContent: 'flex-end',
  },
};
