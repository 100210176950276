import * as React from 'react';
import { useParams } from 'react-router-dom';

import capitalize from 'lodash/capitalize';

import { sortEvents } from '@cntxt/shared/data-access-cdf';
import { useAuthContext } from '@cntxt/shared/providers/web';
import {
  DateFormat,
  getDateWithFormat,
  getDisplayDate,
} from '@cntxt/shared/util-date';
import {
  getAssetIdsFromEvents,
  getNamesFromAssetIds,
  useAssetsByIdQuery,
  useEventsQuery,
} from '@cntxt/steam/data-access';

import { TableData } from './Table/TableView';

type Data = {
  tableData: TableData[];
  totals: Record<string, number>;
  calendarData: [string, number][];
};

type Response = {
  data: Data;
  isLoading: boolean;
  healthIndex: string;
  siteSurveys: number;
  id?: string | undefined;
};
export const useData = (
  assetId: number,
  dataSetIds: { id: number }[],
  startTime?: number,
  endTime?: number,
): Response => {
  const { id } = useParams<'id'>();
  const { client } = useAuthContext();
  const { data: events, refetch: refetchEventsData } = useEventsQuery({
    client,
    id: assetId,
    dataSetIds,
    startTime,
    endTime,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
  const eventsData = sortEvents(events);
  const assetIds = getAssetIdsFromEvents(eventsData);
  const { data: assetsData } = useAssetsByIdQuery({ client, ids: assetIds });

  React.useEffect(() => {
    // Manually trigger refetch when either startTime or endTime changes
    refetchEventsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime, events?.length]);

  const initialReport: Data = {
    tableData: [],
    totals: {
      healthy: 0,
      passing: 0,
      blocked: 0,
    },
    calendarData: [],
  };

  if (!eventsData) {
    return { isLoading: true } as Response;
  }

  const data = eventsData?.reduce((results, item) => {
    if (!item.metadata) {
      return results;
    }
    const names = getNamesFromAssetIds(item.assetIds, assetsData);
    const startTime = Number(item.startTime);
    const newEvent: TableData = {
      facility: item.metadata['areaExternalId'],
      id: names.join(', '),

      state: capitalize(item.subtype) || '',
      confidence:
        Number(item.metadata['confidenceScore']) ||
        Number(item.metadata['confidence']) ||
        0,
      date: getDisplayDate(new Date(startTime)),
      createdDate: getDisplayDate(item.createdTime),
      metadata: {
        eventId: item.id,
        parentId: item.assetIds ? item.assetIds[0] : 0,
        datasetId: item.dataSetId,
      },
    };

    const formattedDate = getDateWithFormat(
      newEvent.date,
      DateFormat.DAY_MONTH_YEAR_24HRs,
    );
    const calendarEntry: [string, number] = [formattedDate, 1];

    return {
      ...results,
      totals: {
        healthy:
          item.subtype === 'healthy'
            ? results.totals.healthy + 1
            : results.totals.healthy,
        passing:
          item.subtype === 'passing'
            ? results.totals.passing + 1
            : results.totals.passing,
        blocked:
          item.subtype === 'blocked'
            ? results.totals.blocked + 1
            : results.totals.blocked,
      },
      tableData: [...results.tableData, newEvent],
      calendarData: [...results.calendarData, calendarEntry],
    };
  }, initialReport);

  const siteSurveys =
    data.totals.healthy + data.totals.passing + data.totals.blocked;

  const healthIndex = ((data.totals.healthy / siteSurveys) * 100).toFixed();
  const healthIndexDisplay = siteSurveys === 0 ? '' : healthIndex;

  const dataWithPendingData = {
    ...data,
    tableData: [...data.tableData],
  };

  return {
    data: dataWithPendingData,
    isLoading: false,
    healthIndex: healthIndexDisplay,
    siteSurveys,
    id,
  };
};
