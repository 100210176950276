import { useParams } from 'react-router-dom';

import { Asset, CogniteClient } from '@cognite/sdk';
import get from 'lodash/get';

import { useAuthContext } from '@cntxt/shared/providers/web';
import { Typography } from '@cntxt/shared/ui';
import { getBreadcrumbs } from '@cntxt/shared/util-ui';
import {
  getNamesFromAssetIds,
  useAssetsByIdQuery,
} from '@cntxt/steam/data-access';

type BreadcrumbSegmentsResults = {
  lastTwoSegments: string[];
  data: Asset[] | undefined;
};
/**
 * Retrieves the last two segments of the current URL path as breadcrumbs (with linked assets)
 *
 * @returns An object with the last two segments of the URL path and the related assets.
 */
export function useBreadcrumbSegments({
  client,
}: {
  client: CogniteClient;
}): BreadcrumbSegmentsResults {
  const { id, ...rest } = useParams<{ id: string }>();

  const extras = get(rest, '*');

  const path = `${id}/${extras}`;

  const lastTwoSegments = getBreadcrumbs({
    amountToShow: 2,
    path,
  });

  const assetIds = lastTwoSegments.map((value) => Number(value));

  const { data } = useAssetsByIdQuery({ client, ids: assetIds });

  return {
    lastTwoSegments,
    data,
  };
}

type Props = {
  separatorCharacter: string;
};
export const Breadcrumbs: React.FC<Props> = ({ separatorCharacter }) => {
  const { client } = useAuthContext();
  const { data, lastTwoSegments } = useBreadcrumbSegments({
    client,
  });

  if (!data) {
    return (
      <Typography.Title.Small
        text={lastTwoSegments.join(separatorCharacter)}
        muted
      />
    );
  }

  const breadcrumbs = getNamesFromAssetIds(
    lastTwoSegments.map(Number),
    data,
  ).join(separatorCharacter);

  return <Typography.Title.Small text={breadcrumbs || ''} muted />;
};
