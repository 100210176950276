import { useContext } from 'react';

import { AuthContext, AuthContextType } from '../providers/AuthProvider';

export const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (context) {
    // we never even render context if it is not for already authenticated user
    // so we do manual type narrowing here
    return context;
  }

  throw new Error('useAuthContext must be used within an AuthProvider');
};
