import { CogniteClient } from '@cognite/sdk/dist/src';
import { useQuery } from '@tanstack/react-query';

import { fetchEventImages } from '../../network/fetchImages';

import { FILES_KEYS } from './keys';

type Props = {
  client: CogniteClient;
  dataSetId: number;
  eventExternalId: string;
  limit: number;
  queryOptions?: {
    refetchOnWindowFocus: boolean;
    refetchOnReconnect: boolean;
    refetchOnMount: boolean;
    staleTime: number;
    enabled: boolean;
  };
};

export const useImagesByEventExternalIdQuery = ({
  client,
  dataSetId,
  eventExternalId,
  limit,
  queryOptions = {
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    refetchOnMount: false,
    staleTime: 0, // Indicate that data is immediately stale
    enabled: !!eventExternalId, // Ensure the query runs only if eventExternalId is provided
  },
}: Props) => {
  return useQuery({
    queryKey: [FILES_KEYS.ALL(), eventExternalId],
    queryFn: () =>
      fetchEventImages({
        client,
        dataSetId,
        eventExternalId,
        limit,
      }),
    refetchOnWindowFocus: queryOptions.refetchOnWindowFocus,
    refetchOnReconnect: queryOptions.refetchOnReconnect,
    refetchOnMount: queryOptions.refetchOnMount,
    staleTime: queryOptions.staleTime,
    enabled: queryOptions.enabled,
  });
};
