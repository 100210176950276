import { ClearImageCard } from './Clear';
import { Props as ImageCardBaseProps } from './ImageCardBase';
import { SelectedImageCard } from './Selected';

type Props = {
  selected?: boolean;
} & ImageCardBaseProps;

export const SwitchCard: React.FC<Props> = ({
  title,
  imageSrc,
  iconCallback,
  imageCallback,
  selected = false,
  isIconVisible = true,
}) => {
  return selected ? (
    <SelectedImageCard
      title={title}
      imageSrc={imageSrc}
      iconCallback={iconCallback}
      imageCallback={imageCallback}
      isIconVisible={isIconVisible}
    />
  ) : (
    <ClearImageCard
      title={title}
      imageSrc={imageSrc}
      iconCallback={iconCallback}
      imageCallback={imageCallback}
      isIconVisible={isIconVisible}
    />
  );
};
