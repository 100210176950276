const ROOT = '/';
const PAGE = 'page'; // authenticated page
export const INSPECT = '/inspect';
export const STEAM_TRAPS = '/steamTraps';
export const ROUTES = {
  HOME: ROOT,
  PAGE: `${ROOT}${PAGE}`,

  DASHBOARD: `${ROOT}${PAGE}${INSPECT}/dashboard`,
  DETAILS: `${ROOT}${PAGE}${INSPECT}/details`,

  HISTORY: `${ROOT}${PAGE}/history`,
  SETTINGS: `${ROOT}${PAGE}/settings`,
  ALERTS: `${ROOT}${PAGE}/alerts`,
  UPLOAD: `${ROOT}${PAGE}/upload`,
  REVIEW: `${ROOT}${PAGE}/reviewImages`,
  INSPECTION: `${ROOT}${PAGE}${STEAM_TRAPS}`,
  STEAM_TRAPS_INSPECTION: `${ROOT}${PAGE}${STEAM_TRAPS}/inspection`,
};
