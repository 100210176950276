import React from 'react';

import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { Layout, Typography } from '@cntxt/shared/ui';
import { DateFormat, getDateWithFormat } from '@cntxt/shared/util-date';
import { formatValueAsPercentage } from '@cntxt/shared/util-ui';
import {
  ChipBlocked,
  ChipHealthy,
  ChipPassing,
  ChipPending,
} from '@cntxt/steam/ui-chips';

import { Table } from './Table';

type Props = {
  id: string | undefined;
  tableData: TableData[];
  detailsRoute: string;
};

export type TableData = {
  facility: string;
  id: string;
  state: string;
  confidence: number;
  date: string;
  createdDate: string;
  metadata: {
    eventId?: number;
    parentId?: number;
    datasetId?: number;
  };
};

const NUMBER_OF_DECIMALS = 0;
const columnHelper = createColumnHelper<TableData>();

const columns = [
  columnHelper.accessor('facility', {
    header: () => 'Facility',
    minSize: 100,
    enableSorting: false,
    footer: (props) => props.column.id,
    cell: ({ getValue }) => {
      const value = getValue() as TableData['facility'];
      return <Layout.BoxCenter>{value}</Layout.BoxCenter>;
    },
  }),
  columnHelper.accessor('id', {
    header: () => 'Steam Trap ID',
    minSize: 100,
    enableSorting: false,
    footer: (props) => props.column.id,
  }),
  columnHelper.accessor('state', {
    header: () => 'State',
    enableSorting: false,
    footer: (props) => props.column.id,
    cell: ({ getValue, row }) => {
      const value = getValue() as TableData['state'];
      const Chip = States[value];
      if (!Chip) {
        return <div key={`state-${row.id}`}>Invalid state: {value}</div>;
      }
      return (
        <Layout.BoxCenter>
          <Chip key={`state-${row.id}`} />
        </Layout.BoxCenter>
      );
    },
  }),
  columnHelper.accessor('confidence', {
    header: () => 'Confidence(%)',
    minSize: 100,
    enableSorting: false,
    footer: (props) => props.column.id,
    cell: ({ getValue }) => {
      const value = getValue() as TableData['confidence'];
      const percentage = formatValueAsPercentage(value, NUMBER_OF_DECIMALS);
      return <Layout.BoxCenter>{percentage}</Layout.BoxCenter>;
    },
  }),
  columnHelper.accessor('date', {
    header: () => 'Captured date',
    enableSorting: false,
    footer: (props) => props.column.id,
    cell: ({ getValue }) => {
      const value = getValue() as TableData['date'];
      return (
        <Layout.BoxCenter>
          {getDateWithFormat(value, DateFormat.DAY_MONTH_YEAR)}
        </Layout.BoxCenter>
      );
    },
  }),
  columnHelper.accessor('createdDate', {
    header: () => 'Created date',
    minSize: 100,
    enableSorting: false,
    footer: (props) => props.column.id,
    cell: ({ getValue }) => {
      const value = getValue() as TableData['createdDate'];
      return (
        <Layout.BoxCenter>
          {getDateWithFormat(value, DateFormat.DAY_MONTH_YEAR)}
        </Layout.BoxCenter>
      );
    },
  }),
];

export const TableView: React.FC<Props> = ({ id, tableData, detailsRoute }) => {
  if (!id) {
    return null;
  }
  return (
    <Layout.PageContentBox>
      <Typography.Title.Small text={'Steam Trap Health Status'} />
      <Table
        data={tableData}
        detailsRoute={detailsRoute}
        columns={columns as ColumnDef<unknown>[]}
      />
    </Layout.PageContentBox>
  );
};

const States: Record<TableData['state'], React.FC> = {
  Healthy: ChipHealthy,
  Passing: ChipPassing,
  Blocked: ChipBlocked,
  Pending: ChipPending,
};
