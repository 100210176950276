/**
 * Formats a value as a percentage string.
 *
 * @param {number} value - The value to format.
 * @param {number} toFixed - The number of decimal places in the formatted string.
 * @returns {string} The formatted value as a percentage.
 */
export const formatValueAsPercentage = (
  value: number,
  toFixed: number,
): string => {
  const formatted = Math.min(value ?? 0, 100);
  const percentage = (100 * formatted).toFixed(toFixed);
  return percentage;
};
