import React, { useEffect, useState } from 'react';

import type { ECElementEvent } from 'echarts';
import { DateTime } from 'luxon';

import { Layout, Typography } from '@cntxt/shared/ui';
import { Icons } from '@cntxt/shared/ui-icons';

import { Breadcrumbs } from '../Breadcrumbs';

import { CalendarGroup } from './CalendarGroup';

export const isValidEventDataArray = (
  data: unknown,
): data is (string | number)[] => {
  if (!Array.isArray(data)) return false;
  if (data.length < 2) return false;

  // Check if every item in the array is an array of [string, number]
  return (
    data &&
    Array.isArray(data) &&
    data.length === 2 &&
    typeof data[0] === 'string' &&
    typeof data[1] === 'number'
  );
};

type Props = {
  id?: string;
  calendarData: [string, number][];
  handleDurationChange: (startTime: number, endTime: number) => void;
  showTitle?: boolean;
};
const calculateDateRange = (
  referenceDate: DateTime,
  monthsDiff: number,
): [DateTime, DateTime] => {
  const startDate = referenceDate.plus({ months: monthsDiff }).startOf('month');
  const endDate = startDate.plus({ months: 5 }).endOf('month');
  return [startDate, endDate];
};

export const CalendarView: React.FC<Props> = ({
  id,
  calendarData,
  handleDurationChange,
  showTitle = true,
}) => {
  const [isDateSelected, setIsDateSelected] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<[DateTime, DateTime]>(() => {
    const today = DateTime.local();
    return calculateDateRange(today, -5);
  });

  useEffect(() => {
    handleDurationChange(dateRange[0].toMillis(), dateRange[1].toMillis());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const updateDateRange = (monthsDiff: number) => {
    const newRange = calculateDateRange(dateRange[0], monthsDiff);
    setDateRange(newRange);
  };

  const onCalendarSelect = ({ data }: ECElementEvent) => {
    const isValidEventData = isValidEventDataArray(data);
    if (!isValidEventData) {
      return;
    }

    if (isDateSelected) {
      handleDurationChange(dateRange[0].toMillis(), dateRange[1].toMillis());
      setIsDateSelected(false);
      return;
    }

    const [date, hasData] = data as [string, number];
    if (hasData > 0) {
      setIsDateSelected(true);
      const selectedDate = DateTime.fromISO(date).setZone('local');
      const endDate = selectedDate.endOf('day').setZone('local');
      handleDurationChange(selectedDate.toMillis(), endDate.toMillis());
    }
  };

  if (!id) {
    return null;
  }

  return (
    <Layout.PageContentBox>
      <Layout.BoxVertical>
        {showTitle && (
          <Layout.BoxHorizontal>
            <Typography.Title.Small text="Site Surveys | " />
            <Breadcrumbs separatorCharacter=" | " />
          </Layout.BoxHorizontal>
        )}
        <Layout.BoxHorizontal sx={{ alignItems: 'center' }}>
          <Icons.LeftArrow onClick={() => updateDateRange(-1)} />
          <CalendarGroup
            data={calendarData}
            startDate={dateRange[0].toJSDate()}
            endDate={dateRange[1].toJSDate()}
            onSelect={onCalendarSelect}
          />
          <Icons.RightArrow onClick={() => updateDateRange(1)} />
        </Layout.BoxHorizontal>
      </Layout.BoxVertical>
    </Layout.PageContentBox>
  );
};
