import React from 'react';

import { useSearchParams } from 'next/navigation';

export const useNextSearchParams = ({
  param,
  defaultValue,
}: {
  param: string;
  defaultValue: string;
}) => {
  const searchParams = useSearchParams();

  return React.useMemo(() => {
    const foundParamValue = searchParams.get(param);
    if (!foundParamValue) {
      return defaultValue;
    }
    return foundParamValue;
  }, [searchParams, defaultValue, param]);
};
