import { CogniteClient, DataSetFilter } from '@cognite/sdk';

export const fetchDatasets = async ({
  client,
  filter,
}: {
  client: CogniteClient;
  filter: DataSetFilter;
}) => {
  const result = await client.datasets.list({
    filter,
  });

  return result.items;
};
