import * as React from 'react';

import Box from '@mui/material/Box';

import {
  Divider,
  Buttons,
  DrawerRight,
  Layout,
  Typography,
  Styles,
} from '@cntxt/shared/ui';

export const ReviewImages: React.FC = () => {
  return (
    <Layout.FlexBox>
      <Layout.PageContent>
        <Typography.Title.Regular text="Review images" />
        <Layout.Expander />
        <Box
          sx={{
            padding: '20px',
            border: `1px solid ${Styles.colors.border.default}`,
            borderRadius: '12px',
            marginTop: '40px',
          }}
        >
          Here we will review image results etc.
        </Box>
      </Layout.PageContent>
      <DrawerRight>
        <Layout.BoxVertical
          sx={{
            padding: '20px',
            flex: 1,
          }}
        >
          <Typography.General.SubtitleBold text="Summary" />
          <div>Some info here.</div>
          <Layout.Expander />
          <Layout.BoxVertical>
            <Divider />
            <Buttons.Done />
          </Layout.BoxVertical>
        </Layout.BoxVertical>
      </DrawerRight>
    </Layout.FlexBox>
  );
};
