import * as React from 'react';

import { CogniteClient } from '@cognite/sdk/dist/src';
import { last } from 'lodash';

import { useAssetRootQuery, useSteamTraps } from '@cntxt/steam/data-access';
import { SteamTrapTableData } from '@cntxt/steam/data-types';

const getInspectionStatus = (isInspectionCompleted: boolean | undefined) => {
  if (typeof isInspectionCompleted === 'undefined') {
    return;
  }
  if (isInspectionCompleted) {
    return 'completed';
  }
  return 'pending';
};
const isInspectionCompletedSelectedDuration = (
  startTime: number,
  endTime: number,
  lastInspectionDate: number,
) => {
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);

  const inspectionDate = new Date(lastInspectionDate);
  if (inspectionDate >= startDate && inspectionDate <= endDate) {
    return true;
  }
  return false;
};
type Response = {
  data: SteamTrapTableData[];
  isLoading: boolean;
  id?: string;
};
export const useSteamTrapsData = (
  client: CogniteClient,
  extras: string[],
  dataSetIds: { id: number }[],
  startTime?: number,
  endTime?: number,
  status?: string,
): Response => {
  const assetId = Number(last(extras));
  const { data: roots } = useAssetRootQuery({ client, dataSetIds });
  const rootIds = assetId ? [{ id: assetId }] : [];
  const { data: assetData, refetch: refetchAssetsData } = useSteamTraps(
    client,
    rootIds,
  );

  React.useEffect(() => {
    // Manually trigger refetch when either startTime or endTime changes
    if (assetId) refetchAssetsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  const defaultResponse = { isLoading: true } as Response;

  const data = assetData?.map((asset) => {
    const location = roots?.find((plant) => plant.id === asset.rootId);
    if (!asset.metadata) {
      return {} as SteamTrapTableData;
    }
    const { lastInspectionStatus, lastInspectionDate } = asset.metadata;

    let isInspectionCompleted;
    let lastInspectionDateValue;
    if (lastInspectionDate) {
      const formattedInspectionDate = Number(lastInspectionDate);
      isInspectionCompleted = isInspectionCompletedSelectedDuration(
        Number(startTime),
        Number(endTime),
        formattedInspectionDate,
      );
      lastInspectionDateValue = formattedInspectionDate;
    }
    return {
      facility: asset.parentExternalId,
      location: location?.name,
      id: asset.name,
      externalId: asset.externalId,
      status: getInspectionStatus(isInspectionCompleted),
      lastInspectionStatus,
      lastInspectionDate: lastInspectionDateValue,
    } as unknown as SteamTrapTableData;
  });
  if (!data) {
    return defaultResponse;
  }
  let filterByStatus = data;
  if (status && status !== 'All') {
    filterByStatus = data?.filter(
      (response) => response.status?.toLowerCase() === status.toLowerCase(),
    );
  }
  return {
    data: filterByStatus,
    isLoading: false,
  };
};
