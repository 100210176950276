import { CogniteClient, IdEither } from '@cognite/sdk';
import { useQuery } from '@tanstack/react-query';

import { fetchAssets } from '../../network/fetchAssets';
import { Asset } from '../../network/types';

import { ASSET_KEYS } from './keys';

type Props = {
  client: CogniteClient;
  rootIds: IdEither[];
  select?: (data: Asset[]) => Asset[] | undefined;
  steamTrapsOnly?: boolean;
};
export const useAssetTreeQuery = ({
  client,
  rootIds,
  select,
  steamTrapsOnly,
}: Props) => {
  return useQuery({
    queryKey: steamTrapsOnly ? ASSET_KEYS.STEAM_TRAPS_ONLY() : ASSET_KEYS.ALL(),
    queryFn: () =>
      fetchAssets({
        client,
        filter: { assetSubtreeIds: rootIds },
      }),
    // Enable the query only when rootIds are available and not empty
    enabled: rootIds && rootIds.length > 0,
    // ✅ uses a stable function reference
    select,
  });
};
