import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import { Layout, Styles } from '@cntxt/shared/ui';
import {
  ChipBlocked,
  ChipHealthy,
  ChipPassing,
  ChipPending,
} from '@cntxt/steam/ui-chips';

import { RowContainer } from './elements';
import { HistoryRow } from './HistoryChangeTable';
import { TableData } from './TableView';

type Props = {
  rowData: HistoryRow[];
};
export const HistoryChangeTableRows: React.FC<Props> = ({ rowData }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const eventIDFromQueryParam = searchParams.get('eventId');

  const addOrUpdateQueryParam = (key: string, value: string) => {
    const location = window.location;
    searchParams.set(key, value);
    return `${location.pathname}?${searchParams.toString()}`;
  };
  return (
    <>
      {rowData.map((row, index) => {
        const state = () => {
          const Chip = States[row.status];
          if (!Chip) {
            return (
              <div key={`state-${index}`}>Invalid state: {row.status}</div>
            );
          }
          return (
            <Layout.BoxCenter>
              <Chip key={`state-${index}`} />
            </Layout.BoxCenter>
          );
        };
        if (row.eventId) {
          return (
            <RowContainer
              style={{
                ...styles.row,
                backgroundColor:
                  eventIDFromQueryParam === row.eventId.toString()
                    ? Styles.colors.surface.interactive.hover
                    : '',
              }}
              key={`state-${row.capturedDate}-${row.eventId}-${index}`}
            >
              <Link
                to={addOrUpdateQueryParam('eventId', row.eventId.toString())}
              >
                <td style={styles.bodyRowCellText}>{row.capturedDate}</td>
                <td style={styles.bodyRowCellText}>{state()}</td>
              </Link>
            </RowContainer>
          );
        } else {
          return (
            <RowContainer
              key={`state-${row.capturedDate}-${row.eventId}-${index}`}
              style={styles.row}
            >
              <td style={styles.bodyRowCellText}>{row.capturedDate}</td>
              <td style={styles.bodyRowCellText}>{state()}</td>
            </RowContainer>
          );
        }
      })}
    </>
  );
};

const States: Record<TableData['state'], React.FC> = {
  Healthy: ChipHealthy,
  Passing: ChipPassing,
  Blocked: ChipBlocked,
  Pending: ChipPending,
};

const styles: Record<string, CSSProperties> = {
  bodyRowCellText: { width: '100%' },
  row: {
    cursor: 'pointer',
  },
};
