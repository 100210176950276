import { QueryClientProvider } from '@cntxt/shared/providers/shared';
import { Layout, Loading, StyleProvider, Styles } from '@cntxt/shared/ui';
import { CNTXTLogo } from '@cntxt/shared/ui-cntxt-logo/web';
import { AuthOptions, IDP_TYPE } from '@cntxt/shared/util-auth';

import { Container, LoginForm } from './elements';
import { SignInWithAAD } from './SignInWithAAD';
import { SignInWithADFS2016 } from './SignInWithADFS2016';

type LoginScreenProps = {
  onSubmit: () => void;
  error?: string;
  name: string;
  isLoading?: boolean;
  authOptions: AuthOptions;
};

export const LoginScreen = ({
  onSubmit,
  error,
  isLoading,
  authOptions,
  name,
}: LoginScreenProps) => {
  if (isLoading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }
  return (
    <QueryClientProvider>
      <StyleProvider />
      <Container>
        <LoginForm>
          <CNTXTLogo />
          <Layout.Spacer />
          <Layout.Spacer />
          <Layout.Spacer />
          <header>
            <h2>Sign in to</h2>
            <h1>{name}</h1>
          </header>
          {error && (
            <div
              className="error"
              style={{ color: Styles.colors.text.status.critical }}
            >
              {error}
            </div>
          )}
          {authOptions.project.idpType === IDP_TYPE.AZURE_AD && (
            <SignInWithAAD onSubmit={onSubmit} />
          )}
          {authOptions.project.idpType === IDP_TYPE.ADFS2016 && (
            <SignInWithADFS2016
              label="Login"
              {...authOptions}
              {...authOptions.project}
              handleSubmit={onSubmit}
            />
          )}
        </LoginForm>
      </Container>
    </QueryClientProvider>
  );
};
