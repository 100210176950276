import { type SxProps } from '@mui/material';

import { Layout, Styles } from '@cntxt/shared/ui';

import {
  SearchTextField,
  Props,
} from '../../Components/Search/SearchTextField';

export function Actions({ assetURLToRoute }: Readonly<Props>) {
  return (
    <Layout.PageContentBox sx={styles.container}>
      <SearchTextField assetURLToRoute={assetURLToRoute} />
    </Layout.PageContentBox>
  );
}

const styles: Record<string, SxProps> = {
  container: {
    display: 'flex',
    gap: Styles.sizes.pixels._1,
    button: {
      alignItems: 'center',
    },
  },
};
