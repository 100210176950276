import { AuthOptions, IDP_TYPE } from '../lib/types';

export const getMockProjectInfo = (): AuthOptions['project'] => {
  return {
    dataSetId: {
      test: 123,
    },
    id: '123',
    cluster: '123',
    idpType: IDP_TYPE.TESTING,
  };
};
