import { CSSProperties } from 'react';

import { CogniteEvent } from '@cognite/sdk/dist/src';

import { Layout, Loading, NoData, Styles } from '@cntxt/shared/ui';

import { SwitchCards } from '../Components/Cards/SwitchCards';

import { ImageCardDetails } from './useData';
import { useThumbnailData } from './useThumbnailData';

type Props = {
  selectedEvent: CogniteEvent | undefined;
  dataSetId: number;
  handleImageSelection: (selectedCardDetails: ImageCardDetails) => void;
};
export const Thumbnail: React.FC<Props> = ({
  selectedEvent,
  dataSetId,
  handleImageSelection,
}) => {
  const { imageCardDetails: thumbnails, isLoading } = useThumbnailData(
    dataSetId,
    selectedEvent,
  );

  const handleOnImageClick = (
    index: number,
    imageCardDetails: ImageCardDetails[],
  ) => {
    const cardDetails = imageCardDetails?.[index];
    handleImageSelection(cardDetails);
  };
  const noData = !isLoading && !thumbnails?.length;
  if (isLoading) {
    return (
      <Layout.BoxHorizontal sx={styles.switchCardContainer}>
        <Loading />
      </Layout.BoxHorizontal>
    );
  }

  if (noData) {
    return (
      <Layout.BoxHorizontal sx={styles.switchCardContainer}>
        <NoData
          color={Styles.colors.text.muted}
          text="No images are available for selection"
        />
      </Layout.BoxHorizontal>
    );
  }

  return (
    <Layout.BoxHorizontal sx={styles.switchCardContainer}>
      <SwitchCards
        cards={thumbnails}
        handleOnImageClick={handleOnImageClick}
        handleOnIconCallback={() => {
          return;
        }}
      />
    </Layout.BoxHorizontal>
  );
};

const styles: Record<string, CSSProperties> = {
  switchCardContainer: {
    maxWidth: '1000px',
    overflow: 'auto',
  },
};
