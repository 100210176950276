import { DateTime } from 'luxon';

export const getUnixTime = (dateString: string): number => {
  const date = new Date(dateString);
  const dt = DateTime.fromJSDate(date);

  const unixTime = dt.toMillis();

  return unixTime;
};
