import React, { CSSProperties, useState } from 'react';

import { Grid } from '@mui/material';

import {
  BackComponent,
  Cards,
  Layout,
  NoData,
  Styles,
  Typography,
} from '@cntxt/shared/ui';
import { ROUTES, getAssetDashboardRoute } from '@cntxt/steam/util-ui';

import { CalendarView } from '../Calendar';

import { Thumbnail } from './Thumbnail';
import { ImageCardDetails, useData } from './useData';

const DEFAULT_WIDTH = 300;

type Props = {
  handleDurationChange: (startTime: number, endTime: number) => void;
  fromUnixTime: number;
  toUnixTime: number;
};

export const Inspect: React.FC<Props> = ({
  handleDurationChange,
  fromUnixTime,
  toUnixTime,
}) => {
  const [selectedImage, setSelectedImage] = useState<string>('');
  const [selectedImageName, setSelectedImageName] = useState<string>('');
  const handleOnImageClick = (selectedCardDetails: ImageCardDetails) => {
    if (selectedCardDetails) {
      const { imageSrc, title: name } = selectedCardDetails;
      setSelectedImage(imageSrc);
      setSelectedImageName(`${name} | `);
    } else {
      setSelectedImage('');
      setSelectedImageName('');
    }
  };

  const { dataSetId, title, parentAsset, selectedEvent, calendarData, id } =
    useData(fromUnixTime, toUnixTime);
  const backwardButtonLink = getAssetDashboardRoute(
    ROUTES.DASHBOARD,
    dataSetId,
    parentAsset?.parentId,
    parentAsset?.id,
  );

  React.useEffect(() => {
    setSelectedImage('');
  }, [selectedEvent?.id]);

  return (
    <>
      <Layout.Page sx={styles.backComponentContainer}>
        <Layout.PageContentBox>
          <BackComponent to={backwardButtonLink} text={title} />
        </Layout.PageContentBox>
        <Layout.Expander />
      </Layout.Page>
      <Grid sx={styles.bodyContainer}>
        <Layout.PageContentBox>
          <Cards.Clear sx={styles.backgroundCard}>
            <Typography.Title.Small text={'Site Surveys'} />
            <Typography.Title.Small
              text={` | ${parentAsset?.name} | ${selectedEvent?.externalId}`}
              muted
            />
            <CalendarView
              id={id}
              calendarData={calendarData}
              handleDurationChange={handleDurationChange}
              showTitle={false}
            />
            <Layout.Spacer />
            <Layout.Spacer />
            <Layout.BoxVertical>
              <Layout.BoxVertical sx={styles.selectImageContainer}>
                {selectedImage ? (
                  <Layout.BoxCenter sx={{ gap: 0 }}>
                    <img
                      src={selectedImage}
                      style={styles.selectedImageStyle}
                      alt="Thermal inspection result"
                    />
                  </Layout.BoxCenter>
                ) : (
                  <>
                    <Layout.Spacer />
                    <Typography.General.SubtitleBold
                      text={'Select an image:'}
                    />
                  </>
                )}
                <Layout.BoxVertical>
                  <Layout.BoxHorizontal>
                    <Typography.Title.Small text={`${selectedImageName}`} />
                    <Typography.Title.Small
                      text={`${parentAsset?.name}`}
                      muted
                    />
                  </Layout.BoxHorizontal>
                  {selectedEvent ? (
                    <Thumbnail
                      handleImageSelection={handleOnImageClick}
                      selectedEvent={selectedEvent}
                      dataSetId={Number(id)}
                    />
                  ) : (
                    <Layout.BoxHorizontal sx={styles.switchCardContainer}>
                      <NoData
                        color={Styles.colors.text.muted}
                        text="Select a capture date to view the list of events for that day"
                      />
                    </Layout.BoxHorizontal>
                  )}
                </Layout.BoxVertical>
              </Layout.BoxVertical>
            </Layout.BoxVertical>
          </Cards.Clear>
        </Layout.PageContentBox>
      </Grid>
      {/* <DrawerRight>
        <InspectPanelView />
      </DrawerRight> */}
    </>
  );
};

const styles: Record<string, CSSProperties> = {
  backComponentContainer: {
    width: '100%',
    height: 'auto',
  },
  bodyContainer: { height: '95vh', width: `calc(100% - ${DEFAULT_WIDTH}px)` },
  backgroundCard: {
    width: '100%',
    height: '100%',
    padding: Styles.sizes.pixels._25,
  },
  selectImageContainer: {
    maxWidth: '1000px',
    overflow: 'auto',
    width: '100%',
  },
  selectedImageStyle: {
    borderRadius: '4px',
    width: '100%',
    maxWidth: '800px',
    height: '350px',
  },
};
